import { ActionTypes } from "../actionTypes";
import { ModalState, ModalAction } from "./types";

const initialState: ModalState = {
    show: false,
    component: undefined,
    modalTitle: "",
    data: {}
};

const modalReducer = (
    state: ModalState = initialState,
    action: ModalAction
): ModalState => {
    switch (action.type) {
        case ActionTypes.SHOW_MODAL:
            const { component } = action.payload;

            if (component) {
                return {
                    show: true,
                    ...action.payload
                };
            } else {
                return state;
            }

        case ActionTypes.HIDE_MODAL:
            return { ...initialState };
        default:
            return state;
    }
};

export default modalReducer;
