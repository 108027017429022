import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import { ModalHeader, ModalBody, ModalFooter, Modal } from "reactstrap";
import "./style.scss";
import { StoreState } from "../../store";
import { hideMessageModal } from "../../store/messageModal/action";
import { CONFIRMATION_BUTTON } from "../../utils/constants/uiConstants";
import { Button, Dialog, MessageDialog } from "@abb/abb-common-ux-react";

interface MessageModalProps { }

export const MessageModal = (
    props: MessageModalProps &
        ReturnType<typeof mapDispatchToProps> &
        ReturnType<typeof mapStateToProps>
) => {
    const handleCloseModal = (event: React.SyntheticEvent) => {
        props.data && props.data.primaryButtonAction(event);
        props.hideMessageModal();
    };
debugger
    return (
        <Dialog
            title={
                (props.data && props.data.componentTitle) ||
                CONFIRMATION_BUTTON.DISCARD_CHANGES
            }
            dimBackground={true}
            closeOnEscape={false}
            showCloseButton={true}
            isOpen={props.isOpen}
            onClose={props.hideMessageModal}
            className="wrapper-message-modal"
        >
            {/**  <Modal
            size="sm"
            centered
            isOpen={props.isOpen}
            className="wrapper-message-modal"
        >
            <ModalBody>
            */}
            <div className="wrapper-warning-msg">
                <div className="wrapper-warning-data">
                    {/* <div className="alert-icon">!</div> */}
                    {props.data && (
                        <div className="message-modal-text">
                            {props.data.warningText}
                        </div>
                    )}
                </div>
                <div className="yes-no-btn">
                    <div
                        id={CONFIRMATION_BUTTON.REJECT}
                        onClick={handleCloseModal}
                    >
                        <Button
                            className="conf-btn"
                            type="discreet-black"
                            text={
                                (props.data && props.data.rejectButtonTitle) ||
                                CONFIRMATION_BUTTON.CANCEL
                            }
                            sizeClass="small"
                        />
                    </div>
                    <div
                        id={CONFIRMATION_BUTTON.ACCEPT}
                        onClick={handleCloseModal}
                    >
                        <Button
                            className="conf-btn"
                            type="primary-blue"
                            sizeClass="small"
                            text={
                                (props.data && props.data.acceptButtonTitle) ||
                                CONFIRMATION_BUTTON.DISCARD_CHANGES
                            }
                        />
                    </div>
                </div>
            </div>
            {/* </ModalBody>
        </Modal> */}
        </Dialog>

        // <MessageDialog
        //     onClose={() => props.hideMessageModal()}
        //     title="Save Changes?"
        //     isOpen={props.isOpen}
        //     buttons="confirmcancel"
        //     message={props.data && props.data.warningText}
        // ></MessageDialog>
    );
};

const mapStateToProps = (state: StoreState) => {
    return {
        isOpen: state.messageModal.show,
        data: state.messageModal.data,
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        hideMessageModal: () => dispatch(hideMessageModal()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageModal);
