import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import Condition from '../shared/Condition';
import { Icon, Dialog, Button, Input } from '@abb/abb-common-ux-react';
import { ModalFooter, FormGroup, Label } from 'reactstrap';
import { StoreState } from '../../store';
import { Dispatch } from 'redux';
import { deleteCustomVariableRequest } from '../../store/assets/actions';
import { AbilityService } from 'sce-engg-model-19.09';
import { ACTION_TYPE, MAX_CHARACTERS } from '../../utils/constants/appConstants';
import { TreeNodeType } from '../../store/assets/types';
import uuid from 'uuid';
import { mapTreeModel } from '../../store/assets/model';
import { validateAtLeastOneCharacter, validateSpecialCharsWithoutSwedishFinnish } from "../../utils/helpers";

interface ICustomTreeAdd {
    showDialog: boolean;
    actionType: string;
    node: any;
    treeNode: any;
    onClose: (showDialog: boolean) => void;
    onApply: (node: any, childNodes: any, name: string) => void;
}

const CustomTreeAdd = (props: ICustomTreeAdd &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>) => {

    const [nodeName, updateNodeName] = useState("");
    const [showDialog, setDialog] = useState(false);
    const [nodeDetails, updateNodeDetails] = useState({} as any);
    const [treeNodeDetails, updateTreeNodeDetails] = useState({} as any);
    const [childNodeDetails, updateChildNodeDetails] = useState({} as any);

    useEffect(() => {
        setDialog(props.showDialog);
    }, [props.showDialog])

    useEffect(() => {
        if (props.actionType == ACTION_TYPE.EDIT || props.actionType == ACTION_TYPE.DELETE) {
            updateNodeDetails(props.node);
            updateTreeNodeDetails(props.treeNode);
            updateNodeName(props.node && props.node.name ? props.node.name : "");
        } else {
            updateNodeDetails(props.node);
            updateTreeNodeDetails(props.treeNode);
            let childNode = {
                ...props.node,
                active: false,
                loading: false,
                description: "",
                expanded: false,
                toggled: false,
                isLeaf: false,
                level: props.node.level ? props.node.level + 1 : 1,
                parentId: props.node.objectId,
                objectId: uuid(),
                name: "",
                children: []
            }
            updateChildNodeDetails(childNode);
        }
    }, [props.node])

    // inputField error handling states
    const [errorName, updateErrorName] = useState(false);
    const [errorEmptyObjectName, updateErrorEmptyObjectName] = useState(false);
    const [errorSpecialCharsObjectName, updateErrorSpecialCharsObjectName] = useState(false);
    const [errorMaxLimitObjectName, updateErrorMaxLimitObjectName] = useState(false);
    const [errorOneCharObjectName, updateErrorOneCharObjectName] = useState(false);

    const resetModal = () => {
        updateErrorName(false);
        updateErrorEmptyObjectName(false);
        updateErrorMaxLimitObjectName(false);
        updateErrorSpecialCharsObjectName(false);
        updateErrorOneCharObjectName(false);
    }

    const validateName = (): string => {
        if (errorName) {
            if (errorEmptyObjectName) return "Name can't be empty";
            else if (errorMaxLimitObjectName) return `Max ${MAX_CHARACTERS} characters allowed`;
            else if (errorSpecialCharsObjectName) return "Special characters not allowed";
            // else if (errorOneCharObjectName) return "Name should contain at least one alphabet";
        }
        return "";
    }

    return (
        <>
            <Dialog
                containerClassName="logic-add-dialog new-layout"
                dimBackground={true}
                closeOnLostFocus={false}
                
                title={props.actionType == ACTION_TYPE.ADD ? "Add Structure Node" : (props.actionType == ACTION_TYPE.EDIT ? "Rename Node" : (props.actionType == ACTION_TYPE.ROOT ? "Add Root Node" : "Remove Node ?"))}
                isOpen={showDialog}
            
                showCloseButton={true}
                onClose={() => {
                    resetModal();
                    props.onClose(false);
                }}
            >
                <div className="parent-asset-config-form">
                    <div className="asset-config-form">
                        <Condition when={props.actionType == ACTION_TYPE.ADD || props.actionType == ACTION_TYPE.EDIT || props.actionType == ACTION_TYPE.ROOT}>
                            <FormGroup className="custom-form-group">
                                <Label for="objectId">
                                    {props.actionType == ACTION_TYPE.ADD ? "Add Node" : props.actionType == ACTION_TYPE.EDIT ? "Edit Node" : (props.actionType == ACTION_TYPE.ROOT ? "Add Root" : "")}
                                </Label>
                                <Input
                                    value={nodeName}
                                    onValueChange={(value) => {
                                        if (props.actionType == ACTION_TYPE.EDIT) {
                                            updateNodeName(value);
                                            updateNodeDetails(nodeDetails);
                                        }
                                        if (props.actionType == ACTION_TYPE.ADD) {
                                            updateNodeName(value);
                                            updateChildNodeDetails(childNodeDetails);
                                        }
                                        if (props.actionType == ACTION_TYPE.ROOT) {
                                            updateNodeName(value);
                                        }
                                        resetModal();
                                    }}
                                    type="normal"
                                    dataType="text"
                                    id="objectId"
                                    placeholder="Enter Node Name"
                                    showValidationBarWhenInvalid={true}
                                    showValidationIconWhenInvalid={true}
                                    instantValidation={true}
                                    validator={() =>
                                        errorName
                                            ? {
                                                valid: false,
                                                text: validateName(),
                                            }
                                            : { valid: true, text: "OK!" }
                                    }
                                />
                            </FormGroup>
                        </Condition>

                        <Condition when={props.actionType == ACTION_TYPE.DELETE}>
                            <FormGroup className="custom-form-group">
                                <Label>
                                    {/* This <b>{nodeDetails ? nodeDetails.name : ""}</b>  hierarchy might be configured in  <b>Streaming Calculation Engine</b>.
                                After deletion, monitoring of configured objects will not be possible. */}
                                    Are you sure want to Remove <b>{nodeDetails ? nodeDetails.name : ""}</b>  ? Deleting this node may remove its child nodes if any exists.
                                </Label>
                            </FormGroup>
                        </Condition>

                    </div>
                </div>
                <ModalFooter>
                    <div>
                        <Button
                            text="Cancel"
                            sizeClass="medium"
                            shape="rounded"
                            type="normal"
                            className="customDeleteButton"
                            onClick={() => {
                                resetModal();
                                props.onClose(false);
                            }} />
                        <Button
                            text={props.actionType == ACTION_TYPE.ADD ? "Add" : props.actionType == ACTION_TYPE.EDIT ? "Save" : (props.actionType == ACTION_TYPE.ROOT ? "Add" : "Remove")}
                            sizeClass="medium"
                            shape="rounded"
                            type="primary-blue"
                            className="customDeleteButton"
                            style={{ marginLeft: `12px` }}
                            onClick={() => {
                                if (props.actionType == ACTION_TYPE.EDIT) {
                                    if (nodeName == "") {
                                        updateErrorName(true);
                                        updateErrorEmptyObjectName(true);
                                        updateErrorMaxLimitObjectName(false);
                                        updateErrorSpecialCharsObjectName(false);
                                        updateErrorOneCharObjectName(false);
                                        return;
                                    }
                                    if (nodeName && nodeName.trim().length > MAX_CHARACTERS) {
                                        updateErrorName(true);
                                        updateErrorEmptyObjectName(false);
                                        updateErrorMaxLimitObjectName(true);
                                        updateErrorSpecialCharsObjectName(false);
                                        updateErrorOneCharObjectName(false);
                                        return;
                                    }
                                    /* Special Char Validation */
                                    const res = validateSpecialCharsWithoutSwedishFinnish(nodeName, [".", "_", "-"]);
                                    if (!res) {
                                        updateErrorName(true);
                                        updateErrorEmptyObjectName(false);
                                        updateErrorMaxLimitObjectName(false);
                                        updateErrorSpecialCharsObjectName(true);
                                        updateErrorOneCharObjectName(false);
                                        return;
                                    }
                                    // const response = validateAtLeastOneCharacter(nodeName);
                                    // if (!response) {
                                    //     updateErrorName(true);
                                    //     updateErrorEmptyObjectName(false);
                                    //     updateErrorMaxLimitObjectName(false);
                                    //     updateErrorSpecialCharsObjectName(false);
                                    //     updateErrorOneCharObjectName(true);
                                    //     return;
                                    // }

                                    let tempNode = nodeDetails;
                                    tempNode.name = nodeName.trim();
                                    props.onApply(tempNode, treeNodeDetails.children, "");
                                }
                                if (props.actionType == ACTION_TYPE.ADD) {
                                    if (nodeName == "") {
                                        updateErrorName(true);
                                        updateErrorEmptyObjectName(true);
                                        updateErrorMaxLimitObjectName(false);
                                        updateErrorSpecialCharsObjectName(false);
                                        updateErrorOneCharObjectName(false);
                                        return;
                                    }
                                    if (nodeName && nodeName.trim().length > MAX_CHARACTERS) {
                                        updateErrorName(true);
                                        updateErrorEmptyObjectName(false);
                                        updateErrorMaxLimitObjectName(true);
                                        updateErrorSpecialCharsObjectName(false);
                                        updateErrorOneCharObjectName(false);
                                        return;
                                    }
                                    const res = validateSpecialCharsWithoutSwedishFinnish(nodeName, [".", "_", "-"]);
                                    if (!res) {
                                        updateErrorName(true);
                                        updateErrorEmptyObjectName(false);
                                        updateErrorMaxLimitObjectName(false);
                                        updateErrorSpecialCharsObjectName(true);
                                        updateErrorOneCharObjectName(false);
                                        return;
                                    }
                                    // const response = validateAtLeastOneCharacter(nodeName);
                                    // if (!response) {
                                    //     updateErrorName(true);
                                    //     updateErrorEmptyObjectName(false);
                                    //     updateErrorMaxLimitObjectName(false);
                                    //     updateErrorSpecialCharsObjectName(false);
                                    //     updateErrorOneCharObjectName(true);
                                    //     return;
                                    // }

                                    let tempNode = childNodeDetails;
                                    tempNode.name = nodeName.trim();
                                    let childMappedNode = mapTreeModel(tempNode);
                                    const childNodes = treeNodeDetails.children && Array.isArray(treeNodeDetails.children) ? treeNodeDetails.children.concat(childMappedNode) : [childMappedNode];
                                    // const childNodes = nodeDetails.children && Array.isArray(nodeDetails.children) ? nodeDetails.children.concat(tempNode) : [].concat(tempNode);
                                    props.onApply(nodeDetails, childNodes, "");
                                }
                                if (props.actionType == ACTION_TYPE.DELETE) {
                                    props.onApply(nodeDetails, treeNodeDetails.children && Array.isArray(treeNodeDetails.children) ? treeNodeDetails.children : [], "");
                                }
                                if (props.actionType == ACTION_TYPE.ROOT) {
                                    if (nodeName == "") {
                                        updateErrorName(true);
                                        updateErrorEmptyObjectName(true);
                                        updateErrorMaxLimitObjectName(false);
                                        updateErrorSpecialCharsObjectName(false);
                                        updateErrorOneCharObjectName(false);
                                        return;
                                    }
                                    if (nodeName && nodeName.trim().length > MAX_CHARACTERS) {
                                        updateErrorName(true);
                                        updateErrorEmptyObjectName(false);
                                        updateErrorMaxLimitObjectName(true);
                                        updateErrorSpecialCharsObjectName(false);
                                        updateErrorOneCharObjectName(false);
                                        return;
                                    }
                                    const res = validateSpecialCharsWithoutSwedishFinnish(nodeName, [".", "_", "-"]);
                                    if (!res) {
                                        updateErrorName(true);
                                        updateErrorEmptyObjectName(false);
                                        updateErrorMaxLimitObjectName(false);
                                        updateErrorSpecialCharsObjectName(true);
                                        updateErrorOneCharObjectName(false);
                                        return;
                                    }
                                    // const response = validateAtLeastOneCharacter(nodeName);
                                    // if (!response) {
                                    //     updateErrorName(true);
                                    //     updateErrorEmptyObjectName(false);
                                    //     updateErrorMaxLimitObjectName(false);
                                    //     updateErrorSpecialCharsObjectName(false);
                                    //     updateErrorOneCharObjectName(true);
                                    //     return;
                                    // }
                                    props.onApply(null, null, nodeName.trim());
                                }
                                resetModal();
                                props.onClose(false);
                            }}
                        />
                    </div>
                </ModalFooter>
            </Dialog>

            {/* Delete ObjectIds and ModelIds */}
        </>
    );
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        deleteCustomVariable: (objectId: any, modelId: any) => {
            dispatch(
                deleteCustomVariableRequest({
                    objectId: objectId,
                    modelId: modelId,
                })
            )
        }
    };
};
const mapStateToProps = (store: StoreState) => {
    return {
        isSystemCategorySelected: store.assets.systemCategorySelected
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomTreeAdd);
