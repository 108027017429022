import React, { ReactNode, useState, useEffect } from "react";
import classnames from "classnames";
import Flex from "../../components/shared/Flex";
import "./styles.scss";
import * as _ from "lodash";

interface SidebarContainerProps {
    isCollapse?: boolean;
    children?: React.ReactNode;
    isModelEditMode?: boolean;
    useDraggable?: boolean;
    customClassName?: string;
}

interface BaseLayoutProps {
    children: ReactNode;
    column?: boolean;
    hideTopBar?: boolean;
}

export const BaseLayout = (props: BaseLayoutProps) => {
    return (
        <Flex
            className={`base-layout-container ${
                props.hideTopBar ? "hideTopBar" : ""
                }`}
        >
            {props.children}
        </Flex>
    );
    // return (
    //  props.column ? (
    //     <Flex className="base-layout-edit-container" column={props.column}>
    //         {props.children[0]}
    //         <Flex row={true}>
    //             {props.children[1]}
    //             {props.children[2]}
    //         </Flex>
    //     </Flex>
    // ) : (
    // <Flex className="base-layout-container">{props.children}</Flex>
    // );
    // );
};
export const NoAccess = () => {
    return (
        <div className="base-layout">
            <b style={{ fontSize: `large`, margin: `8% 0 0 25%` }}>
                You don't have required permissions and roles. Please check with admin for access.
            </b>
        </div>
    );
};
export const SidebarContainer = (props: SidebarContainerProps) => {
    let isSideBarDragging = false;

    const handleDragStart = (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
        e.preventDefault();
        isSideBarDragging = true;
        if (props.useDraggable) {
            window.addEventListener("mousemove", moveSidebar);
        }
    };
    const moveSidebar = (e: MouseEvent) => {
        const element = document.getElementById("sideBarContainer");
        if (element && isSideBarDragging) {
            if (e.pageX < 1070) element.style.width = `${window.screen.width - e.pageX + 200}px`;
            else element.style.width = `330px`;
        }
        console.log("window width", window.screen.width)
        console.log("sidebar width", element ? element.style.width : 0);
        console.log("e.pageX", e.pageX);
        if (props.useDraggable) {
            window.addEventListener("mouseup", handleDragOver);
        }
    };

    const handleDragOver = (e: MouseEvent) => {
        console.log("drag over", props.useDraggable, props.isModelEditMode);

        e.preventDefault();

        const element = document.getElementById("sideBarContainer");
        if (element && isSideBarDragging) {
            if (e.pageX < 1070) element.style.width = `${window.screen.width - e.pageX + 200}px`;
            else element.style.width = `330px`;
        }
        window.removeEventListener("mousemove", moveSidebar);
        window.removeEventListener("mouseup", handleDragOver);
        isSideBarDragging = false;
    };

    useEffect(() => {
        return () => {
            window.removeEventListener("mouseup", handleDragOver);
            window.removeEventListener("mousemove", moveSidebar);
        };
    }, []);

    useEffect(() => {
        if (props.isModelEditMode) {
            const element = document.getElementById("sideBarContainer");
            if (element) {
                element.style.width = "330px";
            }
        }
        return () => {
            const element = document.getElementById("sideBarContainer");
            if (element) {
                element.style.width = "303px";
            }
        };
    }, [props.isModelEditMode]);

    return (
        <Flex
            id="sideBarContainer"
            className={classnames(
                `sidebar-container ${props.customClassName || ""}`,
                {
                    "edit-model-sidebar": props.isModelEditMode,
                    "is-collapse": props.isCollapse,
                    "margin-left": props.isModelEditMode,
                }
            )}
        >
            {props.useDraggable && (
                <div id="dragbar" draggable onMouseDown={handleDragStart}></div>
            )}
            {props.children}

        </Flex>
    );
};

export const BodyContainer = (props: any) => {
    return (
        <Flex
            className={classnames("editor-body-container", {
                // "left-padding": !prop s.isModelEditMode,
            })}
            fill
        >
            {props.children}
        </Flex>
    );
};
