import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import Condition from '../shared/Condition';
import { Icon, Dialog, Button, Input, Checkbox, Tooltip, WithTooltip } from '@abb/abb-common-ux-react';
import { ModalFooter, FormGroup, Label } from 'reactstrap';
import { StoreState } from '../../store';
import { Dispatch } from 'redux';
import { deleteCustomVariableRequest, dropTreeNode, dropTreeListNodeToggleAction, addChildNodesToParentDropNodeRequest, updateNewHierarchyNode, removeNodeFromTreeRequest, getNewAssetInstanceListActionRequest } from '../../store/assets/actions';
import { AbilityService } from 'sce-engg-model-19.09';
import { LEAF_DRAG_DATASET, NODE_MARGIN_LEFT, ACTION_TYPE, LOADER_SIZE, LOADER_TYPE, MAX_NODES, HIERARCHY_TOOL, NOTIFICATION_MODAL_STATUS } from '../../utils/constants/appConstants';
import Flex from '../shared/Flex';
import Tree, { Node } from 'react-virtualized-tree';
import Loader from '../Loader';
import { TreeNodeType } from '../../store/assets/types';
import { IStructure } from '../../store/assets/types';
import CustomTreeAdd from '../CustomTreeAdd';
import { mapTreeModel } from '../../store/assets/model';
import AppSettings from "../../services/AppSettings";
import { showNotificationModal } from "../../store/notificationModal/action";
import { relative } from "path";
import "./style.scss";

interface ICustomHierarchy {
    objectName: string;
    isNewSelected: boolean;
    selectedStructure: IStructure;
    onSave: (data: any) => void;
    onCancel: (value: boolean) => void;
}
const CustomHierarchy = (props: ICustomHierarchy &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>) => {


    const [treeList, setTreeData] = useState<Node[]>([]);
    const [showTreeAddDialog, updateTreeAddDialog] = useState(false);
    const [actionType, updateActionType] = useState("");
    const [nodeDetails, updateNodeDetails] = useState({} as any);
    const [treeNodeDetails, updateTreeNodeDetails] = useState({} as any);
    const [includeChildren, updateIncludeChildren] = useState(false);

    useEffect(() => {
        setTreeData(props.treeList!);
    }, [props.treeList])


    const updateTreeList = (node: TreeNodeType) => {
        if (node) {
            node.active = false;
            if (!includeChildren) {
                node.children = [];
                node.expanded = false;
                node.toggled = false;
                node.path = HIERARCHY_TOOL;
                node.isLeaf = false;
            }
            props.dropTreeNode(node);
        }
    }
    return (
        <>
            <Flex id="topHierarchyName" inline>
                <Condition when={props.isNewSelected}>
                    <h3>{props.objectName}</h3>
                </Condition>
                <Condition when={!props.isNewSelected}>
                    <Icon name="abb/caret-left" sizeClass="medium" onClick={() => props.onCancel(false)} />
                    <h3>{props.selectedStructure !== null && props.selectedStructure !== undefined ? props.selectedStructure.name : ""}</h3>
                </Condition>

                {/* <Checkbox
                    id="includeChildren"
                    label="Include Children"
                    style={{ marginLeft: `25%` }}
                    sizeClass={"small"}
                    value={includeChildren}
                    onChange={() => {
                        updateIncludeChildren(!includeChildren)
                    }
                    }
                /> */}

                <hr />
            </Flex>
            <div>
                <Button
                    text="Add Root"
                    icon="abb/folder-new"
                    sizeClass="small"
                    shape="rounded"
                    type="primary-blue"
                    className="customDeleteButton"
                    style={{ margin: `16px 0px 0px 12px` }}
                    onClick={() => {
                        updateActionType(ACTION_TYPE.ROOT);
                        updateTreeAddDialog(true);
                    }}
                />
            </div>
            <div className="h5Label">
                <Label for="name">
                    NAME
                </Label>
                <Condition when={props.isTreeHierarchyLoading}>
                    <div className="customLoading">
                        <Loader
                            sizeClass={LOADER_SIZE.LARGE}
                            type={LOADER_TYPE.RADIAL}
                        />
                    </div>
                </Condition>

                <Label for="actions">
                    ACTIONS
                </Label>

            </div>
            <Flex row>

                <div className="onDropContainer"
                    onDrop={(e: React.DragEvent<HTMLDivElement>) => {
                        if (e.dataTransfer.getData(LEAF_DRAG_DATASET)) {
                            let data = JSON.parse(e.dataTransfer.getData(LEAF_DRAG_DATASET));
                            updateTreeList(data.node);
                        }
                    }}
                    onDragOver={e => {
                        e.preventDefault()
                    }}>
                    {/* Tree Section */}
                    <>
                        {(

                            <Tree
                                // nodeMarginLeft={0}
                                nodes={treeList}
                                onChange={nodes => {
                                    setTreeData(nodes);
                                }}
                            >
                                {({ style, node }) => {
                                    const isToggled = node.state && node.state.toggled;
                                    const isLoading = node.state && node.state.loading;
                                    const isActive = node.state && node.state.active;
                                    const isTopLevelParent = treeList.findIndex(i => i.id == node.id) > -1;
                                    const isLeafNode = node.state && node.state.isLeaf;
                                    const path = node.state ? node.state.path : null;
                                    const maxLevel = node.state ? node.state.level : null;
                                    const isDevDeleted = node.state ? node.state.isDevDeleted : false;
                                    // const isLeafNode = _.get(node.state, 'children[0].isLeaf', false);
                                    // console.info(rest);
                                    return (
                                        <React.Fragment>
                                            <div
                                                style={{ ...style, marginLeft: `0px !important`, paddingLeft: node.deepness * 12 }}
                                                className="defaultClassTree"
                                                onDragStart={(e: any) => {
                                                    e.dataTransfer.setData(
                                                        LEAF_DRAG_DATASET,
                                                        JSON.stringify({ node: node.state })
                                                    );
                                                }}
                                                // draggable={(isLeafNode && leafNodeDrag) || fullNodeDrag}
                                                onDrop={(e: React.DragEvent<HTMLDivElement>) => {
                                                    if (e.dataTransfer.getData(LEAF_DRAG_DATASET)) {
                                                        let data = JSON.parse(e.dataTransfer.getData(LEAF_DRAG_DATASET));
                                                        data.node.children = [];
                                                        data.node.path = HIERARCHY_TOOL;
                                                        data.node.isLeaf = false;
                                                        if (node.children && Array.isArray(node.children)) {
                                                            // const isExist = node.children.findIndex(x => x.id == data.node.objectId) > -1;
                                                            let childMappedNode = mapTreeModel(data.node);
                                                            if (!isDevDeleted && AppSettings.IsCOD) {
                                                                const childNodes = node.children && Array.isArray(node.children) ? node.children.concat(childMappedNode) : [childMappedNode];
                                                                props.addChildNodesToParentDNR(node.state!, childNodes, "", "");
                                                            }
                                                            else if (!isDevDeleted && maxLevel < MAX_NODES + 1) {
                                                                const childNodes = node.children && Array.isArray(node.children) ? node.children.concat(childMappedNode) : [childMappedNode];
                                                                props.addChildNodesToParentDNR(node.state!, childNodes, "", "");
                                                            }
                                                            if (isDevDeleted) {
                                                                props.showNotificationModal({
                                                                    title: "NODE ADDITION WARNING",
                                                                    resultStatus: NOTIFICATION_MODAL_STATUS.WARNING,
                                                                    type: "confirmation",
                                                                    details: ["New node can't be added due to its state"],
                                                                });
                                                            }
                                                            // const childNodes = node.state!.children ? node.state!.children!.concat(data.node) : [].concat(data.node);
                                                            // if (!isExist) props.addChildNodesToParentDNR(node.state!, childNodes, "", actionType);
                                                        }
                                                    }
                                                    e.stopPropagation();
                                                }}
                                                onDragOver={e => e.preventDefault()}
                                                onClick={() => {
                                                    if (node && node.state) {
                                                        node.state.expanded = !node.state.expanded;
                                                        node.state.toggled = !node.state.toggled;
                                                        props.dropTreeListNodeToggleAction(node.state);
                                                        props.getNewAssetInstanceList(node.state, "NEW", false);
                                                    }
                                                }}
                                            >
                                                <Condition when={isLoading}>
                                                    {/* <Flex center> */}
                                                    <Loader
                                                        sizeClass="small"
                                                        type="radial"
                                                    />
                                                    {/* </Flex> */}
                                                </Condition>
                                                <Condition when={!isLoading}>
                                                    <div className={`${isActive ? "isActive tree-view-list" : "tree-view-list"}`} >

                                                        <Condition when={!isLeafNode}>
                                                            <Icon name={isToggled ? "abb/down" : "abb/right"} sizeClass="small" style={{ paddingTop: "0px" }} />
                                                        </Condition>
                                                        <Condition when={!isDevDeleted}>
                                                            <Condition when={!isLeafNode && !isTopLevelParent}>
                                                                <Icon name="abb/object-container-2" sizeClass="small" className="treeIconPaddingLeft" />
                                                            </Condition>
                                                            <Condition when={isLeafNode}>
                                                                <Icon name="abb/object" sizeClass="small" className="treeIconPaddingLeft" />
                                                            </Condition>
                                                            <Condition when={isTopLevelParent && !isToggled && !isLeafNode}>
                                                                <div className="tooltipCustom">
                                                                    <p style={{ paddingLeft: `8px`, width: `100% !important` }}>
                                                                        <Icon name="abb/folder" sizeClass="small" className="treeIconPaddingLeft" />
                                                                    </p>
                                                                    <span className="tooltiptextCustom">{"FOLDER"}</span>
                                                                </div>
                                                            </Condition>
                                                            <Condition when={isTopLevelParent && isToggled && !isLeafNode}>
                                                                <div className="tooltipCustom">
                                                                    <p style={{ paddingLeft: `8px`, width: `100% !important` }}>
                                                                        <Icon name="abb/folder-open" sizeClass="small" className="treeIconPaddingLeft" />
                                                                    </p>
                                                                    <span className="tooltiptextCustom">{"OPEN FOLDER"}</span>
                                                                </div>
                                                            </Condition>
                                                        </Condition>
                                                        <Condition when={isDevDeleted}>
                                                            <div className="tooltipCustom">
                                                                <p style={{ paddingLeft: `8px`, width: `100% !important` }}>
                                                                    <Icon name="abb/warning-triangle" sizeClass="small" style={{ paddingTop: "0px" }} />
                                                                </p>
                                                                <span className="tooltiptextCustom">{"WARNING"}</span>
                                                            </div>
                                                        </Condition>
                                                        <React.Fragment>
                                                            <div className="w-full">
                                                                <Flex inline justifyContentBetween className="w-full">
                                                                    <div>
                                                                        <div className="tooltipCustom">
                                                                            <p style={{ paddingLeft: `8px`, width: `100% !important` }}>{node.name ? node.name : " "}</p>
                                                                    <span className="tooltiptextCustom"> {node.name}</span>
                                                                        </div>
                                                                    </div>
                                                                    <Flex>
                                                                        <Condition when={path == null || path == "" || path == HIERARCHY_TOOL}>
                                                                        <Condition when={!isDevDeleted}>
                                                                                <div className="tooltipCustom">
                                                                                    <p style={{ paddingLeft: `8px`, width: `100% !important` }}>

                                                                                <Icon name="abb/edit"  sizeClass="small" onClick={(e: any) => {
                                                                                    updateActionType(ACTION_TYPE.EDIT);
                                                                                    updateTreeAddDialog(true);
                                                                                    updateNodeDetails(node.state!);
                                                                                    updateTreeNodeDetails(node);
                                                                                    e.stopPropagation();
                                                                                        }} /></p>
                                                                                    <span className="tooltiptextCustom">{ACTION_TYPE.EDIT}</span>
                                                                            </div>
                                                                        </Condition>
                                                                        <Condition when={!isDevDeleted && !AppSettings.IsCOD}>
                                                                            <Condition when={maxLevel < MAX_NODES + 1}>
                                                                                    <div className="tooltipCustom">
                                                                                        <p style={{ paddingLeft: `8px`, width: `100% !important` }}>
                                                                                            <Icon name="abb/folder-new" sizeClass="small" className="marginIcon" onClick={(e: any) => {
                                                                                        updateActionType(ACTION_TYPE.ADD);
                                                                                        updateTreeAddDialog(true);
                                                                                        updateNodeDetails(node.state!);
                                                                                        updateTreeNodeDetails(node);
                                                                                        e.stopPropagation();
                                                                                    }} />
                                                                                        </p>
                                                                                        <span className="tooltiptextCustom">{ACTION_TYPE.ADD}</span>
                                                                                    </div>
                                                                            </Condition>
                                                                            <Condition when={maxLevel >= MAX_NODES + 1}>
                                                                                <Icon name="abb/folder-new" sizeClass="small" className="marginIcon disabled" onClick={(e: any) => {
                                                                                    // updateActionType(ACTION_TYPE.ADD);
                                                                                    // updateTreeAddDialog(true);
                                                                                    // updateNodeDetails(node.state!);
                                                                                    // updateTreeNodeDetails(node);
                                                                                    e.stopPropagation();
                                                                                }} />
                                                                            </Condition>
                                                                        </Condition>
                                                                        <Condition when={!isDevDeleted && AppSettings.IsCOD}>
                                                                                <div className="tooltipCustom">
                                                                                    <p style={{ paddingLeft: `8px`, width: `100% !important` }}>
                                                                                        <Icon name="abb/folder-new" sizeClass="small" className="marginIcon" onClick={(e: any) => {
                                                                                    updateActionType(ACTION_TYPE.ADD);
                                                                                    updateTreeAddDialog(true);
                                                                                    updateNodeDetails(node.state!);
                                                                                    updateTreeNodeDetails(node);
                                                                                    e.stopPropagation();
                                                                                }} />
                                                                                    </p>
                                                                                    <span className="tooltiptextCustom">{ACTION_TYPE.ADD}</span>
                                                                                </div>
                                                                        </Condition>
                                                                            <div className="tooltipCustom">
                                                                                <p style={{ paddingLeft: `8px`, width: `100% !important` }}>
                                                                                    <Icon name="abb/close-light" sizeClass="small" className="marginIcon" onClick={(e: any) => {
                                                                                updateActionType(ACTION_TYPE.DELETE);
                                                                                updateTreeAddDialog(true);
                                                                                updateNodeDetails(node.state!);
                                                                                updateTreeNodeDetails(node);
                                                                                e.stopPropagation();
                                                                            }} />
                                                                                </p>
                                                                                <span className="tooltiptextCustom">{ACTION_TYPE.DELETE}</span>
                                                                            </div>
                                                                        </Condition>
                                                                    </Flex>



                                                                </Flex>

                                                            </div>
                                                        </React.Fragment>
                                                    </div>
                                                </Condition>
                                            </div>
                                        </React.Fragment>
                                    );
                                }}
                            </Tree>
                        )}
                    </>
                </div>
                <Condition when={actionType !== ""}>
                    <CustomTreeAdd showDialog={showTreeAddDialog}
                        actionType={actionType}
                        node={nodeDetails}
                        treeNode={treeNodeDetails}
                        onApply={(node: any, childNodes: any, name: string) => {
                            if (actionType == ACTION_TYPE.ADD || actionType == ACTION_TYPE.EDIT) {
                                props.addChildNodesToParentDNR(node, childNodes, "", actionType);
                                updateActionType("");
                            }
                            else if (actionType == ACTION_TYPE.DELETE) {
                                props.removeNodeFromTree(node.objectId);
                                updateActionType("");
                            }
                            else if (actionType == ACTION_TYPE.ROOT) {
                                props.addChildNodesToParentDNR(node, childNodes, name, actionType);
                                updateActionType("");
                            }
                        }}
                        onClose={(showDialog: boolean) => {
                            updateTreeAddDialog(showDialog);
                            updateActionType("");
                        }} />
                </Condition>
            </Flex>
        </>
    );
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        dropTreeNode: (node: TreeNodeType) => {
            dispatch(
                dropTreeNode(node)
            )
        },
        dropTreeListNodeToggleAction: (node: any) => {
            dispatch(
                dropTreeListNodeToggleAction(node)
            )
        },
        addChildNodesToParentDNR: (node: any, childNodes: any, name: string, actionType: string) => {
            dispatch(
                addChildNodesToParentDropNodeRequest(node, childNodes, name, actionType)
            )
        },
        removeNodeFromTree: (nodeId: any) => {
            dispatch(
                removeNodeFromTreeRequest(nodeId)
            )
        },
        getNewAssetInstanceList: (
            selectedNode: any,
            selectedType: string,
            includeAllChild: boolean
        ) => {
            dispatch(
                getNewAssetInstanceListActionRequest({
                    node: selectedNode,
                    type: selectedType,
                    activePage: 1,
                })
            );
        },
        showNotificationModal: (val: any) =>
            dispatch(showNotificationModal(val)),
    };
};
const mapStateToProps = (store: StoreState) => {
    return {
        treeList: store.assets.treeNewVirtualList.children,
        isTreeHierarchyLoading: store.assets.isTreeHierarchyLoading
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomHierarchy);
