import _, { reduce } from "lodash";
import JSZip from "jszip";
// import { IODataTypes } from "../components/Fabric/types";
import { AbbResponseType, commonAuthHelper } from "./types/commonInterface";

// import FunctionTypeDetail from "../transformers/AssetType/FunctionType";
// import { LocalJson } from "../transformers/ComputeModel/fromJson/types";
// import { TableData } from "../model/TableController";
// import {
//     FunctionTypeDetailsWithMappings,
//     TableDropDownData,
// } from "../model/TableController/types";
// import ObjectTypeDetail from "../transformers/AssetType/ObjectType";
// import { FunctionHeaderTypeDetails } from "../store/function/types";
import { TABLE_IO_TYPE, CONFIGURATION_STATUS } from "./constants/appConstants";
import { CSSProperties } from "react";
import AppSettings from '../services/AppSettings';
import { AbilityService } from 'sce-engg-model-19.09';
import jwt_decode from "jwt-decode";
import { SupportedNewModel } from "../store/supportedConditionMonitors/types";

export const isNilOrEmpty = (val: any): boolean => {
    return Boolean(_.isNil(val) || _.isEmpty(val));
};

// export const isIOConnectionArrayType = (type: string) => {
//     return IODataTypes.ARRAY === type;
// };

export const abbSDKResponseHandle = (response: AbbResponseType) => {
    if (
        response.status >= httpStatus.OK &&
        response.status <= httpStatus.MULTIPLE_CHOICES
    ) {
        return response;
    }

    throw new Error("Something went wrong");
};

export const isAssetInstanceNotConfigured = (configureStatus: string) => {
    return configureStatus === CONFIGURATION_STATUS.YET_TO_BE_CONFIGURED;
};

export const abbSDKGetErrorMessage = (error: any) => {
    if (error.response && error.response.data && error.response.data.meta) {
        let errorArray = error.response.data.meta || "";
        let errorMessage = "";
        errorMessage = errorArray[errorArray.length - 1].detail;
        return errorMessage;
    } 
    // else if (error.message) {
    //     let message = error.message || "";
    //     try {
    //         const parsedError = JSON.parse(error.message);
    //         message = parsedError.meta
    //             ? parsedError.meta.error
    //                 ? parsedError.meta.error
    //                 : parsedError.meta
    //             : parsedError;
    //     } catch (error) { }
    //     return message.toString();
    // }

    else if (error.response && error.response.data) {
        const errorData = error.response.data;
        let errorMessage = error.message || '';
        if (Array.isArray(errorData)) {
            /** differen error format coming
             * {reason:string, title:string,status:number}[]
             * or
             * {detail:string,status:number}[]
             */
            if (errorData[0].details) {
                return errorData[0].details;
            }
            if(errorData[0].reason){
                return errorData[0].reason;
            }

        }
        return errorMessage.toString();
    } else if (error.message) {
        let message = error.message || '';
        try {
            const parsedError = JSON.parse(error.message);
            message = parsedError.meta
                ? parsedError.meta.error
                    ? parsedError.meta.error
                    : parsedError.meta
                : parsedError;
        } catch (error) {}
        return message.toString();
    }
};


// SEARCH HELPER FUNCTION .

export const filterListData = (
    data: any[],
    path: string,
    searchValue: string
) => {
    const filterList: any[] = [];
    data.forEach((item, index) => {
        let res = _.get(item, path, "");
        if (res.toLowerCase().includes(searchValue.toLowerCase()))
            filterList.push(item);
    });

    return filterList;
};


const getUpdateDetails = (details: any[], overallStatus: boolean) => {
    const updatedDetails = details.map((detail) => {
        if (!detail.hasOwnProperty("isDisabled")) {
            if (overallStatus === false && detail.hasOwnProperty("isDelete")) {
                delete detail.isDelete;
            } else if (
                overallStatus === true &&
                !detail.hasOwnProperty("isDelete")
            ) {
                detail.isDelete = true;
            }
        }

        return detail;
    });
    return updatedDetails;
};
const getUpdatedObject = (details: any[], updatedDetails: any[]) => {
    let updatedObj = {};
    let i = 0;
    details.forEach((detail: any) => {
        updatedObj[detail] = updatedDetails[i];
        i++;
    });
    return updatedObj;
};

export const getUpdatedConditionDetails = (
    key: string,
    conditionDetails: {}
) => {
    let updatedConditionDetails = conditionDetails;
    const selectedObj = updatedConditionDetails[key];
    if (selectedObj.hasOwnProperty("deleteFlag")) {
        if (selectedObj.hasOwnProperty("subConditions")) {
            Object.values(Object.values(selectedObj)[0] as {}).map(
                (subCondition: any) => {
                    delete subCondition.deleteFlag;
                }
            );
        }
        delete selectedObj.deleteFlag;
    } else {
        if (selectedObj.hasOwnProperty("subConditions")) {
            Object.values(Object.values(selectedObj)[0] as {}).map(
                (subCondition: any) => {
                    subCondition.deleteFlag = true;
                }
            );
        }
        selectedObj.deleteFlag = true;
    }
    return updatedConditionDetails;
};

export const getUpdatedList = (functions: any[], newFunction: any) => {
    let updatedFunctions = [];
    let functionPresent = false;
    functions.forEach((functionDetail) => {
        if (functionDetail.id.value === newFunction.id.value) {
            functionPresent = true;
        }
    });
    if (functionPresent) {
        const reduced = functions.filter((functionDetail) => {
            return functionDetail.id.value !== newFunction.id.value;
        });
        updatedFunctions = [...reduced, newFunction];
    } else {
        updatedFunctions = [...functions, newFunction];
    }

    updatedFunctions.sort(function (functionA, functionB) {
        var nameA = functionA.name.value.toLowerCase(),
            nameB = functionB.name.value.toLowerCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
    });

    return updatedFunctions;
};
const formatData = (details: any) => {
    Object.values(details).forEach((detail: any) => {
        if (
            detail.hasOwnProperty("isDelete") ||
            detail.hasOwnProperty("deleteFlag")
        ) {
            delete detail.isDelete;
            delete detail.deleteFlag;
        }
    });
    return details;
};
const formatConditionData = (details: any) => {
    Object.values(details).forEach((detail: any) => {
        if (detail.hasOwnProperty("deleteFlag")) {
            if (detail.hasOwnProperty("subConditions")) {
                detail.subConditions = formatData(detail.subConditions);
            }
            delete detail.deleteFlag;
        }
    });
    return details;
};
export const objectsToSave = (
    inputDetails: any,
    conditionDetails: any,
    calculationDetails: any,
    outputDetails: any
) => {
    const formattedData = {
        inputDetails: _.cloneDeep(inputDetails),
        conditionDetails: _.cloneDeep(conditionDetails),
        calculationDetails: _.cloneDeep(calculationDetails),
        outputDetails: _.cloneDeep(outputDetails),
    };
    formattedData.inputDetails = formatData(inputDetails);
    formattedData.outputDetails = formatData(outputDetails);
    formattedData.calculationDetails = formatData(calculationDetails);
    formattedData.conditionDetails = formatConditionData(conditionDetails);
};

export const finalOutputConditionJSON = (
    conditionDetails: any,
    objectLink: string
) => {
    const finalObj = {};
    Object.keys(conditionDetails).forEach((conditionName) => {
        const subCondition = conditionDetails[conditionName]["subConditions"];
        const subConditionKeys = Object.keys(subCondition);
        finalObj[conditionName] = {
            subCondition: {
                dataType: "string",
                enum: [...subConditionKeys],
            },
        };
        const subConditionProperties = subCondition[subConditionKeys[0]];
        Object.keys(subConditionProperties).forEach((property) => {
            finalObj[conditionName][property] = {
                dataType: subConditionProperties[property]["dataType"],
            };
        });
        finalObj[conditionName][conditionName + "Alarm"] = {
            dataType: "string",
            objectLink: objectLink,
        };
    });

    return finalObj;
};

export function validURL(str: string) {
    // var pattern = new RegExp(
    //     "^(https?:\\/\\/)" + // protocol
    //     "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
    //     "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
    //     "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
    //     "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
    //         "(\\#[-a-z\\d_]*)?$",
    //     "i"
    // ); // fragment locator
    // return !!pattern.test(str);

    try {
        const url = new URL(str);
        if (url.href === str) {
            return true;
        }
    } catch (err) {
        console.log(err);
        return false;
    }
    return false;
}

export const getJsonArrayfromZip = async (filesToBeImported: any) => {
    let jsonsToBeImported: any = [];
    var blob = new Blob([filesToBeImported as any], {
        type: "application/zip",
    });
    var new_zip = new JSZip();
    try {
        jsonsToBeImported = await new_zip
            .loadAsync(blob)
            .then(async (zipped: any) => {
                return Promise.all(
                    Object.keys(zipped.files).map(async (filename) => {
                        const json = await zipped.file(filename);
                        let finalJson = undefined;
                        if (json) {
                            finalJson = await json.async("string");
                        }
                        let res = null;
                        if (finalJson && filename[0] !== "_") {
                            res = JSON.parse(finalJson);
                        }
                        return res;
                    })
                );
            });
        jsonsToBeImported = jsonsToBeImported.filter(
            (item: any) => item !== null
        );
        return jsonsToBeImported;
    } catch (e) {
        return;
    }
};

export const getAssetRef = (supportedModels: any, modalType: any) => {
    let modalRef: any;
    const modalName = Object.keys(modalType)[0];
    supportedModels.forEach((supportedModalType: any) => {
        const assets: any = Object.values(supportedModalType)[1];
        assets.forEach((assetType: any) => {
            if (assetType.assetName === modalName) {
                modalRef = assetType.assetType;
            }
        });
    });
    return modalRef;
};

export const customReactSelectStyles = {
    menu: (provided: any, state: any) => ({
        ...provided,
        marginTop: "0px",
        borderRadius: "0px",
    }),
    valueContainer: (provided: any, state: any) => ({
        ...provided,

        maxHeight: "100px",
        overflow: "auto",
    }),
    indicatorSeparator: (provided: any, state: any) => ({
        ...provided,
        display: "none",
    }),

    control: (styles: CSSProperties, state: any) => ({
        ...styles,
        backgroundColor: "white",
        borderRadius: "0px",
        border: state.isSelected || state.isFocused ? "1px solid #3366ff" : "",
    }),
    option: (provided: CSSProperties, state: any) => ({
        ...provided,
        backgroundColor: state.isSelected ? "#dbdbdb" : "#fff",

        ":hover": {
            backgroundColor: "#dbdbdb",
        },
    }),

    multiValue: (styles: any) => {
        return {
            ...styles,
            backgroundColor: "#dbdbdb",
            borderRadius: "40px",
            padding: "2px",
        };
    },
    multiValueLabel: (styles: any) => ({
        ...styles,
        color: "#1f1f1f",
        maxWidth: "150px",
        backgroundColor: "#dbdbdb",
        borderRadius: "40px",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
    }),
    multiValueRemove: (styles: any) => ({
        ...styles,
        color: "#1f1f1f",
        backgroundColor: "#dbdbdb",
        borderRadius: "40px",
        ":hover": {
            backgroundColor: "#858585",
            color: "#1f1f1f",
            borderRadius: "40px",
        },
    }),
    singleValue: (provided: any, state: any) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = "opacity 300ms";

        return { ...provided, opacity, transition };
    },
};

export const assetSelectedToDelete = (assetsArray: any[], searchItem: any) => {
    let present = assetsArray.findIndex((item: any, index: any) => {
        if (item.nodeId === searchItem.nodeId) {
            return true;
        }
        return false;
    });
    return present;
};

export const deleteFromCanvas = (assetsArray: any, tableData: any) => {
    let updatedTableData = _.cloneDeep(tableData);
    updatedTableData.functionTypeDetails = updatedTableData.functionTypeDetails.filter(
        (item: any) => assetSelectedToDelete(assetsArray, item) === -1
    );
    updatedTableData.functionTypeDetails = updatedTableData.functionTypeDetails.map(
        (functionDetail: any) => {
            const functionInputs = functionDetail.inputs;
            functionInputs.map((inputType: any) => {
                const mappingDetails = inputType.mappingDetails[0];
                if (
                    mappingDetails &&
                    (assetSelectedToDelete(
                        assetsArray,
                        mappingDetails.input.asset
                    ) !== -1 ||
                        assetSelectedToDelete(
                            assetsArray,
                            mappingDetails.output.asset
                        ) !== -1)
                ) {
                    inputType.mappingDetails = [];
                }
                return inputType;
            });
            return functionDetail;
        }
    );
    updatedTableData.objectTypeDetails = updatedTableData.objectTypeDetails.filter(
        (item: any) => assetSelectedToDelete(assetsArray, item) === -1
    );
    return updatedTableData;
};

export const handleTokenDecode = () => {
    // const token =
    //     AppSettings.cacheLocation === "localStorage"
    //         ? localStorage.getItem("token")
    //         : sessionStorage.getItem("token");
            const token = commonAuthHelper.getToken();
            if (!!token) AbilityService.decodeToken(token);
};

export const checkValidityForMonitorsInput = (
    index: number,
    value: string,
    key: string,
    conditionMonitorListToRender: SupportedNewModel[]
) => {
    let valid = true;
    let text = 'OK!';
    let updatedValue = _.cloneDeepWith(value).split('.').join('');
    if (!validateForSwedishAndFinishCharacters(updatedValue)) {
        valid = false;
        text = `Condition Monitor ${key} cannot have special characters`;
    }

    if (!checkWhetherConditionMonitorIsUnique(conditionMonitorListToRender, index, value)) {
        valid = false;
        text = `Condition Monitor ${key} should be unique`;
    }
    return { valid, text };
};

export const checkWhetherConditionMonitorIsUnique = (
    conditionMonitorListToRender: any[],
    currentMonitorIndex: number,
    value: string
) => {
    const filteredMonitorList = conditionMonitorListToRender.filter(
        (item, index) => index !== currentMonitorIndex
    );
    let isUnique = true;
    filteredMonitorList.forEach((item) => {
        if (item === value) {
            isUnique = false;
        }
    });
    return isUnique;
};

export type VersionUpdateType = "MAJOR" | "MINOR" | "PATCH" | "DEFAULT";
export const VERSION_UPDATE_TYPE = <const>{
    MAJOR: "MAJOR",
    MINOR: "MINOR",
    PATCH: "PATCH",
    DEFAULT: "DEFAULT",
};

export const checkVersionUpdateStatus = (options: {
    oldVersion: string;
    currentVersion: string;
    versionUpdateType: VersionUpdateType;
}) => {
    const { oldVersion, currentVersion, versionUpdateType } = options;

    let errorText = "";
    let successVersionUpdate = true;
    const oldVersionData = oldVersion.split(".");
    const currentVersionData = currentVersion.split(".");
    const oldData = {
        major: parseInt(oldVersionData[0]),
        minor: parseInt(oldVersionData[1]),
        patch: parseInt(oldVersionData[2]),
    };
    const currentData = {
        major: parseInt(currentVersionData[0]),
        minor: parseInt(currentVersionData[1]),
        patch: parseInt(currentVersionData[2]),
    };

    switch (versionUpdateType) {
        case VERSION_UPDATE_TYPE.MAJOR: {
            if (currentData.major <= oldData.major) {
                errorText = `Please update the version to ${oldData.major + 1
                    }.0.0 or higher.`;
                successVersionUpdate = false;
            }
            if (currentData.minor !== 0 || currentData.patch !== 0) {
                errorText = `Only major version update required.Please set minor and patch version to 0.`;
                successVersionUpdate = false;
            }
            break;
        }
        case VERSION_UPDATE_TYPE.MINOR: {
            if (currentData.major === oldData.major) {
                if (currentData.minor <= oldData.minor) {
                    errorText = `Please update the version to ${oldData.major
                        }.${oldData.minor + 1}.${oldData.patch} or higher.`;
                    successVersionUpdate = false;
                }
            }
            if (currentData.major > oldData.major) {
                if (currentData.minor !== 0 || currentData.patch !== 0) {
                    errorText = `Please increment either major or minor version (${oldData.major
                        }.${oldData.minor + 1}.${oldData.patch} or higher).`;
                    successVersionUpdate = false;
                }
            }
            if (oldData.major > currentData.major) {
                if (
                    oldData.minor > currentData.minor ||
                    oldData.patch > currentData.patch
                ) {
                    errorText = `Please update the version higher than ${oldData.major
                        }.${oldData.minor + 1}.${oldData.patch}.`;
                    successVersionUpdate = false;
                }
            }
            break;
        }
        case VERSION_UPDATE_TYPE.PATCH: {
            if (currentData.major > oldData.major) {
                if (
                    currentData.minor !== oldData.minor ||
                    currentData.patch !== oldData.patch
                ) {
                    errorText = `Please update either major or minor version`;
                    successVersionUpdate = false;
                }
            }
            if (currentData.major === oldData.major) {
                if (currentData.minor === oldData.minor) {
                    if (currentData.patch <= oldData.patch) {
                        errorText = `Please update the version to ${oldData.major
                            }.${oldData.minor}.${oldData.patch + 1} or higher.`;
                        successVersionUpdate = false;
                    }
                } else if (currentData.minor < oldData.minor) {
                    errorText = `Please update the version to ${oldData.major
                        }.${oldData.minor}.${oldData.patch + 1} or higher.`;
                    successVersionUpdate = false;
                }
            }
            if (currentData.major > oldData.major) {
                if (currentData.minor !== 0 || currentData.patch !== 0) {
                    errorText = `Please update either major or minor version`;
                    successVersionUpdate = false;
                }
            }
            break;
        }
        case VERSION_UPDATE_TYPE.DEFAULT: {
            if (oldVersion !== currentVersion) {
                errorText = `No changes!. Please set version to ${oldData.major}.${oldData.minor}.${oldData.patch}.`;
                successVersionUpdate = false;
            }
            break;
        }
        default: {
            console.log("Invalid version update scenario");
        }
    }

    return {
        errorVersionUpdateText: errorText,
        successVersionUpdate: successVersionUpdate,
    };
};

export const isMinorVersionUpdate = (options: {
    oldVersion: string;
    currentVersion: string;
}) => {
    const { oldVersion, currentVersion } = options;
    let isMinorVersionUpdate = false;

    const oldVersionData = oldVersion.split(".");
    const currentVersionData = currentVersion.split(".");
    const oldData = {
        major: parseInt(oldVersionData[0]),
        minor: parseInt(oldVersionData[1]),
        patch: parseInt(oldVersionData[2]),
    };
    const currentData = {
        major: parseInt(currentVersionData[0]),
        minor: parseInt(currentVersionData[1]),
        patch: parseInt(currentVersionData[2]),
    };

    if (currentData.major === oldData.major) {
        if (currentData.minor > oldData.minor) {
            isMinorVersionUpdate = true;
        }
    }
    return isMinorVersionUpdate;
};

export const checkPermissionRoles = (token: string): boolean => {
    const decodedToken: any = jwt_decode(token);
    return (decodedToken && decodedToken.roles && Array.isArray(decodedToken.roles) && decodedToken.roles.length > 0);
}
export const hasWhiteSpace = (s: string) => {
    return /\s/g.test(s);
};

export const hasSpecialCharacters = (s: string) => {
    return /[ `!@#$%^&*()+\=\[\]{};':"\\|,.<>\/?~]/.test(s);
};

export const hasSpecialCharactersExceptDot = (s: string) => {
    return /[ `!@#$%^&*()+\=\[\]{};':"\\|,<>\/?~]/.test(s);
};

export const validateForSwedishAndFinishCharacters = (s: string) => {
    let valid = true;
    // const pattern = new RegExp(
    //     "^(?=.{1,150}$)[\\w\\-]([\\w\\-]|[\\.][\\w\\-])*$"
    // );
    // const pattern = new RegExp("^@#$%&*()$");
    const pattern = new RegExp(/\p{L}/gu);
    valid = pattern.test(s);
    if (valid && s.indexOf(".") !== -1) {
        valid = false;
    }
    return valid;
};

export const validateOnlyNumberLetters = (s: string) => {
    let valid = true;
    const pattern = new RegExp('^[A-Za-z0-9]{0,150}$');
    valid = pattern.test(s);
    return valid;
};


export const validateSpecialCharsWithoutSwedishFinnish = (item: string, exclude?: string[]) => {
    const specialChars = ["`", "!", "~", "@", "#", "$", "%", "^", "&", "*", "(", ")", "=", "[", "]", "{", "}", "<", ">", "?", ":", ";", ",", ".", "\"", "\'", "|", "\\", "/", "-", "+", "_"];
    let isFounded = item.split("").some(x => {
        if (exclude && exclude.includes(x)) {
            return false;
        } else {
            return specialChars.includes(x)
        }
    });
    return !isFounded;
}

export const validateAtLeastOneCharacter = (n: string) => {
    const regex = /[a-zA-Z]/;
    return regex.test(n);
};

export const hasNegativeValue = (n: string) => {
    return /^[1-9][0-9]*$/.test(n);
};

export const checkIntegerArray = (n: string) => {
    return /^[0-9,]*$/.test(n);
};

export const checkNumberArray = (n: string) => {
    return /^((([-]?[0-9])+([\.]*[0-9]*[,]*))*)$/.test(n);
};
export const onlyNumber = (n: string) => {
    return /^[0-9]*$/.test(n);
};

export const monitorsList = (supportedConditionMonitors: SupportedNewModel[]) => {
    const monitorList = supportedConditionMonitors.map((monitor) => {
        return monitor.modelName;
    });

    return monitorList;
};

export const getMonitorsToDeleteLocally = (
    selectedConditionMonitorModels: SupportedNewModel[],
    addedSupportedConditionMonitorModels: SupportedNewModel[]
) => {
    let monitorsToDeleteLocally: SupportedNewModel[] = [];

    selectedConditionMonitorModels.forEach((monitor) => {
        let isLocalMonitorSelected = false;
        addedSupportedConditionMonitorModels.forEach((localMonitor) => {
            if (localMonitor.modelName === monitor.modelName) {
                isLocalMonitorSelected = true;
            }
        });
        if (isLocalMonitorSelected) {
            monitorsToDeleteLocally.push(monitor);
        }
    });

    return monitorsToDeleteLocally;
};

export const getMonitorsToDeleteRemotely = (
    selectedConditionMonitorModels: SupportedNewModel[],
    monitorsToDeleteLocally: SupportedNewModel[]
) => {
    let monitorsToDeleteRemotely: SupportedNewModel[] = [];

    selectedConditionMonitorModels.forEach((monitor) => {
        let isMonitorPresentForLocalDelete = false;
        monitorsToDeleteLocally.forEach((localMonitor) => {
            if (localMonitor.modelName === monitor.modelName) {
                isMonitorPresentForLocalDelete = true;
            }
        });
        if (!isMonitorPresentForLocalDelete) {
            monitorsToDeleteRemotely.push(monitor);
        }
    });

    return monitorsToDeleteRemotely;
};
