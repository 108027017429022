import React, { useCallback, useEffect, useState, Fragment } from "react";
import {
    BaseLayout,
    BodyContainer,
    SidebarContainer
} from "../../containers/EditorLayout";
import "./style.scss";
import ButtonBar from "./ButtonBar";
import Flex from "../../components/shared/Flex";
import InstanceName from "./InstanceName";
import AssetInstanceSidebar from "./Sidebar/AssetInstanceSidebar";
import { Dispatch } from "redux";
import { UI_Info_Circle } from "../../utils/constants/assets";
import { connect } from "react-redux";

import {
    VIEW_MODE,
    CONFIGURATION_TOOL_MODE,
    LOADER_SIZE,
    LOADER_TYPE,
    LEAF_DRAG_DATASET,
    STRUCTURE_SELECTION,
    ACTION_TYPE,
    MAX_CHARACTERS
} from "../../utils/constants/appConstants";
import { CREATE_NEW_MODEL, TOOLTIP_MESSAGES } from "../../utils/constants/uiConstants";
import { StoreState } from "../../store";
import { showModal } from "../../store/modal/action";
import { getAssetCategoryListActionRequest, clearHierarchyDropNodeRequest, saveHierarchyDropNodeRequest, setExistingStructure, getNewSystemStructuresDropNodeRequest, addChildNodesToParentDropNodeRequest, addDefaultNewNodeTreeRequest, getSystemStructureModel, getNewSystemStructureHierarchyForTenantRequest, createNewSystemStructureHierarchyForTenantRequest, cloneNewSystemStructureHierarchyForTenantRequest, deleteNewSystemStructureHierarchyForTenantRequest, resetCommonHierarchy } from "../../store/assets/actions";

import Loader from '../../components/Loader';
import Condition from '../../components/shared/Condition';
import { AbilityService } from 'sce-engg-model-19.09';
import AppSettings from '../../services/AppSettings';
import { redirectLogin } from '../../store/login/action';
import FormGroup from 'reactstrap/lib/FormGroup';
import { Label, Table, ModalFooter } from 'reactstrap';
import { Input, Button, Dropdown, DropdownOption, ToggleButtonGroup, ToggleButton, Icon, Dialog, WithTooltip, Tooltip } from '@abb/abb-common-ux-react';
import CustomHierarchy from '../../components/CustomHierarchy/CustomHierarchy';
import { IStructure } from '../../store/assets/types';
import uuid from 'uuid';
import { checkPermissionRoles, handleTokenDecode, hasSpecialCharacters, validateAtLeastOneCharacter, validateForSwedishAndFinishCharacters, validateSpecialCharsWithoutSwedishFinnish } from '../../utils/helpers';
import { commonAuthHelper } from "../../utils/types/commonInterface";
import CustomTooltip from "../../components/CustomTooltip";

export const Asset = (
    props: ReturnType<typeof mapDispatchToProps> &
        ReturnType<typeof mapStateToProps>
) => {

    useEffect(() => {
        // const token = AppSettings.cacheLocation === "localStorage" ? localStorage.getItem("token") : sessionStorage.getItem("token");
        const token = commonAuthHelper.getToken();
        if (!!token) {
            const access = checkPermissionRoles(token);
            updateAccess(access);
            if (access) {
                handleTokenDecode();
                initializeAssetBlock();
            }
        }
    }, []);

    useEffect(() =>{
        if(props.isLoadObjectHierarchy){
            updateHierarchy(false);
            setObjectName("");
            props.getNewSystemStructureHierarchyForTenant();
            props.clearHierarchyDropNode();
            props.resetCommonHierarchy();
        }
    }, [props.isLoadObjectHierarchy])

    const initializeAssetBlock = async () => {
        // Tree Node
        console.log(props.selectedTreeNode);
        if (!props.selectedTreeNode) {
            props.getAssetsCategoryList();
        }
        props.getNewSystemStructureHierarchyForTenant();
    }

    const [objectName, setObjectName] = useState("");
    const [clonedObjectName, setClonedObjectName] = useState("");
    const [modelId, updateModelId] = useState<string>("");
    const [canAccess, updateAccess] = useState(false);

    const [errorObjectName, updateErrorObjectName] = useState(false);
    const [errorEmptyObjectName, updateErrorEmptyObjectName] = useState(false);
    const [errorSpecialCharsObjectName, updateErrorSpecialCharsObjectName] = useState(false);
    const [errorMaxLimitObjectName, updateErrorMaxLimitObjectName] = useState(false);
    const [errorCloneObjectName, updateErrorCloneObjectName] = useState(false);
    const [errorOneCharObjectName, updateErrorOneCharObjectName] = useState(false);
    const [errorAlreadyExistingObjectName, updateAlreadyExistingObjectName] = useState(false);
    const [showHierarchy, updateHierarchy] = useState<boolean>(false);
    const [isNewSelected, updateNewSelection] = useState<boolean>(false);
    const [selectedStructure, updateSelectedStructure] = useState<IStructure>();
    const [showDialog, updateShowDialog] = useState<boolean>(false);
    const [dialogText, updateDialogText] = useState<string>(ACTION_TYPE.NEW);
    const [whiteSpaceerror,updateWhiteSpaceerror]=useState(false);

    const resetModal = () => {
        updateDialogText(ACTION_TYPE.NEW);
        updateShowDialog(false);
        updateAlreadyExistingObjectName(false);
        updateErrorObjectName(false);
        updateErrorCloneObjectName(false);
        updateErrorEmptyObjectName(false);
        updateErrorMaxLimitObjectName(false);
        updateErrorSpecialCharsObjectName(false);
        updateErrorOneCharObjectName(false);
        setObjectName("");
        updateModelId("");
        setClonedObjectName("");
        updateWhiteSpaceerror(false);
    }

    const validateName = (): string => {
        if (errorObjectName) {
            if (errorCloneObjectName) return "Please provide another name";
            else if (errorEmptyObjectName) return "Name can't be empty";
            else if (errorMaxLimitObjectName) return `Max ${MAX_CHARACTERS} characters allowed`;
            else if (errorSpecialCharsObjectName) return "Special characters not allowed";
            else if (errorAlreadyExistingObjectName) return "Hierarchy is already existing. Please choose another name.";
            
            // else if (errorOneCharObjectName) return "Name should contain at least one alphabet";
        }
        return "";
    }
    return (
        <Fragment>
            <BaseLayout>
                <BodyContainer fill>
                    <Condition when={!canAccess}>
                        <Flex column className="body-container">
                            <Flex column className="asset-instance-body-container">
                                <b style={{ fontSize: `large`, margin: `8% 0 0 25%` }}>
                                    You don't have required permissions and roles.
                                    Please check with admin for access.
                                </b>
                            </Flex>
                        </Flex>
                    </Condition>
                    <Condition when={canAccess}>
                        <Flex column className="body-container">
                            <Flex column className="asset-instance-body-container">

                                {/* Tree Hierarchy */}
                                <Condition when={showHierarchy}>
                                    <CustomHierarchy objectName={objectName ? objectName.trim() : objectName} isNewSelected={isNewSelected} selectedStructure={selectedStructure!}
                                        onSave={(value: any) => {
                                        }
                                        }
                                        onCancel={(value: boolean) => {
                                            updateHierarchy(value);
                                            setObjectName("");
                                            props.getNewSystemStructureHierarchyForTenant();
                                            props.clearHierarchyDropNode();
                                            props.resetCommonHierarchy();
                                            // props.setExistingStructure(selectedStructure!);
                                        }
                                        } />
                                </Condition>

                                {/* Landing Page */}
                                <Condition when={!showHierarchy}>
                                    <Label style={{ borderBottom: "1px solid" }}><b>OBJECT HIERARCHIES</b></Label>
                                    <div style={{ marginTop: "15px" }}>
                                        <Button
                                            text="Create New Hierarchy"
                                            sizeClass="small"
                                            shape="rounded"
                                            type="primary-blue"
                                            className="customDeleteButton"
                                            icon="abb/plus"
                                            onClick={() => {
                                                // props.setExistingStructure(selectedStructure!);
                                                // props.getNewSystemStructuresDropNodeRequest();
                                                // updateHierarchy(true);
                                                updateDialogText(ACTION_TYPE.NEW);
                                                updateShowDialog(true);
                                            }}
                                        />

                                    </div>
                                    <div className="object-table">

                                        <Table className="supportedModelTable" style={{ width: "100%" }}>
                                            <thead>
                                                <tr>
                                                    <th
                                                        className={`text-uppercase width-40 `}>
                                                        OBJECT HIERARCHY
                                                    </th>
                                                    {/* <th className="width-5">MODIFIED</th>
                                                <th className="width-5">BY</th> */}
                                                    <th className="width-5">ACTIONS</th>
                                                </tr>
                                            </thead>
                                            <Condition when={props.isHierarchyLoading}>
                                                <Loader
                                                    sizeClass={LOADER_SIZE.LARGE}
                                                    type={LOADER_TYPE.RADIAL}
                                                />
                                            </Condition>
                                            <Condition when={!props.isHierarchyLoading && props.structureListForTenant.length == 0}>
                                                <tbody>
                                                    <tr>
                                                        <td className="width-15" style={{ textAlign: "center" }}>
                                                            No Hierarchies
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Condition>
                                            <Condition when={!props.isHierarchyLoading && props.structureListForTenant.length > 0}>
                                                <tbody>
                                                    {props.structureListForTenant.map((item: IStructure, itemIndex: number) => {
                                                        return (
                                                            <tr key={itemIndex}>
                                                                <td className="width-15" onClick={() => {
                                                                    const selectedObject = props.structureListForTenant.find(x => x.model === item.model);
                                                                    if (selectedObject) {
                                                                        updateSelectedStructure(selectedObject);
                                                                    }
                                                                    props.setExistingStructure(selectedObject!);
                                                                    props.getNewSystemStructuresDropNodeRequest();
                                                                    updateHierarchy(true);
                                                                }}>
                                                                    {item.name}
                                                                    <Icon name="abb/caret-right" sizeClass="medium" />
                                                                </td>
                                                                <td className="width-15">
                                                                    <div className="actionIcons">
                                                                        <Condition when={item.tenantId}>
                                                                            <CustomTooltip position="bottom" text={TOOLTIP_MESSAGES.EDIT}>
                                                                                <Icon name="abb/edit" sizeClass="small"
                                                                                    onClick={() => {
                                                                                        updateDialogText(ACTION_TYPE.EDIT);
                                                                                        updateShowDialog(true);
                                                                                        setObjectName(`${item.name}`)
                                                                                        updateModelId(item.model);
                                                                                    }}
                                                                                />
                                                                            </CustomTooltip> 
                                                                        </Condition>
                                                                        <CustomTooltip position="bottom" text={TOOLTIP_MESSAGES.COPY}>
                                                                            <Icon name="abb/copy" sizeClass="small"
                                                                                onClick={() => {
                                                                                    updateDialogText(ACTION_TYPE.COPY);
                                                                                    updateShowDialog(true);
                                                                                    setObjectName(`${item.name} Copy`);
                                                                                    setClonedObjectName(item.name);
                                                                                    updateModelId(item.model);
                                                                                }}
                                                                            />
                                                                        </CustomTooltip>
                                                                        <Condition when={item.tenantId}>
                                                                            <CustomTooltip position="bottom" text={TOOLTIP_MESSAGES.DELETE}>
                                                                                <Icon name="abb/trash" sizeClass="small"
                                                                                    onClick={() => {
                                                                                        updateDialogText(ACTION_TYPE.DELETE);
                                                                                        updateShowDialog(true);
                                                                                        setObjectName(`${item.name}`)
                                                                                        updateModelId(item.model);
                                                                                    }} />
                                                                            </CustomTooltip>
                                                                        </Condition>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </Condition>
                                        </Table>
                                    </div>
                                </Condition>

                                {/* New Hierarchy Create Modal */}
                                <Dialog
                                    containerClassName="logic-add-dialog new-layout"
                                    dimBackground={true}
                                    closeOnLostFocus={false}
                                    title={dialogText === ACTION_TYPE.NEW ? "Create New Object Hierarchy" : (dialogText === ACTION_TYPE.COPY ? "Clone Object Hierarchy" : (dialogText === ACTION_TYPE.EDIT ? "Rename Hierarchy" : "Remove Hierarchy ?"))}
                                    isOpen={showDialog}
                                    showCloseButton={true}
                                    onClose={() => resetModal()}
                                >
                                    <div className="parent-asset-config-form">
                                        <Condition when={dialogText !== ACTION_TYPE.DELETE}>
                                            <div className="asset-config-form">
                                                <FormGroup className="custom-form-group">
                                                    <Label for="objectId">
                                                        Hierarchy Name
                                                    </Label>
                                                    <Input
                                                        value={objectName}
                                                        onValueChange={(value) => {
                                                            setObjectName(value);
                                                            updateErrorObjectName(false);
                                                            updateErrorCloneObjectName(false);
                                                            updateErrorEmptyObjectName(false);
                                                            updateErrorMaxLimitObjectName(false);
                                                            updateErrorSpecialCharsObjectName(false);
                                                            updateErrorOneCharObjectName(false);
                                                        }}
                                                        type="normal"
                                                        dataType="text"
                                                        id="objectName"
                                                        placeholder="Enter Name"
                                                        showValidationBarWhenInvalid={true}
                                                        showValidationIconWhenInvalid={true}
                                                        instantValidation={true}
                                                        validator={() =>
                                                            errorObjectName
                                                                ? {
                                                                    valid: false,
                                                                    text: validateName()
                                                                }
                                                                : { valid: true, text: "OK!" }
                                                        }
                                                    />
                                                </FormGroup>
                                            </div>
                                        </Condition>
                                        <Condition when={dialogText === ACTION_TYPE.DELETE}>
                                            <FormGroup className="custom-form-group">
                                                <Label>
                                                    {/* This hierarchy might be configured in  <b>Streaming Calculation Engine</b>.
                                                    After deletion, monitoring will not be possible. */}
                                                    Are you sure want to Remove <b>{objectName}</b>  ?
                                                </Label>
                                            </FormGroup>
                                        </Condition>
                                    </div>
                                    <ModalFooter>
                                        <div>
                                            <Button
                                                text="Cancel"
                                                style={{ marginRight: `12px` }}
                                                sizeClass="medium"
                                                shape="rounded"
                                                type="normal"
                                                className="customDeleteButton"
                                                onClick={() => resetModal()} />
                                            <Button
                                                text={dialogText === ACTION_TYPE.NEW ? "Create" : (dialogText === ACTION_TYPE.COPY ? "Clone" : (dialogText === ACTION_TYPE.EDIT ? "Save" : "Remove"))}
                                                sizeClass="medium"
                                                shape="rounded"
                                                type="primary-blue"
                                                className="customDeleteButton"
                                                onClick={() => {
                                                    if (objectName.trim() === "") {
                                                        updateErrorObjectName(true);
                                                        updateErrorCloneObjectName(false);
                                                        updateErrorEmptyObjectName(true);
                                                        updateErrorMaxLimitObjectName(false);
                                                        updateErrorSpecialCharsObjectName(false);
                                                        updateErrorOneCharObjectName(false);
                                                        return;

                                                    }
                                                    // if(objectName===""&& !objectName.trim()){
                                                    //     updateErrorObjectName(true);
                                                    //     updateErrorCloneObjectName(false);
                                                    //     updateErrorEmptyObjectName(false);
                                                    //     updateErrorMaxLimitObjectName(false);
                                                    //     updateErrorSpecialCharsObjectName(false);
                                                    //     updateErrorOneCharObjectName(false);
                                                    //     updateWhiteSpaceerror(true);

                                                    //     return;

                                                    // }
                                                    if (objectName && objectName.trim().length > MAX_CHARACTERS) {
                                                        updateErrorObjectName(true);
                                                        updateErrorCloneObjectName(false);
                                                        updateErrorEmptyObjectName(false);
                                                        updateErrorMaxLimitObjectName(true);
                                                        updateErrorSpecialCharsObjectName(false);
                                                        updateErrorOneCharObjectName(false);
                                                        return;
                                                    }
                                                    if (dialogText !== ACTION_TYPE.DELETE) {
                                                        const res = validateSpecialCharsWithoutSwedishFinnish(objectName.trim(), [".", "_", "-"]);
                                                        if (!res) {
                                                            updateErrorObjectName(true);
                                                            updateErrorCloneObjectName(false);
                                                            updateErrorEmptyObjectName(false);
                                                            updateErrorMaxLimitObjectName(false);
                                                            updateErrorSpecialCharsObjectName(true);
                                                            updateErrorOneCharObjectName(false);
                                                            return;
                                                        }
                                                    }
                                                    
                                                    if (dialogText === ACTION_TYPE.COPY && objectName.trim() === clonedObjectName.trim()) {
                                                        updateErrorObjectName(true);
                                                        updateErrorCloneObjectName(true);
                                                        updateErrorEmptyObjectName(false);
                                                        updateErrorMaxLimitObjectName(false);
                                                        updateErrorSpecialCharsObjectName(false);
                                                        updateErrorOneCharObjectName(false);
                                                        return;
                                                    }
                                                    
                                                    let isExist = props.structureListForTenant.filter(item => item.name === objectName.trim());
                                                    if(isExist.length > 0 && dialogText !== ACTION_TYPE.DELETE) {
                                                            updateErrorObjectName(true);
                                                            updateAlreadyExistingObjectName(true);
                                                            updateErrorCloneObjectName(false);
                                                            updateErrorEmptyObjectName(false);
                                                            updateErrorMaxLimitObjectName(false);
                                                            updateErrorSpecialCharsObjectName(false);
                                                            updateErrorOneCharObjectName(false);
                                                            return;
                                                    }

                                                    if (dialogText === ACTION_TYPE.NEW || dialogText === ACTION_TYPE.EDIT) {
                                                        props.createNewSystemStructureHierarchyForTenant(objectName.trim(), modelId);
                                                    } else if (dialogText === ACTION_TYPE.COPY) {
                                                        props.cloneNewSystemStructureHierarchyForTenant(objectName.trim(), "", modelId);
                                                    } else if (dialogText === ACTION_TYPE.DELETE) {
                                                        props.deleteNewSystemStructureHierarchyForTenant(modelId);
                                                    }
                                                    resetModal();
                                                }} />
                                        </div>
                                    </ModalFooter>
                                </Dialog>
                            </Flex>
                        </Flex>
                        <Condition when={showHierarchy}>
                            <SidebarContainer isCollapse={props.assetConfigurationMode}
                                useDraggable={true}>
                                <AssetInstanceSidebar />
                            </SidebarContainer>
                        </Condition>
                    </Condition>
                </BodyContainer>

                {/* Sidebar */}

            </BaseLayout>
        </Fragment>
    );
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        setExistingStructure: (structure: IStructure) => {
            dispatch(setExistingStructure(structure));
        },
        getNewSystemStructuresDropNodeRequest: () => {
            dispatch(getNewSystemStructuresDropNodeRequest(undefined));
        },
        clearHierarchyDropNode: () => {
            dispatch(clearHierarchyDropNodeRequest());
        },
        saveHierarchyDropNode: (data: any) => {
            dispatch(saveHierarchyDropNodeRequest(data));
        },
        getAssetsCategoryList: () => {
            dispatch(getAssetCategoryListActionRequest());
        },
        redirectLogin: () => {
            dispatch(redirectLogin());
        },
        addDefaultNewNodeTree: (childNodes: any) => {
            dispatch(
                addDefaultNewNodeTreeRequest(childNodes)
            )
        },
        getNewSystemStructureHierarchyForTenant: () => {
            dispatch(getNewSystemStructureHierarchyForTenantRequest());
        },
        createNewSystemStructureHierarchyForTenant: (name: string, modelId: string) => {
            dispatch(createNewSystemStructureHierarchyForTenantRequest(name, modelId));
        },
        cloneNewSystemStructureHierarchyForTenant: (cloneStructureName: string, clonedStructureModelId: string, originSouceId: string) => {
            dispatch(cloneNewSystemStructureHierarchyForTenantRequest(cloneStructureName, clonedStructureModelId, originSouceId));
        },
        deleteNewSystemStructureHierarchyForTenant: (modelId: string) => {
            dispatch(deleteNewSystemStructureHierarchyForTenantRequest(modelId));
        },
        resetCommonHierarchy: () => {
            dispatch(resetCommonHierarchy());
        },
    };
};

const mapStateToProps = (state: StoreState) => {
    return {
        assetConfigurationMode: state.assets.configurationMode,
        selectedTreeNode: state.assets.selectedTreeNode,
        structureListForTenant: state.assets.assetStructureDetails.structureListForTenant,
        selectedStructure: state.assets.existingStructure,
        treeNewVirtualList: state.assets.treeNewVirtualList,
        isLoading: state.assets.isLoading,
        isHierarchyLoading: state.assets.isHierarchyLoading,
        isLoadObjectHierarchy: state.assets.isLoadObjectHierarchy
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Asset);


