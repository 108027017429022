// import React from "react";
// import Condition from "../../../shared/Condition";
// import Flex from "../../../shared/Flex";
// import { IconProps, ActionIconProps } from "./types";
// import "./styles.scss";

// const Icon = (props: IconProps) => {
//   return (
//     <div className="icon-container">
//       <img
//         className="img-fluid icon-styling"
//         src={props.icon}
//         alt={props.text}
//       />
//     </div>
//   );
// };

// const ActionIcon = (props: ActionIconProps) => {
//   return (
//     <Flex className="action-icon-container justify-content-end">
//       <Condition when={props.actionArr && props.actionArr.length}>
//         {props.actionArr.map((actionObj, idx) => {
//           return <Icon {...actionObj} key={idx} />;
//         })}
//       </Condition>
//     </Flex>
//   );
// };

// ActionIcon.defaultProps = {
//   actionArr: []
// };

// export default ActionIcon;

import React, { useState } from "react";
import Condition from "../../../shared/Condition";
import Flex from "../../../shared/Flex";
import { IconProps, ActionIconProps } from "./types";
import "./styles.scss";

import {
    Popup,
    AppTopNaviDivider,
    AppTopNaviItem,
    AppTopNavi,
    WithPopup,
    Link,
    Icon as AbbIcon,
    WithTooltip,
    Tooltip,
} from "@abb/abb-common-ux-react";
import { AbilityService } from "sce-engg-model-19.09";
import { AuthScheme, IS_LOGIN } from "../../../../utils/constants/appConstants";
import AppSettings from "../../../../services/AppSettings";
import { Label } from "reactstrap";
import ReleaseLicense from "../../../ReleaseLicense/ReleaseLicense";
import { UserMenu, UserMenuItem } from "@abb/abb.ia.components.react";
import "@abb/abb.ia.components.react/index.css";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { showDialog } from "../../../../store/dialog/action";
import Licensing from '../../../Licensing';
// import SupportedModel from "../../../SupportedModel/SupportedModel";
import { commonAuthHelper } from "../../../../utils/types/commonInterface";
import { TOOLTIP_MESSAGES } from "../../../../utils/constants/uiConstants";
import { StoreState } from '../../../../store';
import CustomTooltip from "../../../CustomTooltip";

const Icon = (props: IconProps) => {
    return (
        <div className="icon-container">
            <img
                className="img-fluid icon-styling"
                src={props.icon}
                alt={props.text}
            />
        </div>
    );
};

const logout = () => { return commonAuthHelper.logout(); };

const mapStateToProps = (state: StoreState) => {
    return {
        isAuthorized: state.modelsPage.isAuthorized,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        showLicensingNotes: () => {
            dispatch(
                showDialog({
                    component: Licensing,
                    modalTitle: "Licensing",
                    customClassName: "release-license-dialog license-dialog",
                    removeBackground: true,
                })
            );
        },
        showReleaseNotes: () => {
            dispatch(
                showDialog({
                    component: ReleaseLicense,
                    modalTitle: "Release Notes",
                    customClassName: "release-license-dialog",
                    removeBackground: true,
                })
            );
        },
    };
};
const UserActions = connect(
    undefined,
    mapDispatchToProps
)((props: ReturnType<typeof mapDispatchToProps>) => {
    const [isOpen, setIsOpen] = useState(false);
    const tenantName =
        AppSettings.cacheLocation === "localStorage"
            ? localStorage.getItem("tenant")
            : sessionStorage.getItem("tenant");

    const userDetails =
        AppSettings.cacheLocation === "localStorage"
            ? localStorage.getItem("loggedInUser")
            : sessionStorage.getItem("loggedInUser");
    const userInfo = userDetails ? JSON.parse(userDetails) : null;

    return (
        <AppTopNavi>
            {/* <WithTooltip>
                <SupportedModel />
                <Tooltip>{TOOLTIP_MESSAGES.SETTINGS}</Tooltip>
            </WithTooltip> */}
            {/* <CustomVariableDelete />
            <RestoreEdge /> */}
            <CustomTooltip position="bottom" text={TOOLTIP_MESSAGES.USER_MANUAL}>
                <AppTopNaviItem
                    icon="abb/help-circle-1"
                    onClick={() => {
                        let prefixStartsSlash;
                        let prefixEndsSlash;
                        if (AppSettings.prefix !== "") {
                            prefixStartsSlash = AppSettings.prefix.startsWith("/");
                            prefixEndsSlash = AppSettings.prefix.endsWith("/");
                        }
                        const link = AppSettings.prefix == '' ? '/help/index.html'
                            : prefixStartsSlash && prefixEndsSlash ?
                                `${AppSettings.prefix}help/index.html`
                                : prefixStartsSlash && !prefixEndsSlash ?
                                    `${AppSettings.prefix}/help/index.html`
                                    : !prefixStartsSlash && prefixEndsSlash
                                        ? `/${AppSettings.prefix}help/index.html`
                                        : !prefixStartsSlash && !prefixEndsSlash
                                            ? `/${AppSettings.prefix}/help/index.html`
                                            : `${AppSettings.prefix}/help/index.html`;
                        window.open(link, "_blank");
                    }}
                />
            </CustomTooltip>
            <AppTopNaviDivider />
            <CustomTooltip position="bottom" text={TOOLTIP_MESSAGES.PROFILE}>
                <UserMenu
                    tenant={tenantName || ""}
                    username={AbilityService.user.name || ''}
                    roles={AbilityService.userRoles || []}
                    instance={""}
                    isOpen={isOpen}
                    trigger={
                        <AppTopNaviItem
                            icon="abb/user-in-circle"
                            onClick={() => {
                                setIsOpen(!isOpen);
                            }}
                        />
                    }
                    showSettings={false}
                    showSwitchTenant={false}
                    onLicensing={() => {
                        console.log("license notes");
                        props.showLicensingNotes();
                    }}
                    onReleaseNotes={() => {
                        console.log(" release notes");
                        props.showReleaseNotes();
                    }}
                    onPrivacyPolicy={() => {
                        const link = `https://new.abb.com/privacy-notice/customer`;
                        window.open(link, "_blank");
                    }}
                    onSignOut={logout}
                    onRequestClose={() => setIsOpen(false)}
                />
            </CustomTooltip>
        </AppTopNavi>
    );
});

const ActionIcon = (props: ActionIconProps) => {
    return (
        <Flex className="action-icon-container">
            <Condition when={props.actionArr && props.actionArr.length}>
                <UserActions />
            </Condition>
        </Flex>
    );
};

ActionIcon.defaultProps = {
    actionArr: [],
};

export default ActionIcon;
