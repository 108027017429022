import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import "./style.scss";
import { StoreState } from "../../store";
import { hideNotificationModal } from "../../store/notificationModal/action";
// import { confirmationButton } from "../../utils/constants/appConstants";
import {
    NotificationContainer,
    Notification,
    NotificationData,
    NotificationContainerProps,
    NotificationState,
    Button
} from "@abb/abb-common-ux-react";
import { NOTIFICATION_MODAL_STATUS } from "../../utils/constants/appConstants";
import { IC_FAILURE_ICON } from "../../utils/constants/assets";

interface NotificationModalProps { }

export const NotificationModal = (
    props: NotificationModalProps &
        ReturnType<typeof mapDispatchToProps> &
        ReturnType<typeof mapStateToProps>
) => {
    const [showDetails, setShowDetails] = useState(false);

    const handleCloseModal = (event: React.SyntheticEvent) => {
        setShowDetails(false);
        // let confirmationModal = document.getElementById("confirmation-modal");

        // if (confirmationModal) {
        //     console.log("height :", confirmationModal.style);
        //     confirmationModal.style.maxHeight = "0px";
        //     confirmationModal.style.overflow = "hidden";
        //     confirmationModal.style.padding = "0px";
        //     // confirmationModal.style.border = "0px";
        //     console.log("height :", confirmationModal.style);
        // }
        props.hideNotificationModal();
        console.log("hideNotification Modal called");
    };

    const toggleShowDetails = () => {
        if (showDetails === false) {
            setShowDetails(true);
        } else {
            setShowDetails(false);
        }
    };
    const NotificationState: NotificationState = {
        isClosing: false
    };

    const Data: NotificationData = {
        id: "1",
        type: "banner",
        discreet: false,
        severity:
            props.notificationModalState.resultStatus ===
                NOTIFICATION_MODAL_STATUS.WARNING
                ? "warn"
                : "success",
        timeout: 5000,
        text: props.notificationModalState.title,
        isClosing: NotificationState.isClosing,

    };
    return props.notificationModalState.show ? (
        props.notificationModalState.type === "confirmation" ? (
            <div
                id="confirmation-modal"
                className={`wrapper-notification-text  wrapper-notification-text--${props.notificationModalState.resultStatus}`}
            >
                <div className="wrapper-notification-data">
                    {props.notificationModalState.resultStatus ===
                        NOTIFICATION_MODAL_STATUS.WARNING ? (
                            <div className="notification-icon">!</div>
                        ) : (
                            <img
                                className="failure-icon"
                                src={IC_FAILURE_ICON}
                                alt="Error Icon"
                            />
                        )}

                    <div className="notification-modal-text">
                        {props.notificationModalState.title}
                    </div>
                </div>
                <div className="yes-no-btn">
                    <Fragment>
                        <div className="confirmation-btn">
                            {props.notificationModalState.details.length >
                                0 && (
                                    <div onClick={toggleShowDetails}>
                                        {" "}
                                        <span className="details">
                                            {!showDetails
                                                ? "View More Details"
                                                : "Hide More Details"}
                                        </span>
                                    </div>
                                )}
                            <div className="ok-button">
                                <Button
                                    type="primary-blue"
                                    text="OK"
                                    sizeClass="small"
                                    onClick={handleCloseModal}
                                />
                            </div>
                        </div>
                        {showDetails ? (
                            <div className="list-details">
                                {props.notificationModalState.details.map(
                                    (data, index) => {
                                        return <div key={index}>-{data}</div>;
                                    }
                                )}
                            </div>
                        ) : (
                                <></>
                            )}
                    </Fragment>
                </div>
            </div>
        ) : (
                <div className="wrapper-notification">
                    <Notification
                        data={Data}
                        //@ts-ignore
                        // onClose={{
                        //     //@ts-ignore
                        //     remove: (remove: id) => {
                        //         console.log("hideNotificationModal banner");
                        //         props.hideNotificationModal();
                        //     }
                        // }}
                        removalHandler={{

                            remove: (id) => {
                                console.log("hideNotificationModal banner");
                                props.hideNotificationModal();
                            }
                        }}
                        className=" wrapper-notification"
                    // style={{
                    //     // marginRight: "0px",
                    //     marginTop: "79px",
                    //     height: "48px",
                    //     width: "583px"
                    // }}
                    />
                </div>
            )
    ) : (
            <Fragment></Fragment>
        );
};

const mapStateToProps = (state: StoreState) => {
    return {
        notificationModalState: state.notificationModal
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        hideNotificationModal: () => dispatch(hideNotificationModal())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationModal);
