import React, { useEffect, useState, useCallback } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import Flex from "../../../../components/shared/Flex";
import CategoryDropdown from "./CategoryDropdown";
import "./styles.scss";
import {
    getAssetCategoryListActionRequest,
    handleCategorySelectionAction,
    setSelectedTreeNodeAction,
    handleTreeListNodeToggleAction,
    updateGroupBy,
    getSystemStructureModel,
    getConnectDetailsFromIdentityModel,
    getNewSystemStructuresActionRequest,
    handleNewTreeListNodeToggleAction,
    getSystemsBasedOnConnectModelRequest,
    updateStructure,
    updateSystem,
    getNewAssetInstanceListActionRequest,
    searchAsset,
    setSelectedParentTreeNodeAction,
    selectedNodeParentListAction,
} from "../../../../store/assets/actions";
import { StoreState } from "../../../../store";
import {
    CategoryObjTypes,
    TreeNodeType,
    IStructure,
    ISystem,
} from "../../../../store/assets/types";
import TreeList from "./TreeList/index";
import SearchBar from "../../../../components/SearchBar";
import { Footer } from "../../../../components/Footer";

import { CommonHierarchyTree } from "common-hierarchy-tree";
//import "common-hierarchy-tree/custom.css";
import { GROUP_BY_LIST } from "../../../../utils/constants/appConstants";
import { filterListData } from "../../../../utils/helpers";

export const Sidebar = (
    props: ReturnType<typeof mapDispatchToProps> &
        ReturnType<typeof mapStateToProps>
) => {
    const [isLoading, setLoading] = useState<boolean>(false);
    const [treeVirtualList, updateTreeVirtualList] = useState<any[]>([]);
    const [nodeId, updateNodeId] = useState<any>(null);

    useEffect(() => {
        checkForSearchText();
    }, [props.treeVirtualList, props.searchAssetText]);

    const checkForSearchText = () => {
        if (props.searchAssetText === "") {
            updateTreeVirtualList(props.treeVirtualList!);
        } else {
            const filteredList = filterListData(
                props.treeVirtualList!,
                "name",
                props.searchAssetText!
            );
            updateTreeVirtualList(filteredList);
        }
        setLoading(false);
    };

    return (
        <CommonHierarchyTree
            showFilter={true}
            showSearchBox={true}
            isLoading={isLoading}
            structureList={props.structureList}
            systemList={props.systemList}
            groupByList={props.groupByList}
            selectedGroupBy={props.selectedGroupBy}
            selectedStructure={props.selectedStructure}
            selectedSystem={props.selectedSystem}
            fullNodeDrag={true}
            onStructureChange={(structure) => {
                console.log("structure :", structure);
                setLoading(true);
                props.updateStructure(structure);
                props.setSelectedTreeNodeAction(null);
                props.getNewSystemStructures();
                props.setSelectedParentTreeNode(null);
                props.getNewAssetInstanceList(null, "", false);
            }}
            onSystemChange={(system) => {
                setLoading(true);
                props.updateSystem(system);
                props.getNewSystems();
                props.setSelectedParentTreeNode(null);
            }}
            onGroupByChange={(groupBy) => {
                setLoading(true);
                props.updateGroupBy(groupBy);
                groupBy === "Structure"
                    ? props.getSystemStructureModel()
                    : props.getConnectDetailsFromIdentityModel();
                props.setSelectedParentTreeNode(null);
            }}
            searchText={props.searchAssetText}
            onSearchTextChange={(text) => {
                setLoading(true);
                // in future need to do actual API call
                props.searchAssetValue(text!);
            }}
            leafNodeDrag={false}
            treeList={[...treeVirtualList!]}
            onToggleTreeListNode={(node) => {
                let tempNode: any = { ...node };
                if (props.selectedGroupBy == "Structure") {
                    if (
                        Array.isArray(tempNode.parents) &&
                        tempNode.parents.length == 0
                    ) {
                        if (nodeId !== tempNode.id) {
                            updateNodeId(tempNode.id);
                            props.setSelectedParentTreeNode(tempNode.id);
                        }
                    } else if (props.selectedParentTreeNodeId == null) {
                        const tempId =
                            Array.isArray(tempNode.parents) &&
                                tempNode.parents.length == 0
                                ? tempNode.id
                                : tempNode.parents[0];
                        updateNodeId(tempId);
                        props.setSelectedParentTreeNode(tempId);
                    }
                } else if (props.selectedGroupBy == "Connect") {
                    updateNodeId(tempNode.id);
                    props.setSelectedParentTreeNode(tempNode.id);
                }
                props.selectedNodeParentList(
                    Array.isArray(tempNode.parents) ? tempNode.parents : []
                );
                props.handleTreeNodeSelection(node.state!);
                props.getNewAssetInstanceList(node.state!, "EXISTING", false);
            }}
        />
    );
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        updateGroupBy: (groupBy: string) => {
            dispatch(updateGroupBy(groupBy));
        },
        updateStructure: (structure: IStructure) => {
            dispatch(updateStructure(structure));
        },
        updateSystem: (system: ISystem) => {
            dispatch(updateSystem(system));
        },
        getSystemStructureModel: () => {
            dispatch(getSystemStructureModel());
        },
        getConnectDetailsFromIdentityModel: () => {
            dispatch(getConnectDetailsFromIdentityModel());
        },
        getNewSystemStructures: () => {
            dispatch(getNewSystemStructuresActionRequest(null));
        },
        getNewSystems: () => {
            dispatch(getSystemsBasedOnConnectModelRequest(null));
        },
        handleTreeNodeSelection: (node: any) => {
            dispatch(handleNewTreeListNodeToggleAction(node));
        },
        searchAssetValue: (text: string) => {
            dispatch(searchAsset(text));
        },
        setSelectedParentTreeNode: (nodeId: any) => {
            dispatch(setSelectedParentTreeNodeAction(nodeId));
        },
        selectedNodeParentList: (nodes: string[]) => {
            dispatch(selectedNodeParentListAction(nodes));
        },
        setSelectedTreeNodeAction: (node: TreeNodeType | null) => {
            dispatch(setSelectedTreeNodeAction(node));
        },
        getNewAssetInstanceList: (
            selectedNode: any,
            selectedType: string,
            includeAllChild: boolean
        ) => {
            dispatch(
                getNewAssetInstanceListActionRequest({
                    node: selectedNode,
                    type: selectedType,
                    activePage: 1,
                })
            );
        },
    };
};
const mapStateToProps = (state: StoreState) => {
    return {
        sysEdgeLoading: state.assets.sysEdgeLoading,
        categoryArr: state.assets.categoryArr,
        selectedCategory: state.assets.selectedCategory,
        treeListData: state.assets.treeList,
        structureList: state.assets.assetStructureDetails.structureList,
        systemList: state.assets.assetStructureDetails.systemList,
        treeVirtualList: state.assets.treeVirtualList.children,
        searchAssetText: state.assets.searchAsset,
        selectedParentTreeNodeId: state.assets.selectedParentTreeNodeId,
        groupByList: state.assets.groupByList,
        selectedGroupBy: state.assets.assetStructureDetails.selectedGroupBy,
        selectedStructure: state.assets.assetStructureDetails.selectedStructure,
        selectedSystem: state.assets.assetStructureDetails.selectedSystem
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
