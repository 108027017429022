import React from "react";
import { connect } from "react-redux";

import "./style.scss";
import { StoreState } from "../../store";

interface MessageModalProps {
    warningText: string;
}

export const NewMessageModal = (
    props: MessageModalProps &
        ReturnType<typeof mapDispatchToProps> &
        ReturnType<typeof mapStateToProps>
) => {
    return (
        <div className="wrapper-warning-msg">
            <div className="wrapper-warning-data">
                <div className="message-modal-text">{props.warningText}</div>
            </div>
        </div>
    );
};

const mapStateToProps = (state: StoreState) => {
    return {};
};
const mapDispatchToProps = (dispatch: any) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(NewMessageModal);
