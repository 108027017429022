import React, { useState, useCallback, useReducer } from "react";
import useReactRouter from "use-react-router";
import { withRouter, RouteComponentProps } from "react-router";
import { Navbar } from "reactstrap";
import * as _ from "lodash";
import NavigationLink from "./NavigationLink";
import Flex from "../../shared/Flex";
import SearchBar from "../../SearchBar";
import ActionIcon from "./ActionIcon";
import {
    ASSET_ICON,
    MODEL_ICON,
    FUNCTION_ICON,
    ALARM_BELL,
    SETTING,
    USER_IN_CIRCLE,
} from "../../../utils/constants/assets";
import "./styles.scss";
import { AppTopNavi, AppTopNaviItem, AppHeader } from "@abb/abb-common-ux-react";
import {
    ROUTE_PATHNAME,
    CONFIGURATION_TOOL_MODE,
} from "../../../utils/constants/appConstants";
import { StoreState } from "../../../store";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { showMessageModal } from "../../../store/messageModal/action";
import MessageModal from "../../MessageModal";
import NewMessageModal from "../../MessageModal/NewMessageModal";
import {
    UNSAVED_CHANGES_CONFIRMATION_MESSAGE,
    CONFIRMATION_BUTTON,
} from "../../../utils/constants/uiConstants";
// import { updateConfigurationToolMode } from "../../../store/configurationTool/actions";
// import { ConfigurationToolState } from "../../../store/configurationTool/types";
import { hideDialog, showDialog } from '../../../store/dialog/action';
import { loadObjectHierarchy } from "../../../store/assets/actions";

const RIGHT_SIDE_ACTION_ARR = [
    {
        icon: ALARM_BELL,
        text: "alarm",
        onClick: _.identity,
    },
    {
        icon: USER_IN_CIRCLE,
        text: "user",
        onClick: _.identity,
    },
    {
        icon: SETTING,
        text: "setting",
        onClick: _.identity,
    },
];

enum NavBarLinks {
    assets = "Objects",
    models = "Models",
    functions = "Functions",
    settings = 'Settings',
}

const NavBarTabsMapStateToProps = (state: StoreState) => {
    return {
        // mode: state.configurationTool.mode,
    };
};
const NavBarTabsMapDispatchToProps = (dispatch: Dispatch, ownProps: any) => {
    return {
        handleRouteChange: (url: string) => {
            dispatch(
                showMessageModal({
                    component: MessageModal,
                    data: {
                        primaryButtonAction: (event) => {
                            const eventTarget = event.currentTarget as HTMLButtonElement;
                            const value = eventTarget.id;
                            console.log(value);
                            if (value === CONFIRMATION_BUTTON.ACCEPT) {
                                ownProps.history.push(url);
                            }
                        },
                        componentTitle: CONFIRMATION_BUTTON.DISCARD_CHANGES,
                        acceptButtonTitle: CONFIRMATION_BUTTON.DISCARD_CHANGES,
                        rejectButtonTitle: CONFIRMATION_BUTTON.CANCEL,
                        warningText: UNSAVED_CHANGES_CONFIRMATION_MESSAGE,
                    },
                })
            );
        },

        handleRouteChanges: (url: string) => {
            dispatch(
                showDialog({
                    component: NewMessageModal,
                    modalTitle: CONFIRMATION_BUTTON.DISCARD_CHANGES,
                    customClassName: 'wrapper-message-modal',
                    data: {
                        warningText: UNSAVED_CHANGES_CONFIRMATION_MESSAGE,
                        standardButtonsOnBottom: [
                            {
                                text: CONFIRMATION_BUTTON.CANCEL,
                                type: 'discreet-black',
                                handler: (dlg: any) => {
                                    dispatch(hideDialog());
                                },
                            },
                            {
                                text: CONFIRMATION_BUTTON.DISCARD_CHANGES,
                                type: 'primary-blue',
                                handler: (dlg: any) => {
                                    ownProps.history.push(url);
                                    dispatch(hideDialog());
                                },
                            },
                        ],
                    },
                })
            );
            },
        loadObjectHierarchy: (value: boolean) => {
            dispatch(loadObjectHierarchy(value));
        },
        // updateConfigurationToolMode: (payload: {
        //     mode: ConfigurationToolState["mode"];
        // }) => {
        //     dispatch(updateConfigurationToolMode(payload));
        // },
    };
};
export const NavBarTabs = withRouter(
    connect(
        NavBarTabsMapStateToProps,
        NavBarTabsMapDispatchToProps
    )(SimpleNavBarTabs)
);

export function SimpleNavBarTabs(
    props: ReturnType<typeof NavBarTabsMapStateToProps> &
        ReturnType<typeof NavBarTabsMapDispatchToProps> &
        RouteComponentProps
) {
    const {history, location } = useReactRouter();
    // console.log("history :", history);
    // const { mode } = props;

    const loadAssets = () => {
        if (
            location.pathname === ROUTE_PATHNAME.HOME
        ) {
            props.handleRouteChange(ROUTE_PATHNAME.OBJECTS);
        } else {
            history.push(ROUTE_PATHNAME.OBJECTS);
            props.loadObjectHierarchy(true);
        }
    };

    const loadSettings = () => {
        debugger
        if (
            location.pathname === ROUTE_PATHNAME.HOME 
            // props.mode === CONFIGURATION_TOOL_MODE.EDIT
        ) {
            props.handleRouteChanges(ROUTE_PATHNAME.FUNCTIONS);
        } else {
            history.push(ROUTE_PATHNAME.SETTINGS);
        }
    };

    const Links: any = [
        {
            name: NavBarLinks.assets,
            icon: "abb/list",
            text: "Object Hierarchies",
            url: ROUTE_PATHNAME.OBJECTS,
            isActive: false,
            onClick: loadAssets,
        },
        // {
        //     name: NavBarLinks.models,
        //     icon: "abb/document",
        //     text: "Types",
        //     url: ROUTE_PATHNAME.HOME,
        //     isActive: false,
        //     onClick: loadModels,
        // },
        // {
        //     name: NavBarLinks.functions,
        //     icon: "abb/network",
        //     text: "Functions",
        //     url: ROUTE_PATHNAME.FUNCTIONS,
        //     isActive: false,
        //     onClick: loadFunctions,
        // },
        {
            name: NavBarLinks.settings,
            icon: 'abb/advanced-settings',
            text: 'Configurations',
            url: ROUTE_PATHNAME.SETTINGS,
            isActive: false,
            onClick: loadSettings,
        },

    ];

    return (
        <AppTopNavi className="navigation-items">
            {Links.map((link: any, linkIndex: number) => {
                return (
                    <AppTopNaviItem
                        key={linkIndex}
                        className="nav-active-link"
                        text={link.text}
                        icon={link.icon}
                        active={location.pathname === link.url}
                        onClick={link.onClick}
                    />
                );
            })}
        </AppTopNavi>
    );
}
// )
// );

function ActionBar(props: any) {
    return (
        <AppHeader className="navbar flex-even header-action-bar-container">
            {/* left side of navbar */}
            <NavBarTabs />

            {/*
            <Flex className="search-bar-container" fill>
                <SearchBar placeholder="Search assets and models" />
            </Flex> */}

            <Flex fill>
                <ActionIcon actionArr={RIGHT_SIDE_ACTION_ARR} />
            </Flex>
        </AppHeader>
        // <Navbar
        //     className="navbar flex-even header-action-bar-container"
        //     expand="md"
        // >
        //     {/* left side of navbar */}
        //     <Flex fill>
        //         {Links.map((link: any, linkIndex: number) => {
        //             return (
        //                 <NavigationLink
        //                     key={linkIndex}
        //                     to={link.url}
        //                     icon={link.icon}
        //                     text={link.text}
        //                     isActive={
        //                         location.pathname === link.url ? true : false
        //                     }
        //                 />
        //             );
        //         })}
        //     </Flex>
        //     {/*
        //     <Flex className="search-bar-container" fill>

        //         <SearchBar placeholder="Search assets and models" />

        //     </Flex> */}

        //     <Flex fill>
        //         <ActionIcon actionArr={RIGHT_SIDE_ACTION_ARR} />
        //         {/* <TopNavigationIcon icon={alarmBellIcon} altText='alarm' />

        // <TopNavigationIcon icon={userInCircleIcon} altText='user' />
        // <TopNavigationIcon icon={settingsIcon} altText='settings' /> */}
        //     </Flex>
        // </Navbar>
    );
}

export default ActionBar;
