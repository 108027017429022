import React, { Fragment, useState, ReactNode } from "react";
import { LoadingIndicator } from "@abb/abb-common-ux-react";

import "./style.scss";

interface LoaderProps {
    sizeClass: "small" | "medium" | "large";

    type: "radial" | "bar";
    determinate?: boolean;
    color?: string;
    value?: number;
    children?: ReactNode;
}

const Loader = (props: LoaderProps) => {
    const [sizeClasses, setSizeClasses] = useState([
        "small",
        "medium",
        "large"
    ]);
    return (
        <div className="wrapper-loader-parent">
            <div className="loader-parent">
                <LoadingIndicator
                    // @ts-ignore
                    type={props.type}
                    determinate={
                        (props.determinate && props.determinate) || false
                    }
                    // @ts-ignore
                    color={(props.color && props.color) || "blue"}
                    // @ts-ignore
                    sizeClass={props.sizeClass}
                    value={props.value ? props.value : 80}
                />
                <div className="loaderText">
                    {props.children && props.children}
                </div>
            </div>
        </div>
    );
};
export default Loader;
