import {
    ROOT_LEVEL_ID,
    DEFAULT_TREE_LIST_NODE,
    DEFAULT_TREE_VIRTUAL_LIST_NODE
} from "../../utils/constants/appConstants";
import { isNilOrEmpty } from "../../utils/helpers";
import uuid from "uuid";
import _ from "lodash";
import {
    SystemObjTypes,
    TreeNodeType,
    StructuresObjTypes,
    SystemStructuresObjTypes
} from "./types";
import { Node } from "react-virtualized-tree";

export const mapAssetsCategoryListModel = (dataArr: string[]) => {
    if (isNilOrEmpty(dataArr)) {
        return [];
    }
    return _.map(dataArr, categoryType => {
        return {
            id: uuid(),
            name: categoryType,
            value: categoryType
        };
    });
};

const getDefaultRootObj = (rootObj: TreeNodeType | null) => {
    let localRootObj: TreeNodeType | null = null;

    if (rootObj) {
        localRootObj = rootObj;
    } else {
        localRootObj = _.cloneDeep(DEFAULT_TREE_LIST_NODE);
    }
    return localRootObj;
};

const getDefaultRootObjVirtualTree = (rootObj: Node | null) => {
    let localRootTreeObj: Node | null = null;
    localRootTreeObj = rootObj ? rootObj : _.cloneDeep(DEFAULT_TREE_VIRTUAL_LIST_NODE);
    return localRootTreeObj;
};

export const mapSystemModel = (
    systemArr: SystemObjTypes[],
    rootObj: TreeNodeType | null
): TreeNodeType => {
    let localRootObj = getDefaultRootObj(rootObj);

    if (isNilOrEmpty(systemArr)) {
        return localRootObj;
    }

    const childNodes = _.map(systemArr, systemObj => {
        const level = localRootObj ? localRootObj.level + 1 : 1;
        return {
            level,
            ancestorLevel: `${localRootObj ? localRootObj.ancestorLevel : ""
                }/${level}`,

            modelId: systemObj.model,
            parentId: localRootObj.objectId,
            type: systemObj.type,
            objectId: systemObj.objectId,
            name: systemObj.name || systemObj.objectId,
            version: systemObj.version,
            loading: false,
            toggled: false,
            description: "",
            path: systemObj.path,
            isDevDeleted : systemObj.isDevDeleted
        };
    });

    localRootObj.children = childNodes as [];

    return localRootObj;
};

// todo
export const mapStructureModel = (
    structuresArr: StructuresObjTypes[] | SystemStructuresObjTypes[],
    rootObj: TreeNodeType | null,
    typeOfObj = ""
): any => {
    let localRootObj = getDefaultRootObj(rootObj);

    if (isNilOrEmpty(structuresArr)) {
        return [];
    }

    const childNodes = _.map(structuresArr, (structureObj: any) => {
        let childNode: any = {};
        const level = localRootObj ? localRootObj.level + 1 : 1;
        childNode.level = level;
        childNode.ancestorLevel = localRootObj.ancestorLevel
            ? localRootObj.ancestorLevel
            : "";
        childNode.parentId = localRootObj.objectId;

        childNode.loading = false;
        childNode.toggled = false;

        // todo
        childNode.name =
            // @ts-ignore
            structureObj.name ||
            // @ts-ignore
            structureObj.typeId ||
            // @ts-ignore
            structureObj.objectId ||
            "";
        // @ts-ignore
        childNode.modelId = structureObj.model;
        // @ts-ignore
        childNode.type = structureObj.type || "";
        // @ts-ignore
        childNode.link = structureObj.link || "";

        // @ts-ignore
        childNode.isLeaf = structureObj.isLeaf || false;

        // childNode.structureModelId =
        // @ts-ignore
        // structureObj.level === 3
        //     ? // @ts-ignore
        //       structureObj.modelId
        //     : // @ts-ignore
        //       rootObj.structureModelId;
        if (level === 3) {
            // @ts-ignore
            childNode.structureModelId = structureObj.model;
        } else {
            // @ts-ignore
            childNode.structureModelId = rootObj.structureModelId;
        }
        // rootObj.structureModelId || rootObj.modelId;
        // @ts-ignore
        if (structureObj.objectId) {
            // @ts-ignore
            childNode.objectId = structureObj.objectId;
        } else {
            // @ts-ignore
            childNode.objectId = `${localRootObj.objectId}/${structureObj.model}`;
        }

        // @ts-ignore
        childNode.description = structureObj.description;
        childNode.version = "";
        childNode.path = structureObj.path;
        childNode.isDevDeleted = structureObj.isDevDeleted;
        return childNode;
    });

    // localRootObj.children = childNodes as [];
    console.log("childNodes", childNodes);
    return childNodes;
};

/**
 * Map node to virtualized Tree model from TreeNodeType model
 * @param TreeNodeType 
 */
export const mapTreeModel = (rootObj: TreeNodeType): Node => {
    let treeData = rootObj.level == 1 ? getDefaultRootObjVirtualTree(null) : {
        id: rootObj.objectId!,
        name: rootObj.name,
        state: rootObj,
        children: []
    };
    const childNodes = _.map(rootObj.children, treeObj => {
        return {
            id: treeObj.objectId!,
            name: treeObj.name,
            state: { ...treeObj },
            children: []
        };
    });
    treeData.children = [...childNodes];
    return treeData;
};

/**
 * Assign node children and state to selected Tree node
 * @param parentNode
 * @param id
 * @param attachNode  
 */
export const mapStructureToTreeNode = (parentNode: Node, id: number | string, attachNode: Node): Node | null => {
    const stack = [parentNode];
    while (stack.length) {
        let node = stack.pop();
        node!.state!.active = false;
        if (node && node.id === id) {
            node.state!.active = true;
            node.name = node.state!.name || node.name;
            node.children = attachNode.children;
            return node;
        }
        node!.children && stack.push(...node!.children);
    }
    return stack.pop() || null;
};

/**
 * Delete node
 * @param parentNode
 * @param id
 */
export const removeTreeNode = (parent: any, id: number | string) => {
    parent.children = parent.children && parent.children
        .filter((child: any) => child.id !== id)
        .map((child: any) => removeTreeNode(child, id));
    return parent;
}

export const resetNodeItem = (parentNode: Node, id: number | string): Node | null => {
    const stack = [parentNode];
    while (stack.length) {
        let node = stack.pop();
        if (node && node.id === id) {
            node.name = node.state!.name || node.name;
            node.children = [];
            return node;
        }
        node!.children && stack.push(...node!.children);
    }
    return stack.pop() || null;
};
// deleteNode(topNode: any, selectedNode: any) {
//   if (topNode.children != null) {
//     for (let i in topNode.children) {
//       if (topNode.children[i].id == selectedNode.id) {
//         topNode.children.splice(i, 1);
//         return;
//       } else this.deleteNode(topNode.children[i], selectedNode);
//     }
//   } else return;
// }