import React from "react";
import ActionBar from "./ActionBar";
import Flex from "../shared/Flex";
import { ABB_LOGO } from "../../utils/constants/assets";
import "./styles.scss";
import { sce } from "sce-engg-model-19.09";
import { AbbBar } from "@abb/abb-common-ux-react";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import { StoreState } from "../../store";
import {
    CONFIGURATION_TOOL_MODE,
    ROUTE_PATHNAME,
} from "../../utils/constants/appConstants";
import AppSettings from '../../services/AppSettings';

interface AppMethods {
    showModal?(): void;
}

const logo = (): JSX.Element => {
    return (
        <img
            src={ABB_LOGO}
            style={{ height: "15px" }}
            className="img-fluid"
            alt="logo"
        />
    );
};

export function TopNavigation(
    props: AppMethods & RouteComponentProps & ReturnType<typeof mapStateToProps>
) {
    const { pathname } = props.location;
    console.log("pathname :", pathname);
    return (
        <Flex className="header-container" column>
            <AbbBar customLogo={logo()} productName={AppSettings.productName} />
            {/* <Flex className="header-logo-container">
                <div className="header-logo">
                    <img src={ABB_LOGO} className="img-fluid" alt="logo" />
                </div>
                <Flex className="header-title" center>
                    <span className="project-title">
                        {sce.getTitleLabel
                            ? sce.getTitleLabel() || "Asset Model Engineering"
                            : "Asset Model Engineering"}
                    </span>
                </Flex>
            </Flex> */}
            {pathname !== "/help/portal" && (
                <Flex>
                    <ActionBar />
                </Flex>
            )}
        </Flex>
    );
}

const mapStateToProps = (state: StoreState) => {
    return {
        // editMode:
        //     state.configurationTool.mode === CONFIGURATION_TOOL_MODE.EDIT ||
        //     state.configurationTool.mode === CONFIGURATION_TOOL_MODE.CREATE,
    };
};
export default connect(mapStateToProps)(withRouter(TopNavigation));
