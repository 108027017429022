import { ActionTypes } from "../actionTypes";
// import { AssetType } from "../../transformers/AssetType";
// import { ComputeModelFromJson } from "../../transformers/ComputeModel/fromJson/ComputeModelFromJson";
import { SAVE_OPTIONS } from "../../utils/constants/appConstants";

import {
    ModelUsedInstanceType,
    InstancePaginationType,
    IModelPermission,
    IInfoModelInstance,
    ModelSaveType,
} from "./types";

export function getAssetsList() {
    return <const>{
        type: ActionTypes.FETCH_ASSETS_LIST,
    };
}

export const updateSupportedModels = (
    payload: {
        key: string;
        value: any;
    }[]
) => {
    return <const>{
        type: ActionTypes.UPDATE_SUPPORTED_MODEL_DATA,
        payload,
    };
};

export const getModelUsedbyInstances = (payload: InstancePaginationType) => {
    return <const>{
        type: ActionTypes.GET_MODEL_USED_BY_INSTANCES,
        payload,
    };
};

export const updateModelUsedbyInstances = (
    payload: ModelUsedInstanceType[]
) => {
    return <const>{
        type: ActionTypes.UPDATE_MODEL_USED_BY_INSTANCES,
        payload,
    };
};

export const handleSidebarToggle = (toggleStatus: boolean) => {
    return <const>{
        type: ActionTypes.HANDLE_SIDEBAR_TOGGLE,
        payload: toggleStatus,
    };
};


export const resetPaginationLoader = (payload: boolean) => {
    return <const>{
        type: ActionTypes.RESET_PAGINATION_LOADER,
        payload: payload,
    };
};

export function getAllPermisssionForModelAction() {
    return {
        type: ActionTypes.GET_ALL_PERMISSION_MODEL,
    };
}

export function updateModelPermission(payload: IModelPermission) {
    return {
        type: ActionTypes.UPDATE_MODEL_PERMISSION,
        payload,
    };
}


export const getInfoModelInstancesRequest = () => {
    return <const>{
        type: ActionTypes.GET_INFO_MODEL_INSTANCES,
    };
};

export const updateInfoModelInstanceRequest = (
    supportedModel: IInfoModelInstance,
    identityModel: IInfoModelInstance[]
) => {
    return <const>{
        type: ActionTypes.UPDATE_INFO_MODEL_INSTANCE,
        payload: {
            supportedModel: supportedModel,
            identityModel: identityModel,
        },
    };
};

export const saveInfoModelInstanceRequest = (
    val: IInfoModelInstance,
    type: string
) => {
    return <const>{
        type: ActionTypes.SAVE_INFO_MODEL_INSTANCE,
        payload: {
            infoModel: val,
            type: type,
        },
    };
};

export const deleteInfoModelInstanceRequest = (
    objectId: string,
    modelId: string
) => {
    return <const>{
        type: ActionTypes.DELETE_INFO_MODEL_INSTANCE,
        payload: {
            objectId: objectId,
            modelId: modelId,
        },
    };
};

export const getTypeDefinitionRequest = () => {
    return <const>{
        type: ActionTypes.GET_TYPE_DEFINITION_REQUEST,
    };
};

export const updateTypeDefinitionRequest = (val: IInfoModelInstance) => {
    return <const>{
        type: ActionTypes.UPDATE_NEW_DEFINITION_TYPE,
        payload: val,
    };
};

export const showProgresRequest = (val: boolean) => {
    return <const>{
        type: ActionTypes.SHOW_PROGRESS,
        payload: val,
    };
};

export const handleLoadingUpdateTypeRelatedIntance = (isLoading: boolean) => {
    return <const>{
        type: ActionTypes.HANDLE_LOADING_UPDATE_TYPE_RELATED_INSTANCE,
        payload: isLoading,
    };
};


export const getAvailableAssetTypeIds = () => {
    return {
        type: ActionTypes.GET_AVAILABLE_TYPE_IDS,
    };
};

export const updateAvailableTypeIds = (payload: string[]) => {
    return {
        type: ActionTypes.UPDATE_AVAILABLE_TYPE_IDS,
        payload,
    };
};
export function setAuthorize(payload: boolean) {
    return <const>{
        type: ActionTypes.SET_AUTHORIZE,
        payload,
    };
}

// export const updateOverallSeverityFunctionId = (nodeId: string) => {
//     return <const>{
//         type: ActionTypes.UPDATE_OVERAll_SEVERITY_FUNCTION_ID,
//         payload: nodeId,
//     };
// };

// TABLE ACTION
// question for type
// export const updateCanvasJsonAction = (
//     canvasJson: any = { assetData: [], connectionData: [] }
// ) => {
//     const transformInstance = new TransformCanvasToTable();
//     transformInstance.transform(canvasJson);
//     return <const>{
//         type: ActionTypes.TABLE_UPDATE_CANVAS_JSON,
//         payload: {
//             canvasJson,
//             transformInstance
//         }
//     };
// };

// export const handleAssetDropAction = (dataPayload: AssetDropActionType) => {
//     return <const>{
//         type: ActionTypes.ADD_ASSET_OBJECT_REQUEST,
//         payload: dataPayload
//     };
// };
// export const handleAssetDropSuccessAction = (
//     dataPayload: AssetDropActionSuccessType
// ) => {
//     return <const>{
//         type: ActionTypes.ADD_ASSET_OBJECT_SUCCESS,
//         payload: dataPayload
//     };
// };
// export const tableIOConnectionRequestAction = (
//     dataPayload: TableIoConnectionRequestType
// ) => {
//     return <const>{
//         type: ActionTypes.TABLE_IO_CONNECTION_REQUEST,
//         payload: dataPayload
//     };
// };

// export const handleAssetDropSuccessAction = (
//   dataPayload: AssetDropActionSuccessType
// ) => {
//   return <const>{
//     type: ActionTypes.ADD_ASSET_OBJECT_SUCCESS,
//     payload: dataPayload
//   };
// };
