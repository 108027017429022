import logger from "redux-logger";
import createSagaMiddleware from "redux-saga";
import { ToastrState } from "react-redux-toastr";
import { applyMiddleware, createStore } from "redux";
import { routerMiddleware } from "connected-react-router";
import history from "./history";
import rootSaga from "./rootSaga";
import reducers from "./combineReducers";
import { ModalState } from "./modal/types";
import { LoginState } from "./login/types";
import { AssetState } from "./assets/types";
import { LoaderState } from "./loader/types";
import { MessageModalState } from "./messageModal/types";
import { NotificationModalState } from "./notificationModal/types";
import { DialogState } from './dialog/types';
import { ModelsPageState } from "./modelsPage/types";
import { SupportedConditionMonitorState } from "./supportedConditionMonitors/types";
import { SettingsState } from './settings/types';

const middlewares: any[] = [];
if (process.env.NODE_ENV !== `production`) {
    middlewares.push(logger);
}

export interface StoreState {
    login: LoginState;
    modal: ModalState;
    toastr: ToastrState;
    loader: LoaderState;
    messageModal: MessageModalState;
    assets: AssetState;
    notificationModal: NotificationModalState;
    dialog: DialogState;
    modelsPage: ModelsPageState;
    supportedConditionMonitor: SupportedConditionMonitorState;
    settings: SettingsState;
}

const sagaMiddleware = createSagaMiddleware();
const routerHistoryMiddleware = routerMiddleware(history);

middlewares.push(sagaMiddleware);
middlewares.push(routerHistoryMiddleware);

export default () => {
    let store = createStore(reducers, applyMiddleware(...middlewares));
    sagaMiddleware.run(rootSaga);
    return { store };
};
