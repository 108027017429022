import React, { useState, useMemo } from 'react';
import { Icon } from '@abb/abb-common-ux-react';
import './style.scss';
import { SettingsLeftNavOptions } from '../../../store/settings/types';
import { sce } from 'sce-engg-model-19.09';
interface ILeftNavigationProps {
    setActiveTab: (activeTab: SettingsLeftNavOptions) => void;
    activeTab: SettingsLeftNavOptions;
}
const LeftNavigation = (props: ILeftNavigationProps) => {
    const { setActiveTab, activeTab } = props;

    const tabData = useMemo(() => {
        if (sce.isDeveloper()) {
            return [
                { name: SettingsLeftNavOptions.CONNECT_MODELS, icon: 'abb/connect' },
                { name: SettingsLeftNavOptions.IDENTITY, icon: 'abb/align-bottom' }
            ];
        } else {
            return [
                { name: SettingsLeftNavOptions.CONNECT_MODELS, icon: 'abb/connect' },
                { name: SettingsLeftNavOptions.IDENTITY, icon: 'abb/align-bottom' }
            ];
        }
    }, []);

    return (
        <div className="wrapper-settings-left-nav">
            <div className="wrapper-setting-tabs">
                {tabData.map((item: { name: SettingsLeftNavOptions; icon: string }) => {
                    return (
                        <div
                            className={`tab-item ${activeTab == item.name ? 'active' : ''}`}
                            onClick={() => setActiveTab(item.name)}
                            key={item.name}
                        >
                            <Icon name={item.icon} />
                            <span>{item.name}</span>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default LeftNavigation;
