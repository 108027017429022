import { combineReducers } from "redux";

import history from "./history";
import modalReducer from "./modal/reducer";
import { reducer as toastrReducer } from "react-redux-toastr";
import { loginReducer } from "./login/reducer";
import { loaderReducer } from "./loader/reducer";
import assetsReducer from "./assets/reducer";
import messageModalReducer from "./messageModal/reducer";
import { connectRouter } from "connected-react-router";
import NotificationModalReducer from "./notificationModal/reducer";
import dialogReducer from './dialog/reducer';
import { modelsPageReducer } from "./modelsPage/reducer";
import supportedConditionMonitorReducer from "./supportedConditionMonitors/reducer";
import settingReducer from './settings/reducer';


const allReducers = combineReducers({
    login: loginReducer,
    modal: modalReducer,
    toastr: toastrReducer,
    loader: loaderReducer,
    router: connectRouter(history),
    messageModal: messageModalReducer,
    assets: assetsReducer,
    notificationModal: NotificationModalReducer,
    dialog: dialogReducer,
    modelsPage: modelsPageReducer,
    supportedConditionMonitor: supportedConditionMonitorReducer,
    settings: settingReducer,
});

export default allReducers;
