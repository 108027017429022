import React from "react";
import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import ReduxToastr from "react-redux-toastr";

import AppRoutes from "../../routes";
import configureStore from "../../store/index";

import { Provider } from "react-redux";

import CustomModal from "../../components/CustomModal";
import MessageModal from "../../components/MessageModal";
import NotificationModal from "../../components/NotificationModal";
import CustomDialog from '../../components/CustomDialog';
import AppSettings from "../../services/AppSettings";
import TimeOutHandler from "../../components/TimeoutHandler/TimeoutHandler";
import { commonAuthHelper } from "../../utils/types/commonInterface";

const { store } = configureStore();

function AppBootUp() {
    const userLogout = () => {
        console.log("logging out");
        if (AppSettings.connectivityMode !== "connectondemand") {
            localStorage.clear();
            sessionStorage.clear();
        }
        commonAuthHelper.logout();
    };
    return (
        <Provider store={store}>
            <DndProvider backend={HTML5Backend}>
                <AppRoutes />
                <CustomModal />
                <MessageModal />
                <CustomDialog />
                <NotificationModal />
                {/* <AlarmConfigurationModal /> */}
                <TimeOutHandler onLogout={userLogout} />
            </DndProvider>

            <ReduxToastr />
        </Provider>
    );
}

export { store };
export default AppBootUp;
