import { isNilOrEmpty } from "../../utils/helpers";
import {
    ROOT_LEVEL_ID,
    DEFAULT_TREE_LIST_NODE,
    DEFAULT_TREE_VIRTUAL_LIST_NODE,
    DEFAULT_ASSET_STRUCTURE_DETAILS,
    DEFAULT_FILTER_QUERY,
    GROUP_BY_LIST,
} from "../../utils/constants/appConstants";
import { AssetState, AssetsActions, IAssetPermission } from "./types";
import { ActionTypes } from "../actionTypes";
import _ from "lodash";
import { isSystemCategorySelected } from "./utils";
import { Node } from "react-virtualized-tree";

export const initialState: AssetState = {
    categoryArr: [],
    selectedCategory: undefined,
    systemCategorySelected: false,
    treeList: _.cloneDeepWith(DEFAULT_TREE_LIST_NODE),
    normalizedTreeList: {
        byId: {},
        entities: [],
    },
    selectedTreeNode: null,
    configurationMode: false,
    isLoading: false,
    sysEdgeLoading: false,
    treeVirtualList: _.cloneDeepWith(DEFAULT_TREE_VIRTUAL_LIST_NODE),
    assetStructureDetails: _.cloneDeepWith(DEFAULT_ASSET_STRUCTURE_DETAILS),
    searchAsset: "",
    assetPermission: {
        hasMonitorInstance: true,
        hasDeleteInstance: true,
        hasEnableInstance: true,
        hasDisableInstance: true
    } as IAssetPermission,
    filterQuery: _.cloneDeepWith(DEFAULT_FILTER_QUERY),
    selectedParentTreeNodeId: null,
    selectedNodeParentList: [],
    groupByList: _.cloneDeepWith(GROUP_BY_LIST),
    normalizedDropTreeList: {
        byId: {},
        entities: [],
    },
    treeNewVirtualList: _.cloneDeepWith(DEFAULT_TREE_VIRTUAL_LIST_NODE),
    existingStructure: undefined,
    selectedTreeNodeDrop: null,
    isHierarchyLoading: false,
    isTreeHierarchyLoading: false,
    isLoadObjectHierarchy: false
};

const assetsReducer = (
    state = initialState,
    action: AssetsActions
): AssetState => {
    switch (action.type) {
        case ActionTypes.ASSET_CATEGORY_LIST_SUCCESS:
        case ActionTypes.ASSET_CATEGORY_LIST_FAILURE: {
            const categoryArr = [...action.payload.dataArr];
            return {
                ...state,
                categoryArr,
            };
        }
        case ActionTypes.ASSET_CATEGORY_SELECTION: {
            const systemCategorySelected = isSystemCategorySelected(
                action.payload.categoryObj
            );
            return {
                ...state,
                selectedCategory: action.payload.categoryObj,
                systemCategorySelected,
            };
        }

        case ActionTypes.GET_SYSTEMS_REQUEST: {
            state.treeList = _.cloneDeep(initialState.treeList);

            return {
                ...state,
                sysEdgeLoading: true,
            };
        }
        case ActionTypes.GET_EDGES_REQUEST: {
            state.treeList = _.cloneDeep(initialState.treeList);

            return {
                ...state,
                sysEdgeLoading: true,
            };
        }
        case ActionTypes.UPDATE_TREE_LIST_NODES: {
            const treeList = { ...action.payload.data.node };
            const treeVirtualList = { ...action.payload.data.treeVirtualNodes };

            const normalizedTreeList = {
                byId: {
                    ...action.payload.data.normalizeNode.byId,
                },
                entities: [...action.payload.data.normalizeNode.entities],
            };
            return {
                ...state,
                sysEdgeLoading: false,
                treeList,
                normalizedTreeList,
                treeVirtualList,
            };
        }
        case ActionTypes.GET_STRUCTURES_SUCCESS: {
            return {
                ...state,
                normalizedTreeList: action.payload.data.normalizeTreeNodeList,
                treeList: {
                    ...state.treeList,
                },
            };
        }
        case ActionTypes.UPDATE_SELECTED_TREE_NODE: {
            return {
                ...state,
                selectedTreeNode: action.payload.data,
            };
        }
        case ActionTypes.TOGGLE_ASSET_PAGE: {
            return {
                ...state,
                configurationMode: !state.configurationMode,
            };
        }
        case ActionTypes.HANDLE_ASSET_TABLE_ACTION_MENU_CLICK_REQUEST: {
            let menuData = { ...action.payload.data };
            if (menuData.id === 1) {
                return { ...state };
            }
            return {
                ...state,
            };
        }

        case ActionTypes.GET_ASSET_INSTANCE_LIST_REQUEST: {
            return {
                ...state,
                isLoading: true,
            };
        }

        case ActionTypes.HANDLE_ASSET_TABLE_ACTION_MENU_CLICK_SUCCESS:
        case ActionTypes.HANDLE_ASSET_TABLE_ACTION_MENU_CLICK_FAILURE:
        case ActionTypes.GET_ASSET_INSTANCE_LIST_SUCCESS:
        case ActionTypes.GET_ASSET_INSTANCE_LIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case ActionTypes.UPDATE_NORMALIZED_TREE_LIST_NODE: {
            if (isNilOrEmpty(_.get(action, "payload.data.objectId", ""))) {
                return state;
            }
            state.normalizedTreeList.byId[
                _.get(action, "payload.data.objectId", "")
            ] = _.get(action, "payload.data", {});
            return {
                ...state,
                treeList: {
                    ...state.treeList,
                },
                normalizedTreeList: {
                    ...state.normalizedTreeList,
                },
                treeVirtualList: {
                    ...state.treeVirtualList,
                },
                treeNewVirtualList: _.cloneDeep(state.treeNewVirtualList)
            };
        }

        // Common Structure Browsing
        case ActionTypes.UPDATE_GROUP_BY: {
            let clonedAssetStructureDetails = _.cloneDeep(
                state.assetStructureDetails
            );
            clonedAssetStructureDetails.selectedGroupBy = action.payload.data;
            clonedAssetStructureDetails.structureList = [];
            clonedAssetStructureDetails.selectedStructure = undefined;
            clonedAssetStructureDetails.systemList = [];
            clonedAssetStructureDetails.selectedSystem = undefined;
            return {
                ...state,
                isLoading: false,
                treeList: _.cloneDeepWith(DEFAULT_TREE_LIST_NODE),
                treeVirtualList: _.cloneDeepWith(
                    DEFAULT_TREE_VIRTUAL_LIST_NODE
                ),
                assetStructureDetails: clonedAssetStructureDetails,
            };
        }

        case ActionTypes.UPDATE_STRUCTURE: {
            state.assetStructureDetails.selectedStructure = action.payload.data;
            state.assetStructureDetails.selectedSystem = undefined;
            return {
                ...state,
            };
        }

        case ActionTypes.SEARCH_ASSET: {
            state.searchAsset = action.payload.data;
            return {
                ...state,
            };
        }

        case ActionTypes.UPDATE_SYSTEM: {
            state.assetStructureDetails.selectedSystem = action.payload.data;
            state.assetStructureDetails.selectedStructure = undefined;
            return {
                ...state,
            };
        }

        case ActionTypes.UPDATE_SYSTEM_STRUCTURE_DETAILS: {
            let clonedAssetStructureDetails = _.cloneDeep(
                state.assetStructureDetails
            );
            clonedAssetStructureDetails.structureList = action.payload.data;
            clonedAssetStructureDetails.systemList = [];

            return {
                ...state,
                assetStructureDetails: clonedAssetStructureDetails,
            };
        }

        case ActionTypes.UPDATE_CONNECT_DETAILS_FROM_IDENTITY_MODELS: {
            let clonedAssetStructureDetails = _.cloneDeep(
                state.assetStructureDetails
            );
            clonedAssetStructureDetails.structureList = [];
            clonedAssetStructureDetails.systemList = action.payload.data;

            return {
                ...state,
                assetStructureDetails: clonedAssetStructureDetails,
            };
        }
        case ActionTypes.GET_NEW_ASSET_INSTANCE_LIST_REQUEST: {
            return {
                ...state,
                isLoading: true,
            };
        }

        case ActionTypes.UPDATE_ASSET_PERMISSION: {
            return {
                ...state,
                assetPermission: { ...action.payload }
            }
        }
        // Filter Query
        case ActionTypes.UPDATE_SELECTED_PARENT_TREE_NODE: {
            let filterQuery = _.cloneDeep(state.filterQuery);
            filterQuery.applyFilter = false;
            filterQuery.filteredItems = [];

            return {
                ...state,
                filterQuery: filterQuery,
                selectedParentTreeNodeId: action.payload.data,
            };
        }
        case ActionTypes.UPDATE_SELECTED_NODE_PARENT_LIST: {
            return {
                ...state,
                selectedNodeParentList: action.payload.data,
            };
        }

        case ActionTypes.GET_FILTER_ASSET_MODEL_TYPE_REQUEST: {
            let filterQuery = _.cloneDeep(state.filterQuery);
            filterQuery.isFilterLoading = true;
            return {
                ...state,
                filterQuery: filterQuery,
            };
        }
        case ActionTypes.UPDATE_FILTER_ASSET_MODEL_TYPE: {
            let filterQuery = _.cloneDeep(state.filterQuery);
            filterQuery.assetModelTypes = action.payload.data.assetModelTypes;
            filterQuery.assetTypes = action.payload.data.assetTypes;
            filterQuery.isFilterLoading = false;

            return {
                ...state,
                filterQuery: filterQuery,
            };
        }

        case ActionTypes.UPDATE_FILTER_ASSET_QUERY: {
            let filterQuery = _.cloneDeep(state.filterQuery);
            filterQuery.filteredItems = action.payload.filteredItems;
            filterQuery.applyFilter = action.payload.applyFilter;

            return {
                ...state,
                filterQuery: filterQuery,
            };
        }

        case ActionTypes.HANDLE_INSTANCE_MONITORING: {
            return {
                ...state,
                isLoading: true,
            };
        }

        case ActionTypes.START_CRITICALITY_MONITORING: {
            return {
                ...state,
                isLoading: true,
            };
        }

        case ActionTypes.UPDATE_NEW_HIERARCHY_NODE: {
            const treeNode = { ...action.payload.data };
            return {
                ...state,
                treeNewVirtualList: _.cloneDeep(treeNode)
            };
        }

        case ActionTypes.CLEAR_HIERARCHY_DROP_NODE_REQUEST: {
            return {
                ...state,
                treeNewVirtualList: _.cloneDeepWith(DEFAULT_TREE_VIRTUAL_LIST_NODE)
            };
        }

        case ActionTypes.UPDATE_TREE_LIST_DROP_NODES: {
            const treeNewVirtualList = { ...action.payload.data.treeVirtualNodes };

            // const normalizedDropTreeList = {
            //     byId: {
            //         ...action.payload.data.normalizeNode.byId,
            //     },
            //     entities: [...action.payload.data.normalizeNode.entities],
            // };
            return {
                ...state,
                // normalizedDropTreeList,
                treeNewVirtualList
            };
        }

        case ActionTypes.SET_EXISTING_STRUCTURE: {
            const existingStructure = { ...action.payload.data };
            return {
                ...state,
                existingStructure
            };
        }

        case ActionTypes.UPDATE_SELECTED_TREE_NODE_DROP: {
            return {
                ...state,
                selectedTreeNodeDrop: action.payload.data,
            };
        }

        case ActionTypes.ADD_DEFAULT_NODE: {
            const treeList = _.cloneDeep(state.treeNewVirtualList);
            const childNode: Node = {
                id: action.payload.node.objectId,
                name: action.payload.node.name,
                state: action.payload.node,
                children: []
            }
            treeList.children = treeList.children && treeList.children.concat(childNode);
            return {
                ...state,
                treeNewVirtualList: treeList
            };
        }

        case ActionTypes.LOAD_STRUCTURE_LIST_REQUEST: {
            return {
                ...state,
                isHierarchyLoading: action.payload,
            };
        }

        case ActionTypes.LOAD_TREE_HIERARCHY_LIST_REQUEST: {
            return {
                ...state,
                isTreeHierarchyLoading: action.payload,
            };
        }

        case ActionTypes.UPDATE_SYSTEM_STRUCTURE_DETAILS_FOR_TENANT: {
            let clonedAssetStructureDetails = _.cloneDeep(
                state.assetStructureDetails
            );
            clonedAssetStructureDetails.structureListForTenant = action.payload.data;
            return {
                ...state,
                assetStructureDetails: clonedAssetStructureDetails,
            };
        }

        case ActionTypes.RESET_COMMON_HIERARCHY: {
            return {
                ...state,
                treeList: _.cloneDeepWith(DEFAULT_TREE_LIST_NODE),
                normalizedTreeList: {
                    byId: {},
                    entities: [],
                },
                selectedTreeNode: null,
                treeVirtualList: _.cloneDeepWith(DEFAULT_TREE_VIRTUAL_LIST_NODE),
                assetStructureDetails: _.cloneDeepWith(DEFAULT_ASSET_STRUCTURE_DETAILS),
                searchAsset: "",
                filterQuery: _.cloneDeepWith(DEFAULT_FILTER_QUERY),
                selectedParentTreeNodeId: null,
                selectedNodeParentList: [],
                groupByList: _.cloneDeepWith(GROUP_BY_LIST),
                normalizedDropTreeList: {
                    byId: {},
                    entities: [],
                },
                treeNewVirtualList: _.cloneDeepWith(DEFAULT_TREE_VIRTUAL_LIST_NODE),
                existingStructure: undefined,
                selectedTreeNodeDrop: null,
            }
        }
        case ActionTypes.LOAD_OBJECT_HIERARCHY: {
            return {
                ...state,
                isLoadObjectHierarchy :_.cloneDeep(action.payload)
            };
        }

        default: {
            return state;
        }
    }
};

export default assetsReducer;
