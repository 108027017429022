import { ActionTypes } from "../actionTypes";
import { ShowMessageModalPayload } from "./types";

export function showMessageModal(payload: ShowMessageModalPayload) {
    return <const>{
        type: ActionTypes.SHOW_MESSAGE_MODAL,
        payload,
    };
}

export function hideMessageModal() {
    return <const>{
        type: ActionTypes.HIDE_MESSAGE_MODAL,
    };
}
