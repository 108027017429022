import React, { Fragment, useMemo, useEffect } from 'react';
import _ from 'lodash';
import Condition from '../../components/shared/Condition';
import {
    BaseLayout,
    BodyContainer,
    SidebarContainer,
    NoAccess,
} from '../../containers/EditorLayout';
// import VerticalNavBar from '../../containers/EditorLayout/VerticalNavBar';
import LeftNavigation from './LeftNavigation';
import { connect } from 'react-redux';
import { StoreState } from '../../store';
import { Dispatch } from 'redux';
import { SettingsLeftNavOptions } from '../../store/settings/types';
import { setActiveSettingTab } from '../../store/settings/action';
import ConnectModelsTab from './SupportedTabs/ConnectModelsTab';
import IdentityModelsTab from './SupportedTabs/IdentityModelsTab';
import AppSettings from '../../services/AppSettings';
import { checkPermissionRoles, handleTokenDecode } from '../../utils/helpers';
import { setAuthorize } from '../../store/modelsPage/actions';
import { sce } from 'sce-engg-model-19.09';
import { commonAuthHelper } from '../../utils/types/commonInterface';

const Settings = (
    props: ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>
) => {
    const { activeSettingTab, setActiveTab } = props;

    const canAccess = useMemo(() => {
        let access = false;
        const token = commonAuthHelper.getToken();
        if (!!token) {
            access = checkPermissionRoles(token);
        }
        return access;
    }, []);

    useEffect(() => {
        debugger
        props.setAuthorize(canAccess);
        if (canAccess) {
            handleTokenDecode();
        }
    }, [canAccess]);

    return (
        <Fragment>
            <Condition when={!canAccess}>
                <BaseLayout>
                    <NoAccess />
                </BaseLayout>
            </Condition>
            <Condition when={canAccess}>
                <BaseLayout>
                    {/* <VerticalNavBar /> */}
                    <SidebarContainer isCollapse={false}>
                        <LeftNavigation setActiveTab={setActiveTab} activeTab={activeSettingTab} />
                    </SidebarContainer>
                     <BodyContainer fill>
                        <Condition
                            when={activeSettingTab === SettingsLeftNavOptions.CONNECT_MODELS}
                        >
                            <ConnectModelsTab />
                        </Condition>
                        <Condition when={activeSettingTab === SettingsLeftNavOptions.IDENTITY}>
                            <IdentityModelsTab />
                        </Condition>
                    </BodyContainer> 
                </BaseLayout>
            </Condition>
        </Fragment>
    );
};

const mapStateToProps = (state: StoreState) => {
    return {
        activeSettingTab: state.settings.activeSettingTab,
    };
};
const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        setActiveTab: (activeTab: SettingsLeftNavOptions) => {
            dispatch(setActiveSettingTab(activeTab));
        },
        setAuthorize: (payload: boolean) => {
            dispatch(setAuthorize(payload));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Settings);
