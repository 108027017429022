import { all, fork, takeLatest } from "redux-saga/effects";
import loginSaga from "./login/saga";
import assetsSaga from "./assets/saga";
import supportConditionMonitorsSaga from "./supportedConditionMonitors/saga";
import modelsPageSaga from "./modelsPage/saga/index";

const task = [
    ...loginSaga,
    ...assetsSaga,
    ...modelsPageSaga,
    ...supportConditionMonitorsSaga
];

// const task = []

const rootSaga = function* rootSaga() {
    yield all(task);
};

export default rootSaga;
