import React, { useState, useEffect } from 'react';
import { Input, Icon, Button } from '@abb/abb-common-ux-react';
import Condition from '../shared/Condition';
import _ from 'lodash';
import { Label } from 'reactstrap';
import { IInfoModelInstance } from '../../store/modelsPage/types';
import Flex from '../shared/Flex';


interface IdentityModelProps {
    newConnectData: any[];
    identityModel: IInfoModelInstance[];
    newDefinedType: IInfoModelInstance | undefined;
    isValid: boolean;
    showModify: boolean;
    showConnect: boolean;
    getNewType: () => void;
    updateShowConnect: (val: boolean) => void;
    onShowModifyUpdate: (val: boolean) => void;
    deleteInfoModelInstance: (selectedConnect: IConnect) => void;
    onUpdate: (
        tableData: any[],
        tableDataConnect: any[],
        type: string,
        newDefinedType: IInfoModelInstance,
        selectedConnect: IInfoModelInstance
    ) => void;
    // validationMessage: string;
}
interface IConnect {
    key: string;
    value: any;
    properties: any;
}

function IdentityModel(props: IdentityModelProps) {
    const [connectData, updateConnects] = useState<IConnect[]>([]);
    const [tableData, updateTableData] = useState<any[]>([]);
    const [tableDataForNewConnect, updateTableDataForNewConnect] = useState<any[]>([]);
    const [selectedConnect, updateSelectedConnect] = useState<IConnect>();
    const [newDefinedType, updateNewDefinedType] = useState<IInfoModelInstance>();
    const [showModify, updateShowModify] = useState<boolean>(true);

    const { showConnect } = props;
    useEffect(() => {
        if (props.identityModel && props.identityModel.length > 0) {
            const tempConnects = props.identityModel.map((val: IInfoModelInstance) => {
                return {
                    key: val.objectId,
                    value: _.get(val, 'properties.connect.value', ''),
                    properties: { ...val.properties },
                };
            });
            updateConnects(tempConnects);
            if(selectedConnect){
                const item = tempConnects.find(x => x.key == selectedConnect.key);
                updateOnConnectChange(item!);
            } else {
                updateOnConnectChange(tempConnects[0]);
            }
        }
    }, [props.identityModel, props.showModify]);

    useEffect(() => {
        if (props.newDefinedType) {
            updateNewDefinedType(props.newDefinedType);
            if (_.has(props.newDefinedType, 'properties')) {
                let supportedMap: any = [];
                Object.keys(props.newDefinedType.properties).forEach((keyVal) => {
                    supportedMap.push({
                        key: keyVal,
                        value: props.newDefinedType!.properties[keyVal].value
                            ? props.newDefinedType!.properties[keyVal].value
                            : '',
                    });
                });
                updateTableDataForNewConnect(supportedMap);
                const tempItem = selectedConnect
                    ? props.identityModel.filter((x) => x.objectId == selectedConnect!.key)[0]
                    : undefined;
                props.onUpdate(tableData, supportedMap, 'NEW', props.newDefinedType, tempItem!);
            }
        }
    }, [props.newDefinedType]);

    useEffect(() => {
        updateShowModify(props.showModify);
    }, [props.showModify]);

    useEffect(() => {
        if (props.newConnectData) {
            updateTableDataForNewConnect([...props.newConnectData]);
        }
    }, [props.newConnectData]);

    const updateOnConnectChange = (connect: IConnect) => {
        updateSelectedConnect(connect);
        if (_.has(connect, 'properties')) {
            let supportedMap: any = [];
            Object.keys(connect.properties).forEach((keyVal) => {
                supportedMap.push({
                    key: keyVal,
                    value: connect.properties[keyVal].value,
                });
            });
            updateTableData(supportedMap);
            const tempItem = connect
                ? props.identityModel.filter((x) => x.objectId == connect.key)[0]
                : undefined;
            props.updateShowConnect(showConnect);
            props.onUpdate(
                supportedMap,
                tableDataForNewConnect,
                'EXISTING',
                newDefinedType!,
                tempItem!
            );
        }
    };
    const updateTableProperty = (itemKey: string, value: string, itemIndex: number) => {
        const clonedTableData = _.cloneDeep(tableData);
        clonedTableData[itemIndex].value = value.trim();
        updateTableData(clonedTableData);
        const tempItem = selectedConnect
            ? props.identityModel.filter((x) => x.objectId == selectedConnect!.key)[0]
            : undefined;
        props.updateShowConnect(showConnect);
        props.onUpdate(
            clonedTableData,
            tableDataForNewConnect,
            'EXISTING',
            newDefinedType!,
            tempItem!
        );
    };
    const updateTableForNewConnect = (itemKey: string, value: string, itemIndex: number) => {
        const clonedTableData = _.cloneDeep(tableDataForNewConnect);
        clonedTableData[itemIndex].value = value.trim();
        updateTableDataForNewConnect(clonedTableData);
        const tempItem = selectedConnect
            ? props.identityModel.filter((x) => x.objectId == selectedConnect!.key)[0]
            : undefined;
        props.updateShowConnect(showConnect);
        props.onUpdate(tableData, clonedTableData, 'NEW', newDefinedType!, tempItem!);
    };

    return (
        <>
            <thead>
                <tr>
                    <Condition when={!showConnect}>
                        <th style={{width:"30%"}}>Connects</th>
                        <th style={{width:"70%"}}>
                            <Flex className="connect-header-items">
                                <select
                                    className="selection"
                                    value={
                                        selectedConnect !== null && selectedConnect !== undefined
                                            ? selectedConnect.key
                                            : ''
                                    }
                                    onChange={(e) => {
                                        const selectedObject = connectData.find(
                                            (x) => x.key === e.currentTarget.value
                                        );
                                        if (selectedObject) {
                                            updateOnConnectChange(selectedObject);
                                        }
                                    }}
                                >
                                    {connectData.map((item) => (
                                        <option key={item.key} value={item.key}>
                                            {item.value}
                                        </option>
                                    ))}
                                </select>
                                <Icon
                                    sizeClass="medium"
                                    name="abb/plus-in-circle"
                                    onClick={() => {
                                        if (!newDefinedType) props.getNewType();
                                        props.updateShowConnect(true);
                                        props.onShowModifyUpdate(false);

                                        const connectProperties = selectedConnect!.properties;
                                        const originalTableData = tableData.map((item) => {
                                            return {
                                                key: item.key,
                                                value: connectProperties[item.key].value,
                                            };
                                        });

                                        updateTableData(originalTableData);
                                    }}
                                />
                                <Icon
                                    sizeClass="medium"
                                    name="abb/trash"
                                    style={{ paddingLeft: '8px' }}
                                    onClick={() => {
                                        props.deleteInfoModelInstance(selectedConnect!);
                                    }}
                                />
                            </Flex>
                        </th>
                    </Condition>
                    <Condition when={showConnect}>
                        <th style={{width:"30%"}}>New Connect Details</th>
                        <th style={{width:"70%"}}></th>
                    </Condition>
                </tr>
            </thead>
            <tbody>
                <Condition when={!showConnect}>
                    {tableData.map((item: IConnect, itemIndex: number) => {
                        return (
                            <tr key={itemIndex}>
                                <td style={{width:"30%"}}>
                                    <Label>{item.key}</Label>
                                </td>
                                <td style={{width:"70%"}}>
                                    <Input
                                        value={item.value}
                                        onValueChange={(value) => {
                                            updateTableProperty(item.key, value, itemIndex);
                                        }}
                                        type="normal"
                                        dataType="text"
                                        id="modelId"
                                        showValidationBarWhenInvalid={true}
                                        showValidationIconWhenInvalid={true}
                                        instantValidation={true}
                                        disabled={showModify}
                                    />
                                </td>
                                {/* <td className="width-5"></td> */}
                            </tr>
                        );
                    })}
                </Condition>
                <Condition when={showConnect}>
                    {tableDataForNewConnect.map((item: IConnect, itemIndex: number) => {
                        return (
                            <tr key={itemIndex}>
                                <td style={{width:"30%"}}>
                                    <Label>{item.key}</Label>
                                </td>
                                <td style={{width:"70%"}}>
                                    <Input
                                        value={item.value}
                                        onValueChange={(value) => {
                                            updateTableForNewConnect(item.key, value, itemIndex);
                                        }}
                                        type="normal"
                                        dataType="text"
                                        id="modelId"
                                        showValidationBarWhenInvalid={true}
                                        showValidationIconWhenInvalid={true}
                                        instantValidation={true}
                                    />
                                </td>
                                {/* <td className="width-5"></td> */}
                            </tr>
                        );
                    })}
                </Condition>
            </tbody>
        </>
    );
}

export default IdentityModel;
