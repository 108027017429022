import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Table, Button, Icon } from '@abb/abb-common-ux-react';
import ButtonBar from '../ButtonBar';
import './style.scss';
import { connect } from 'react-redux';
import { StoreState } from '../../../store';
import _ from 'lodash';
import { Dispatch } from 'redux';
import { IInfoModelInstance, SettingsLeftNavOptions } from '../../../store/settings/types';
import {
    getInfoModelInstancesRequest,
    getTypeDefinitionRequest,
    deleteInfoModelInstanceRequest,
    saveInfoModelInstanceRequest,
} from '../../../store/settings/action';

import Loader from '../../../components/Loader';
import Condition from '../../../components/shared/Condition';
import Flex from '../../../components/shared/Flex';
import IdentityModel from '../../../components/SupportedModel/IdentityModel';
import ConfigurationHeader from '../Header';
import { CONFIRMATION_BUTTON, CONFIRMATION_DELETE, CONFIRMATION_TITLE } from '../../../utils/constants/uiConstants';
import { hideDialog, showDialog } from '../../../store/dialog/action';
import NewMessageModal from '../../../components/MessageModal/NewMessageModal';
interface IConnectProp {
    oldConnect: any[];
    newConnect: any[];
    newDefinedType: IInfoModelInstance | undefined;
    selectedConnect: IInfoModelInstance | undefined;
}
const IdentityModelsTab = (
    props: ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
) => {
    const [showModify, updateShowModify] = useState(true);
    const [validation, updateValidation] = useState(false);
    const [validationMessage, setValidationMessage] = useState('');
    const [showConnect, updateShowConnect] = useState(false);
    const [connectItem, updateConnectItem] = useState<IConnectProp>({} as IConnectProp);

    useEffect(() => {
        if (props.identityModel.length === 0) {
            props.getInfoModelInstances();
        }
    }, []);

    const validateConnectDetails = (connectDetail: any[], type: 'UPDATE' | 'SAVE') => {
        let isValid = true;
        let message = '';
        const connectName = connectDetail.filter((item) => item.key === 'connect')[0].value;
        if (type === 'SAVE' && connectItem.newConnect.filter((x) => x.value == '').length > 0) {
            isValid = false;
            message = 'None of the fields should be empty';
        }
        if (type === 'SAVE' && connectItem.newConnect.filter((x) => x.value.length > 150).length > 0) {
            isValid = false;
            message = 'Fields can not have value more than 150 characters.';
        }
        if (type === 'UPDATE' && connectItem.oldConnect.filter((x) => x.value.length > 150).length > 0) {
            isValid = false;
            message = 'Fields can not have value more than 150 characters.';
        }
        if (type === 'UPDATE' && connectItem.oldConnect.filter((x) => x.value == '').length > 0) {
            isValid = false;
            message = 'None of the fields should be empty';
        }
        if (isValid) {
            props.identityModel.forEach((item) => {
                if (
                    (type === 'UPDATE' && !_.isEqual(item, { ...connectItem.selectedConnect! })) ||
                    type === 'SAVE'
                ) {
                    if (item.properties['connect'].value === connectName) {
                        isValid = false;
                        message = `Connect name ${connectName} already exists.`;
                    }
                }
            });
        }
        if (!isValid) {
            updateValidation(true);
            setValidationMessage(message);
        }
        return isValid;
    };
    const handleSave = useCallback(() => {
        const item = {
            ...connectItem.newDefinedType!,
        };
        if (!validateConnectDetails(connectItem.newConnect, 'SAVE')) {
            return;
        }
        item.type =
            item.typeId && item.version ? `${item.typeId}@${item.version.split('.')[0]}` : '';
        delete item['lastModified'];
        delete item.tenantId;
        delete item.version;
        delete item.typeId;
        delete item.isDeleted;
        item.properties = {};
        connectItem.newConnect.forEach((val, idx) => {
            let propValue = {
                value: val.value,
            };
            item.properties[val.key] = propValue;
        });
        props.saveInfoModelInstance(item, 'SAVE');
        updateShowModify(true);
        updateShowConnect(false);
    }, [connectItem]);
    const handleUpdate = useCallback(() => {
        const item = {
            ...connectItem.selectedConnect!,
        };
        if (!validateConnectDetails(connectItem.oldConnect, 'UPDATE')) {
            return;
        }
        delete item['lastModified'];
        delete item.tenantId;
        item.properties = {};
        connectItem.oldConnect.forEach((val, idx) => {
            let propValue = {
                value: val.value,
            };
            item.properties[val.key] = propValue;
        });
        props.saveInfoModelInstance(item, 'UPDATE');
        updateShowModify(true);
    }, [connectItem]);

    const isUpdateDisabled = useMemo(() => {
        const item = {
            ...connectItem.selectedConnect!,
        };
        let isSame = true;
        if (connectItem.oldConnect) {
            connectItem.oldConnect.forEach((val, idx) => {
                if (item.properties[val.key].value !== val.value) {
                    isSame = false;
                }
            });
        }
        return isSame;
    }, [connectItem]);

    const clearConnectData = useCallback((connectItem: IConnectProp) => {
        let updatedData =
            connectItem.newConnect &&
            connectItem.newConnect.map((item) => {
                return { key: item.key, value: '' };
            });

        return { ...connectItem, newConnect: updatedData } as IConnectProp;
    }, []);

    return (
        <div className="wrapper-connect-models">
            <ConfigurationHeader headerText={SettingsLeftNavOptions.IDENTITY} />
            <div className="connect-models-form">
                <Condition when={props.showModalProgress}>
                    <Flex fill center>
                        <Loader sizeClass="large" type="radial" />
                    </Flex>
                </Condition>
                <Condition when={!props.showModalProgress}>
                    <Table className="supportedModelTable">
                        <IdentityModel
                            newConnectData={connectItem.newConnect}
                            showModify={showModify}
                            showConnect={showConnect}
                            identityModel={props.identityModel}
                            isValid={validation}
                            newDefinedType={props.newDefinedType}
                            getNewType={props.getTypeDefinition}
                            onShowModifyUpdate={(val) => {
                                if (validation) {
                                    setValidationMessage('');
                                    updateValidation(false);
                                }
                                updateShowModify(val);
                            }}
                            updateShowConnect={(val) => {
                                if (validation) {
                                    setValidationMessage('');
                                    updateValidation(false);
                                }
                                updateShowConnect(val);
                            }}
                            deleteInfoModelInstance={(selectedConnect) => {
                                props.showConfirmationDialog(selectedConnect, connectItem)
                                updateShowModify(true);
                            }}
                            onUpdate={(
                                tableData,
                                tableDataNewConnect,
                                type,
                                newDefinedType,
                                selectedConnect
                            ) => {
                                if (validation) {
                                    setValidationMessage('');
                                    updateValidation(false);
                                }
                                if (type == 'NEW')
                                    updateConnectItem({
                                        ...connectItem,
                                        newConnect: tableDataNewConnect,
                                        newDefinedType,
                                    });
                                if (type == 'EXISTING')
                                    updateConnectItem({
                                        ...connectItem,
                                        oldConnect: tableData,
                                        selectedConnect,
                                    });
                            }}
                        />
                    </Table>
                    <Condition when={validation}>
                        <div className="validation-message">
                            <Icon
                                name="abb/error-circle-1"
                                color={'#f03040'}
                                className="circle-error-icon"
                                sizeClass="small"
                            />
                            <span>{validationMessage}</span>
                        </div>
                    </Condition>
                </Condition>
            </div>
            <ButtonBar>
                <Condition when={showModify}>
                    <Button
                        text="Modify"
                        sizeClass="medium"
                        shape="rounded"
                        type="primary-blue"
                        style={{ marginLeft: `12px` }}
                        onClick={() => updateShowModify(false)}
                        disabled={props.showModalProgress || props.identityModel.length === 0}
                    />
                </Condition>

                <Condition when={!showModify}>
                    <Button
                        text={CONFIRMATION_BUTTON.CANCEL}
                        sizeClass="medium"
                        shape="rounded"
                        type="normal"
                        className="monitor-model-button-cancel"
                        onClick={() => {
                            updateShowModify(true);
                            if (validation) {
                                setValidationMessage('');
                                updateValidation(false);
                            }
                            updateShowConnect(false);
                            updateConnectItem(clearConnectData({ ...connectItem }));
                        }}
                    />
                    <Button
                        text={showConnect ? 'Save' : 'Update'}
                        sizeClass="medium"
                        shape="rounded"
                        type="primary-blue"
                        className="customDeleteButton"
                        style={{ marginLeft: `12px` }}
                        onClick={() => {
                            if (showConnect) {
                                handleSave();
                            } else {
                                handleUpdate();
                            }
                        }}
                        disabled={props.showModalProgress || (!showConnect && isUpdateDisabled)}
                    />
                </Condition>
            </ButtonBar>
        </div>
    );
};
const mapStateToProps = (state: StoreState) => {
    return {
        identityModel: state.settings.identityModel,
        showModalProgress: state.settings.showModalProgress,
        newDefinedType: state.settings.newDefinedType,
    };
};
const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        saveInfoModelInstance: (model: IInfoModelInstance, type: string) => {
            dispatch(saveInfoModelInstanceRequest(model, type));
        },
        getInfoModelInstances: () => {
            dispatch(getInfoModelInstancesRequest());
        },
        getTypeDefinition: () => {
            dispatch(getTypeDefinitionRequest());
        },
        deleteInfoModelInstance: (objecId: string, modelId: string) => {
            dispatch(deleteInfoModelInstanceRequest(objecId, modelId));
        },
        showConfirmationDialog: (selectedConnect: any, connectItem: IConnectProp) => {
            dispatch(
                showDialog({
                    component: NewMessageModal,
                    modalTitle: CONFIRMATION_TITLE.DELETE_MODEL,
                    customClassName: 'wrapper-message-modal',
                    data: {
                        warningText: CONFIRMATION_DELETE,
                        standardButtonsOnBottom: [
                            {
                                text: CONFIRMATION_BUTTON.CANCEL,
                                type: 'discreet-black',
                                handler: (dlg: any) => {
                                    dispatch(hideDialog());
                                },
                            },
                            {
                                text: CONFIRMATION_BUTTON.CONFIRM,
                                type: 'primary-blue',
                                handler: (dlg: any) => {
                                    const item = {
                                        ...connectItem.selectedConnect!,
                                    };
                                    dispatch(deleteInfoModelInstanceRequest(selectedConnect.key, item.model));
                                    dispatch(hideDialog());
                                },
                            },
                        ],
                    },
                })
            );
        }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(IdentityModelsTab);
