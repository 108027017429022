import { ModelsPageState, Actions, IModelPermission } from "./types";
import { ActionTypes } from "../actionTypes";
import uuid from "uuid";

// import CanvasController from "../../components/Fabric";

import _ from "lodash";

// import { ComputeModelFromJson } from "../../transformers/ComputeModel/fromJson/ComputeModelFromJson";
// import { LocalJson } from "../../transformers/ComputeModel/fromJson/types";
import {
    MODEL_SAVE_TYPE,
    SAVE_OPTIONS,
} from "../../utils/constants/appConstants";
// import { updateJsonWithOriginalFunctionDetails } from "../../utils/helpers";

// const blankComputeModelFromJson = new ComputeModelFromJson();

export const initialState: ModelsPageState = {
    currentRoutePath: "",
    supportedModels: [],
    modelUsedInstances: [],
    isLoading: false,
    sidebarToggleStatus: false,
    modelsPagination: {
        activePage: 1,
        entriesPerPage: 0,
        totalPages: 0,
        totalRecords: 0,
    },
    modelPermission: {
        hasSave: true,
        hasDelete: true,
        hasEdit: true,
        hasImport: true,
        hasExport: true,
    } as IModelPermission,
    infoModelInstance: undefined,
    identityModel: [],
    newDefinedType: undefined,
    showModalProgress: false,
    saveType: "",
    updatingTypeRelatedInstances: false,
    availableTypeIds: [],
    isAuthorized:false,
};

const defaultJson = { assetData: [], connectionData: [] };

export const modelsPageReducer = (
    state = initialState,
    action: Actions
): ModelsPageState => {
    switch (action.type) {
 
        case ActionTypes.UPDATE_SUPPORTED_MODEL_DATA: {
            const supportedModels = action.payload;
            state.supportedModels = [...supportedModels];
            return { ...state };
        }
        case ActionTypes.GET_MODEL_USED_BY_INSTANCES: {
            const { pageNumber, pageSize } = action.payload;
            state.modelsPagination.activePage = pageNumber;
            state.modelsPagination.entriesPerPage = pageSize;
            return { ...state };
        }
        case ActionTypes.UPDATE_MODEL_USED_BY_INSTANCES: {
            const modelUsedInstances = action.payload || [];
            state.modelUsedInstances = [...modelUsedInstances];
            return { ...state };
        }

        case ActionTypes.HANDLE_SIDEBAR_TOGGLE: {
            const toggleStatus = action.payload;

            return {
                ...state,
                sidebarToggleStatus: toggleStatus,
            };
        }

        case ActionTypes.UPDATE_MODEL_PERMISSION: {
            return {
                ...state,
                modelPermission: { ...action.payload },
            };
        }

        case ActionTypes.UPDATE_INFO_MODEL_INSTANCE: {
            const infoModelInstance = action.payload.supportedModel;
            const identityModel = action.payload.identityModel;
            return {
                ...state,
                infoModelInstance,
                identityModel,
            };
        }
        case ActionTypes.UPDATE_NEW_DEFINITION_TYPE: {
            const newDefinedType = action.payload;
            return {
                ...state,
                newDefinedType,
            };
        }

        case ActionTypes.SHOW_PROGRESS: {
            const showModalProgress = action.payload;
            return {
                ...state,
                showModalProgress,
            };
        }

        case ActionTypes.HANDLE_LOADING_UPDATE_TYPE_RELATED_INSTANCE: {
            const updatingTypeRelatedInstances = action.payload;
            return {
                ...state,
                updatingTypeRelatedInstances,
            };
        }
        case ActionTypes.SET_AUTHORIZE: {
            const isAuthorized = action.payload;
            return {
                ...state,
                isAuthorized,
            };
        }
        case ActionTypes.UPDATE_AVAILABLE_TYPE_IDS: {
            const availableTypeIds = action.payload;
            return {
                ...state,
                availableTypeIds,
            };
        }

        default: {
            return state;
        }
    }
};

// export function updateCanvasJson(options: {
//     canvasController: CanvasController;
//     json: LocalJson;
// }) {
//     const { canvasController, json } = options;
//     canvasController.fromJSON({ json: { ...json } });
// }

// function updateCanvasMode(options: {
//   canvasController: CanvasController;
//   editMode: boolean;
// }) {
//   const { editMode, canvasController } = options;
//   if (editMode) {
//     canvasController.handleEditMode();
//   } else {
//     canvasController.handleViewMode();
//   }
// }

// function clearTypesListFilter(state: ModelsPageState) {
//     console.log("initialState :", initialState);
//     // state.objectTypeList.filters = _.cloneDeep(
//     //     initialState.objectTypeList.filters
//     // );
//     state.objectTypeList.filters = { search: "" };
//     // state.functionTypeList.filters = _.cloneDeep(
//     //     initialState.functionTypeList.filters
//     // );
//     state.functionTypeList.filters = { search: "" };

//     state.objectTypeList.filterList = filterTypesList({
//         masterList: state.objectTypeList.masterList,
//         filters: state.objectTypeList.filters,
//     });
//     state.functionTypeList.filterList = filterTypesList({
//         masterList: state.functionTypeList.masterList,
//         filters: state.functionTypeList.filters,
//     });
//     return state;
// }

// function filterTypesList(options: {
//     masterList: ModelsPageState["objectTypeList"]["masterList"];
//     filters: ModelsPageState["objectTypeList"]["filters"];
// }) {
//     const { masterList, filters } = options;
//     const { search } = filters;

//     const filterList: string[] = [];
//     console.log(typeof search, search === " ");
//     Object.keys(masterList.byId).forEach((objectId) => {
//         if (
//             masterList.byId[objectId].assetName
//                 .toLowerCase()
//                 .includes(search.toLowerCase())
//         )
//             filterList.push(objectId);
//     });

//     return filterList;
// }
