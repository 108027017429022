import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Table, Button, Icon } from '@abb/abb-common-ux-react';
import SupportedModelTable from '../../../components/SupportedModel/ConnectedModel/SupportedModelTable';
import ButtonBar from '../ButtonBar';
import './style.scss';
import { connect } from 'react-redux';
import { StoreState } from '../../../store';
import _ from 'lodash';
import { Dispatch } from 'redux';
import { IInfoModelInstance, SettingsLeftNavOptions } from '../../../store/settings/types';
import {
    saveInfoModelInstanceRequest,
    getInfoModelInstancesRequest,
} from '../../../store/settings/action';
import Loader from '../../../components/Loader';
import Condition from '../../../components/shared/Condition';
import Flex from '../../../components/shared/Flex';
import './style.scss';
import ConfigurationHeader from '../Header';
import {
    VALIDATION_MESSAGE,
    CONFIRMATION_BUTTON,
    CONFIRMATION_TITLE,
} from '../../../utils/constants/uiConstants';
import { Item } from 'rc-menu';
import MessageModal from '../../../components/MessageModal';
import { showDialog, hideDialog } from '../../../store/dialog/action';
import { showMessageModal } from '../../../store/messageModal/action';
import NewMessageModal from '../../../components/MessageModal/NewMessageModal';
import { DEFAULT_CONECT_MODELS } from '../../../utils/constants/appConstants';

export interface IConnectModelTableRow {
    modelId: string;
    modelName: string;
    errorModelId?: string;
    errorModelName?: string;
    isSelected?: boolean;
    isNew?: boolean;
}
const ConnectModelsTab = (
    props: ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
) => {
    const [tableData, updateTableData] = useState<IConnectModelTableRow[]>([]);
    const [newModelData, updateNewModelData] = useState<IConnectModelTableRow[]>([]);
    const [infoModelInstance, updateInfoModelInstance] = useState<IInfoModelInstance>(
        {} as IInfoModelInstance
    );
    const [validation, updateValidation] = useState(false);

    useEffect(() => {
        if (!props.infoModelInstance) {
            props.getInfoModelInstances();
        }
    }, []);

    useEffect(() => {
        if (props.infoModelInstance) {
            updateInfoModelInstance(props.infoModelInstance);
            if (_.has(props.infoModelInstance, 'properties.supportedModels')) {
                const { supportedModels } = props.infoModelInstance.properties;
                let supportedMap: IConnectModelTableRow[] = [];
                let originalTableData: IConnectModelTableRow[] = [];
                const newModelListObj = newModelData.reduce(
                    (obj: { [key: string]: IConnectModelTableRow }, curr) => ({
                        ...obj,
                        [curr.modelName]: curr,
                    }),
                    {}
                );

                Object.keys(supportedModels).forEach((keyVal) => {
                    supportedMap.push({
                        modelName: keyVal,
                        modelId: supportedModels[keyVal].value,
                        errorModelId: '',
                        errorModelName: '',
                        isSelected: false,
                    });
                    originalTableData.push({
                        modelName: keyVal,
                        modelId: supportedModels[keyVal].value,
                    });
                });

                let listData: IConnectModelTableRow[] = [];
                originalTableData.forEach((item) => {
                    const oldItem = newModelListObj[item.modelName];

                    listData.push({
                        modelId: oldItem ? oldItem.modelId : item.modelId,
                        modelName: oldItem ? oldItem.modelName : item.modelName,
                        isNew: false,
                        isSelected: oldItem ? oldItem.isSelected : false,
                        errorModelId: oldItem ? oldItem.errorModelId : '',
                        errorModelName: oldItem ? oldItem.errorModelName : '',
                    });
                });
                const listObj = listData.reduce(
                    (obj: { [key: string]: IConnectModelTableRow }, curr) => ({
                        ...obj,
                        [curr.modelName]: curr,
                    }),
                    {}
                );
                updateTableData(originalTableData);

                newModelData.forEach((item) => {
                    if (item.isNew && !listObj[item.modelName]) {
                        listData.push(item);
                    }
                });
                updateNewModelData(listData);
            }
        }
    }, [props.infoModelInstance]);

    const getFinalTableData = useCallback((currentTableData: IConnectModelTableRow[]) => {
        const finalTableData: IConnectModelTableRow[] = currentTableData.map((item) => {
            const finalItem: IConnectModelTableRow = {
                modelId: item.modelId,
                modelName: item.modelName,
            };
            return finalItem;
        });
        return finalTableData;
    }, []);

    const checkNewModelDataForError = useCallback(() => {
        return newModelData.some((item) => {
            if (
                (item.errorModelId && item.errorModelId.length > 0) ||
                (item.errorModelName && item.errorModelName.length > 0)
            ) {
                return true;
            } else {
                return false;
            }
        });
    }, [newModelData]);

    const isUpdateBtnDisabled = useMemo(() => {
        const hasError = checkNewModelDataForError();
        const finalTableData = getFinalTableData(newModelData);
        return _.isEqual(tableData, finalTableData) || hasError;
    }, [tableData, newModelData]);
    const isDeleteBtnDisabled = useMemo(() => {
        const hasError = checkNewModelDataForError();
        const newSelectedItems = newModelData.filter(x => x.isSelected).filter(x=> !DEFAULT_CONECT_MODELS.find(item => x.modelId == item));
        const hasSelectedItem = newSelectedItems.some((item) => {
            if (item.isSelected) {
                return true;
            } else {
                return false;
            }
        });
        return hasError || !hasSelectedItem;
    }, [tableData, newModelData]);

    const handleSelctedItem = useCallback(
        (isSelected: boolean, index) => {
            const updateData = _.cloneDeepWith(newModelData);
            updateData[index] = { ...updateData[index], isSelected: isSelected };
            updateNewModelData(updateData);
        },
        [newModelData]
    );

    const isAllSelected = useMemo(() => {
        const newSelectedItems = newModelData.filter(x=> !DEFAULT_CONECT_MODELS.find(item => x.modelId == item));
        let isNotSelected = newSelectedItems.some((item) => {
            if (!item.isSelected) {
                return true;
            } else {
                return false;
            }
        });
        if (newSelectedItems.length === 0) {
            isNotSelected = true;
        }
        return isNotSelected ? false : true;
    }, [newModelData]);

    const handleSelectAll = useCallback(
        (isSelected: boolean) => {
            const newData = newModelData.map((item) => {
                return { ...item, isSelected: isSelected };
            });
            updateNewModelData(newData);
        },
        [newModelData]
    );

    const handleCancel = useCallback(() => {
        const originalTableData: IConnectModelTableRow[] = tableData.map((item) => {
            return {
                ...item,
                errorModelName: '',
                isNew: false,
                isSelected: false,
                errorModelId: '',
            };
        });
        props.showMessageModal({
            acceptButtonTitle: CONFIRMATION_BUTTON.CONFIRM,
            rejectButtonTitle: CONFIRMATION_BUTTON.CANCEL,
            handleStandardButton: () => updateNewModelData(originalTableData),
            title: CONFIRMATION_BUTTON.DISCARD_CHANGES,
            warningText: `Unsaved changes will be lost. Are you sure you want to continue?`,
        });
    }, [tableData, updateNewModelData]);

    const handleDelete = useCallback(() => {
        const availableOldModelData = newModelData.filter((item) => !item.isSelected && !item.isNew);
        const newAddedItem = newModelData.filter((item) => item.isNew);
        const newItemAvailable = newAddedItem.filter((item) => !item.isSelected);
        const finalTableData = getFinalTableData(availableOldModelData);
        if (finalTableData.length > 0) {
            if (finalTableData.filter((x) => x.modelId == '' || x.modelName == '').length > 0) {
                updateValidation(true);
                return;
            }
            const modelNames = finalTableData.map((x) => x.modelName);
            if (modelNames.some((item, idx) => modelNames.indexOf(item) != idx)) {
                updateValidation(true);
                return;
            }
            const modelIds = finalTableData.map((x) => x.modelId);
            if (modelIds.some((item, idx) => modelIds.indexOf(item) != idx)) {
                updateValidation(true);
                return;
            }
        }
        const item = { ...infoModelInstance };
        delete item['lastModified'];
        delete item.tenantId;
        item.properties.supportedModels = {};

        DEFAULT_CONECT_MODELS.forEach(modelId => {
            if(finalTableData.findIndex(item => item.modelId == modelId) < 0){
                let defaultModel = newModelData.find(x => x.modelId == modelId);
                if(defaultModel) {
                    finalTableData.unshift({...defaultModel,isSelected: false});
                    availableOldModelData.unshift({...defaultModel,isSelected: false});
                }
            }
        });

        finalTableData.forEach((val, idx) => {
            let propValue = { value: val.modelId };
            item.properties.supportedModels[val.modelName] = propValue;
        });
        updateNewModelData([...availableOldModelData, ...newItemAvailable]);
        props.saveInfoModelInstance(item, 'UPDATE');
    }, [props.infoModelInstance, newModelData]);

    const handleUpdate = useCallback(() => {
        const finalTableData = getFinalTableData(newModelData);
        if (finalTableData.length > 0) {
            if (finalTableData.filter((x) => x.modelId == '' || x.modelName == '').length > 0) {
                updateValidation(true);
                return;
            }
            const modelNames = finalTableData.map((x) => x.modelName);
            if (modelNames.some((item, idx) => modelNames.indexOf(item) != idx)) {
                updateValidation(true);
                return;
            }
            const modelIds = finalTableData.map((x) => x.modelId);
            if (modelIds.some((item, idx) => modelIds.indexOf(item) != idx)) {
                updateValidation(true);
                return;
            }
        }
        const item = { ...infoModelInstance };
        delete item['lastModified'];
        delete item.tenantId;
        item.properties.supportedModels = {};
        finalTableData.forEach((val, idx) => {
            let propValue = { value: val.modelId };
            item.properties.supportedModels[val.modelName] = propValue;            
        });
        props.saveInfoModelInstance(item, 'UPDATE');
        updateNewModelData(finalTableData)
    }, [props.infoModelInstance, newModelData]);

    console.log(newModelData, tableData, infoModelInstance);
    return (
        <div className="wrapper-connect-models">
            <ConfigurationHeader headerText={SettingsLeftNavOptions.CONNECT_MODELS} />
            <div className="connect-models-form connect-form">
                <Condition when={props.showModalProgress}>
                    <Flex fill center>
                        <Loader sizeClass={'large'} type="radial" />
                    </Flex>
                </Condition>
                <Condition when={!props.showModalProgress}>
                    <Table className="supportedModelTable connect-models-table">
                        <SupportedModelTable
                            selectAll={isAllSelected}
                            handleSelectAll={handleSelectAll}
                            handleSelectedItem={handleSelctedItem}
                            tableData={newModelData}
                            isValid={validation}
                            onUpdate={(tableData: any) => {
                                if (validation) {
                                    updateValidation(false);
                                }
                                updateNewModelData(tableData);
                            }}
                        />
                    </Table>
                    <Condition when={tableData.length === 0}>
                        <div>No Model found!</div>
                    </Condition>

                    <div className="add-btn-wrapper">
                        <Button
                            className="add-model-btn"
                            type="discreet-black"
                            onClick={() => {
                                if (validation) {
                                    updateValidation(false);
                                }
                                updateNewModelData([
                                    ...newModelData,
                                    {
                                        modelName: '',
                                        modelId: '',
                                        errorModelId: VALIDATION_MESSAGE.EMPTY_FIELD_MESSAGE,
                                        errorModelName: VALIDATION_MESSAGE.EMPTY_FIELD_MESSAGE,
                                        isNew: true,
                                        isSelected: true,
                                    },
                                ]);
                            }}
                            sizeClass="medium"
                            text="Add Model"
                            icon="abb/plus-in-circle"
                        />
                    </div>

                    <Condition when={validation}>
                        <div className="validation-message">
                            <Icon
                                name="abb/error-circle-1"
                                color={'#f03040'}
                                className="circle-error-icon"
                                sizeClass="small"
                            />
                            <div className="message-text">
                                <span>
                                    <b>MODEL NAME</b> and <b>MODEL ID</b> should be unique and non
                                    empty fields
                                </span>
                            </div>
                        </div>
                    </Condition>
                </Condition>
            </div>
            <ButtonBar className="connect-btn-bar">
                <Button
                    text="Update"
                    sizeClass="medium"
                    shape="rounded"
                    type="primary-blue"
                    className="customDeleteButton"
                    style={{ marginLeft: `18px` }}
                    onClick={handleUpdate}
                   disabled={props.showModalProgress || isUpdateBtnDisabled}
                />
                <Button
                    text="Delete"
                    sizeClass="medium"
                    shape="rounded"
                    type="primary-blue"
                    className="customDeleteButton"
                    style={{ marginLeft: `18px` }}
                    onClick={() => {
                        const selectedModels = newModelData.filter((item) => item.isSelected);
                        let modelNames: string[] = [];
                        selectedModels.forEach((item) => {
                             if(!DEFAULT_CONECT_MODELS.includes(item.modelId)){
                                     modelNames.push(item.modelName);
                             }
                        });
                        if(modelNames.length > 0){
                            props.showMessageModal({
                                acceptButtonTitle: CONFIRMATION_BUTTON.CONFIRM,
                                rejectButtonTitle: CONFIRMATION_BUTTON.CANCEL,
                                handleStandardButton: () => handleDelete(),
                                title: CONFIRMATION_TITLE.DELETE_MODEL,
                                warningText: `Are you sure you want to delete ${modelNames.join(',')}?`,
                            });
                     }
                    }}
                    disabled={props.showModalProgress || isDeleteBtnDisabled}
                />
                <Button
                    text="Cancel"
                    sizeClass="medium"
                    shape="rounded"
                    type="normal"
                    className="customDeleteButton"
                    style={{ marginLeft: `18px` }}
                    onClick={handleCancel}
                    disabled={
                        props.showModalProgress ||
                        (isUpdateBtnDisabled && !checkNewModelDataForError())
                    }
                />
            </ButtonBar>
        </div>
    );
};
const mapStateToProps = (state: StoreState) => {
    return {
        infoModelInstance: state.settings.infoModelInstance,
        identityModel: state.settings.identityModel,
        showModalProgress: state.settings.showModalProgress,
    };
};
const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        saveInfoModelInstance: (model: IInfoModelInstance, type: string) => {
            dispatch(saveInfoModelInstanceRequest(model, type));
        },
        getInfoModelInstances: () => {
            dispatch(getInfoModelInstancesRequest());
        },
        showMessageModal: (payload: {
            title: string;
            rejectButtonTitle: string;
            acceptButtonTitle: string;
            handleStandardButton: () => void;
            selectedTab?: string;
            warningText: string;
        }) => {
            const {
                title,
                rejectButtonTitle,
                acceptButtonTitle,
                selectedTab,
                handleStandardButton,
                warningText,
            } = payload;
            const rejectButtonText = rejectButtonTitle || CONFIRMATION_BUTTON.CANCEL;
            const acceptButtonText = acceptButtonTitle || CONFIRMATION_BUTTON.CONFIRM;
            dispatch(
                showDialog({
                    component: NewMessageModal,
                    modalTitle: title || 'Discard Changes?',
                    customClassName: 'wrapper-message-modal',
                    data: {
                        selectedTab: selectedTab,
                        warningText: warningText,
                        standardButtonsOnBottom: [
                            {
                                text: rejectButtonText,
                                type: 'discreet-black',
                                handler: (dlg: any) => {
                                    dispatch(hideDialog());
                                },
                            },
                            {
                                text: acceptButtonText,
                                type: 'primary-blue',
                                handler: (dlg: any) => {
                                    handleStandardButton();
                                    dispatch(hideDialog());
                                },
                            },
                        ],
                    },
                })
            );
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ConnectModelsTab);
