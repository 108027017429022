import { ActionTypes } from "../actionTypes";
import { LoaderState } from "./types";

export const initialState = {
    computeModelSaving: { isSaving: false },
    computeModelLoading: { isLoading: false },
    computeModelDeleting: { isDeleting: false },
    paginationLoader: { isLoading: false },
    importExportModal: { isProcessing: false },
};

export const loaderReducer = (
    state: LoaderState = initialState,
    action: any
): LoaderState => {
    switch (action.type) {
        case ActionTypes.SAVE_COMPUTE_MODEL_API: {
            return {
                ...state,
                computeModelSaving: {
                    isSaving: true,
                    data: { saveOption: action.payload.saveOption },
                },
            };
        }
        case ActionTypes.SAVE_COMPUTE_MODEL_API_ERROR:
        case ActionTypes.SAVE_COMPUTE_MODEL_STORE: {
            return { ...state, computeModelSaving: { isSaving: false } };
        }
        case ActionTypes.GET_COMPUTE_MODELS_API: {
            return { ...state, computeModelLoading: { isLoading: true } };
        }
        case ActionTypes.GET_COMPUTE_MODELS_STORE: {
            return { ...state, computeModelLoading: { isLoading: false } };
        }
        case ActionTypes.DELETE_COMPUTE_MODEL_API: {
            return { ...state, computeModelLoading: { isLoading: true } };
        }

        case ActionTypes.DELETE_COMPUTE_MODEL_API_ERROR: {
            return { ...state, computeModelLoading: { isLoading: false } };
        }

        case ActionTypes.DELETE_COMPUTE_MODEL_STORE: {
            return { ...state, computeModelLoading: { isLoading: false } };
        }
        case ActionTypes.RESET_PAGINATION_LOADER: {
            return {
                ...state,
                paginationLoader: { isLoading: action.payload },
            };
        }
        case ActionTypes.TOGGLE_SPINNER_MODAL: {
            return {
                ...state,
                importExportModal: { isProcessing: action.payload },
            };
        }
        default: {
            return state;
        }
    }
};
