import { isNilOrEmpty, abbSDKGetErrorMessage } from "../../utils/helpers";
import { StoreState } from "../index";
import { takeLatest, put, select, call } from "redux-saga/effects";
import { ActionTypes } from "../actionTypes";
import {
    getAssetCategoryListActionRequest,
    getAssetCategoryListActionSuccess,
    getAssetCategoryListActionFailure,
    handleCategorySelectionAction,
    getSystemsActionRequest,
    setTreeListNodesAction,
    getEdgesActionRequest,
    geObjectBytEdgesActionRequest,
    handleTreeListNodeToggleAction,
    updateNormalizedTreeListNode,
    getStructuresActionSuccess,
    getStructuresActionRequest,
    getStructuresActionFailure,
    getSystemStructuresActionFailure,
    getSystemStructuresActionRequest,
    getSystemStructuresActionSuccess,
    getAssetInstanceListActionRequest,
    getAssetInstanceListActionSuccess,
    setSelectedTreeNodeAction,
    handleAssetInstanceActionMenuClick,
    handleAssetToggle,
    handleAssetInstanceActionMenuClickSuccess,
    handleAssetInstanceActionMenuClickFailure,
    updateSystemStructureModels,
    updateConnectDetailsFromIdentityModels,
    updateGroupBy,
    getSystemStructureModel,
    getConnectDetailsFromIdentityModel,
    getNewSystemStructuresActionRequest,
    handleNewTreeListNodeToggleAction,
    getSystemsBasedOnConnectModelRequest,
    getSystemsActionFailure,
    getNewAssetInstanceListActionRequest,
    searchAsset,
    updateAssetPermission,
    updateFilterAssetModelType,
    getAllPermisssionForAssetAction,
    handleInstanceMonitoring,
    deleteCustomVariableRequest,
    dropTreeNode,
    updateNewHierarchyNode,
    dropTreeListNodeToggleAction,
    getNewSystemStructuresDropNodeRequest,
    saveHierarchyDropNodeRequest,
    setTreeListDropNodesAction,
    addChildNodesToParentDropNodeRequest,
    removeNodeFromTreeRequest,
    setSelectedTreeNodeDropAction,
    addDefaultNewNodeTreeRequest,
    loadStructureListRequest,
    getNewSystemStructureHierarchyForTenantRequest,
    createNewSystemStructureHierarchyForTenantRequest,
    cloneNewSystemStructureHierarchyForTenantRequest,
    deleteNewSystemStructureHierarchyForTenantRequest,
    updateSystemStructureModelsForTenant,
    loadTreeHierarchyListRequest,
    loadObjectHierarchy,
} from "./actions";
import {
    getSelectedCategoryTypeInAssetSelector,
    getNormalizedTreeNodeSelector,
    selectedTreeNodeSelector,
    getSelectedAssetInstanceSelector,
    getVirtualTreeSelector,
    getVirtualTreeDropSelector,
    selectedTreeNodeSelectorDrop,
} from "./selector";
import { sce as AbbSDK } from "sce-engg-model-19.09";
import {
    mapAssetsCategoryListModel,
    mapSystemModel,
    mapStructureModel,
    mapTreeModel,
    mapStructureToTreeNode,
    removeTreeNode,
    resetNodeItem,
} from "./model";
import { normalize } from "normalizr";
import { assetTreeNodeSchema } from "../../utils/schemas";
import _ from "lodash";
import { mergeNormalizedTreeNodes } from "./utils";
import { TreeNodeType } from "./types";
import { showNotificationModal } from "../notificationModal/action";
import {
    NOTIFICATION_MODAL_STATUS,
    GROUPING_IDENTIFIER,
    NAME_LOOKUP_MODEL,
    MIN_RECORDS,
    MAX_RECORDS,
    GROUP_BY_SYSTEM,
    GROUP_BY_STRUCTURE,
    ASSET_CRITICALITY,
    DEFAULT_CONTROL_STRUCTURE,
    DEFAULT_TREE_LIST_NODE,
    DEFAULT_TREE_VIRTUAL_LIST_NODE,
    MOCK_SYSTEM_STRUCTURE,
    ACTION_TYPE,
    OPCUA_MODEL
} from "../../utils/constants/appConstants";
import { CREATE_NEW_MODEL, VIEW_BY_LIST } from "../../utils/constants/uiConstants";
import uuid from 'uuid';
import { failureResponse } from '../../services/http/response';

// This method will after removing the dependent API details as it is no longer needed
function* getAssetCategoryList() {
    try {
        // let categoryList = yield AbbSDK.getViewBy();
        const mappedCategoryList = mapAssetsCategoryListModel(VIEW_BY_LIST);
        yield put(getAssetCategoryListActionSuccess(mappedCategoryList));

        // select 0 position category
        yield put(handleCategorySelectionAction(mappedCategoryList[0]));
    } catch (err) {
        console.log(err);
        yield put(getAssetCategoryListActionFailure());
    }
}
function* handleAssetCategoryChange() {
    try {
        const store: StoreState = yield select();
        const systemCategorySelected = store.assets.systemCategorySelected;
        console.log(
            "%c systemCategorySelected ",
            "background: lime; color: black",
            systemCategorySelected
        );

        if (systemCategorySelected) {
            // make system api call
            // yield put(getSystemsActionRequest());
            // yield put(getNewSystemStructuresActionRequest(null));
        } else {
            // make edge api call
            yield put(getEdgesActionRequest());
        }
    } catch (err) {
        console.log(
            "%c err inside handleAssetCategoryChange",
            "background: lime; color: black",
            err
        );
        // handle error handling
    }
}

function* getSystemsList() {
    try {
        let systemList = yield AbbSDK.getSystems(); // fetching of asset side bar data from system list .
        console.log(
            "%c systemList ",
            "background: lime; color: black",
            systemList
        );
        if (systemList && systemList.details) {
            const rootTreeNodes = mapSystemModel(systemList.details, null);
            const normalizedNodes = normalize(systemList.details, [
                assetTreeNodeSchema,
            ]);
            const treeNodes = mapTreeModel(rootTreeNodes);
            yield put(
                setTreeListNodesAction({
                    node: rootTreeNodes,
                    normalizeNode: {
                        byId: _.get(
                            normalizedNodes,
                            "entities.assetTreeNode",
                            {}
                        ),
                        entities: _.get(normalizedNodes, "result", {}),
                    },
                    treeVirtualNodes: treeNodes,
                })
            );
        }
    } catch (err) {
        console.log(
            "%c err inside getSystemsList ",
            "background: salmon; color: black",
            err
        );
    }
}
function* getEdgesList() {
    try {
        let edgesList = yield AbbSDK.getEdges();
        console.log(
            "%c edgesList ",
            "background: lime; color: black",
            edgesList
        );
        if (edgesList && edgesList.details) {
            const rootTreeNodes = mapSystemModel(edgesList.details, null);
            const normalizedBasePlans = normalize(edgesList.details, [
                assetTreeNodeSchema,
            ]);
            const treeNodes = mapTreeModel(rootTreeNodes);
            console.log(
                "%c normalizedBasePlans ",
                "background: lime; color: black",
                normalizedBasePlans
            );
            yield put(
                setTreeListNodesAction({
                    node: rootTreeNodes,
                    normalizeNode: {
                        byId: _.get(
                            normalizedBasePlans,
                            "entities.assetTreeNode",
                            {}
                        ),
                        entities: _.get(normalizedBasePlans, "result", {}),
                    },
                    treeVirtualNodes: treeNodes,
                })
            );
        }
    } catch (err) {
        console.log(
            "%c err inside getEdgesList ",
            "background: salmon; color: black",
            err
        );
    }
}
function* getObjectByEdges(
    action: ReturnType<typeof geObjectBytEdgesActionRequest>
) {
    try {
        if (isNilOrEmpty(action.payload.objectId)) {
            return;
        }
        let edgeObjectList = yield AbbSDK.getObjectByEdge(
            action.payload.objectId
        );
        console.log(
            "%c edgeObjectList ",
            "background: lime; color: black",
            edgeObjectList
        );
    } catch (err) {
        console.log(
            "%c err inside getObjectByEdges ",
            "background: salmon; color: black",
            err
        );
    }
}

// todo
// added type
function* addChildNodesToParentNode(node: TreeNodeType, childNodes: any) {
    let normalizeTreeNodeList = yield select(getNormalizedTreeNodeSelector);
    console.log("normalizeTreeNodeList", normalizeTreeNodeList);
    console.log("node", node);
    node.loading = false;

    node.expanded =
        Array.isArray(childNodes) && !childNodes[0].isLeaf
            ? !!node.expanded
            : false;

    // set isLeafNode property here rather in tree component
    node.isLeaf = Array.isArray(childNodes) ? childNodes[0].isLeaf : false;
    node.children = !node.isLeaf ? childNodes : [];

    let mappedNodes = mapTreeModel(node);
    const treeVirtualList = yield select(getVirtualTreeSelector);
    let totalNodeList = mapStructureToTreeNode(
        treeVirtualList,
        mappedNodes.id,
        mappedNodes
    );

    const normalizedBasePlans = normalize(childNodes, [assetTreeNodeSchema]);
    console.log("normalizedBasePlans", normalizedBasePlans);

    normalizeTreeNodeList = mergeNormalizedTreeNodes(
        normalizeTreeNodeList,
        _.get(normalizedBasePlans, "entities.assetTreeNode", {}),
        _.get(normalizedBasePlans, "entities.result", [])
    );

    yield put(updateNormalizedTreeListNode(node));
}

function* getStructures(action: ReturnType<typeof getStructuresActionRequest>) {
    let node = _.get(action, "payload.data", null);
    try {
        if (node) {
            let systemStructures = yield AbbSDK.getStructures();
            console.log("node modelId :", node.modelId);
            // let systemStructures = yield AbbSDK.querySystemStructureObjects(
            //     node.modelId,
            //     node.objectId,
            //     false,
            //     false
            // );
            console.log("systemStructures -", systemStructures);
            const mappedNodes = mapStructureModel(
                systemStructures.details,
                node
            );
            console.log("mappedNodes", mappedNodes);
            yield addChildNodesToParentNode(node, mappedNodes);

            let normalizeTreeNodeList = yield select(
                getNormalizedTreeNodeSelector
            );

            yield put(
                getStructuresActionSuccess({
                    structures: systemStructures.details,
                    normalizeTreeNodeList,
                })
            );
        }
    } catch (err) {
        yield addChildNodesToParentNode(node, []);
        yield put(getStructuresActionFailure());
    }
}
function* getSystemStructures(
    action: ReturnType<typeof getSystemStructuresActionRequest>
) {
    let node = _.get(action, "payload.data", null);
    try {
        if (node) {
            console.log("node --", node);
            const systemId = node.parentId;
            const modelId = node.modelId;
            if (systemId && modelId) {
                let systemStructures = null;
                if (node.level === 3) {
                    // systemStructures = yield AbbSDK.systemStructureObjects(
                    //     node.parentId,
                    //     node.modelId
                    // );
                    console.log("node.level huuhuyguygy", node);
                    console.log("node :", node);

                    systemStructures = yield AbbSDK.querySystemStructureObjects(
                        node.modelId,
                        node.parentId,
                        false,
                        false
                    );

                    console.log("systemStructures -", systemStructures);
                } else {
                    console.log("child objects", node.level);
                    console.log("nodelink", [
                        node.link,
                        node.modelId,
                        node.structureModelId,
                    ]);

                    // systemStructures = yield AbbSDK.systemStructureChildObjects(
                    //     node.objectId,
                    //     node.modelId
                    // );
                    systemStructures = yield AbbSDK.querySystemStructureObjects(
                        node.structureModelId,
                        node.link,
                        false,
                        false
                    );

                    console.log("systemStructures -- :", systemStructures);
                }

                const mappedNodes = mapStructureModel(
                    systemStructures.details,
                    node
                );
                yield addChildNodesToParentNode(node, mappedNodes);

                let normalizeTreeNodeList = yield select(
                    getNormalizedTreeNodeSelector
                );

                yield put(
                    getSystemStructuresActionSuccess({
                        systemStructures: systemStructures.details,
                        normalizeTreeNodeList,
                    })
                );
            }
        }
    } catch (err) {
        console.log(err);
        yield addChildNodesToParentNode(node, []);
        yield put(getSystemStructuresActionFailure());
    }
}

function* handleTreeListNodeToggle(
    action: ReturnType<typeof handleTreeListNodeToggleAction>
) {
    console.log("%c node ", "background: lime; color: black", action.payload);
    try {
        const systemCategorySelected = yield select(
            getSelectedCategoryTypeInAssetSelector
        );

        // console.log("systemCategorySelected :", systemCategorySelected);
        let node = _.get(action, "payload.data", null);
        const nodeLevel = _.get(action, "payload.data.level", null);

        if (systemCategorySelected && nodeLevel && node) {
            node.toggled = !node.toggled;
            if (node.toggled) {
                node.loading = true;
            } else if (!node.toggled) {
                node.expanded = false;
            }
            yield put(updateNormalizedTreeListNode(node));

            if (nodeLevel === 2 && node.toggled) {
                yield put(getStructuresActionRequest(node));
            }
            if (nodeLevel >= 2 && node.toggled) {
                yield put(getSystemStructuresActionRequest(node));
            }
        } else {
            node.toggled = !node.toggled;
            yield put(updateNormalizedTreeListNode(node));
        }
    } catch (err) {
        console.log(
            "%c err inside getObjectByEdges ",
            "background: salmon; color: black",
            err
        );
    }
}


// Asset structure details
function* updateGroupByField(action: ReturnType<typeof updateGroupBy>) {
    console.log(
        "%c updateGroupBy ",
        "background: lime; color: black",
        action.payload
    );
    try {
        let groupBy = action.payload.data;
        if (groupBy === "Structure") {
            yield put(getSystemStructureModel());
        } else if (groupBy === "Connect") {
            yield put(getConnectDetailsFromIdentityModel());
        }
    } catch (err) {
        console.log(
            "%c err inside updateGroupBy ",
            "background: salmon; color: black",
            err
        );
    }
}

// Landing Page Old API call
function* getSystemStructureModels() {
    console.log(
        "%c getSystemStructureModels ",
        "background: lime; color: black"
    );
    try {
        yield put(loadStructureListRequest(true));
        const hasSystemStructure = yield AbbSDK.hasPermission(AbbSDK.getSystemStructureModelsForSpecificTenant);
        const response = hasSystemStructure ? yield AbbSDK.getSystemStructureModelsForSpecificTenant() : null;
        if (
            response &&
            Array.isArray(response.details) &&
            response.details.length
        ) {
            const index = response.details.findIndex(
                (x: any) => x.model === DEFAULT_CONTROL_STRUCTURE
            );
            if(index >= 0){
                response.details.unshift(
                    response.details.splice(
                        response.details.findIndex(
                            (x: any) => x.model === DEFAULT_CONTROL_STRUCTURE
                        ),
                        1
                    )[0]
                );
            }
            const store: StoreState = yield select();
            const existingStructure = store.assets.existingStructure;
            const filteredItems = response.details.filter((x: any) => x.model !== existingStructure!.model);
            yield put(updateSystemStructureModels(filteredItems));
        } else {
            yield put(updateSystemStructureModels([]));
        }
    } catch (err) {
        console.log(
            "%c err inside getSystemStructureModels ",
            "background: salmon; color: black",
            err
        );
        yield showNotificationError(err);
    }
}

function* getConnectDetailsFromIdentityModels() {
    console.log(
        "%c getConnectDetailsFromIdentityModels ",
        "background: lime; color: black"
    );
    try {
        const hasSystemStructure = yield AbbSDK.hasPermission(
            AbbSDK.getConnectDetailsFromIdentityModels
        );
        if (hasSystemStructure) {
            const response = yield AbbSDK.getConnectDetailsFromIdentityModels();
            yield put(updateConnectDetailsFromIdentityModels(response.details));
        } else {
            yield put(updateConnectDetailsFromIdentityModels([]));
        }
    } catch (err) {
        console.log(
            "%c err inside getConnectDetailsFromIdentityModels ",
            "background: salmon; color: black",
            err
        );
        yield showNotificationError(err);
    }
}

// TO DO - fetch new system list based on 800xA/OPC UA
function* getSystemsBasedOnConnectModels(
    action: ReturnType<typeof getSystemsBasedOnConnectModelRequest>
) {
    let node = _.get(action, "payload.data", null);
    try {
        let systems = null;
        const store: StoreState = yield select();
        const assetStructureDetails = store.assets.assetStructureDetails;
        if (!node) {
            const hasSystemAccess = yield AbbSDK.hasPermission(
                AbbSDK.getSystemsBasedOnConnectModel
            );
            if (hasSystemAccess) {
                systems = yield AbbSDK.getSystemsBasedOnConnectModel(
                    assetStructureDetails.selectedSystem
                        ? assetStructureDetails.selectedSystem.baseModel
                        : "abb.controlSystem.system"
                );
                const rootTreeNodes = mapSystemModel(systems.details, null);
                const normalizedNodes = normalize(systems.details, [
                    assetTreeNodeSchema,
                ]);
                const treeNodes = mapTreeModel(rootTreeNodes);
                yield put(
                    setTreeListNodesAction({
                        node: rootTreeNodes,
                        normalizeNode: {
                            byId: _.get(
                                normalizedNodes,
                                "entities.assetTreeNode",
                                {}
                            ),
                            entities: _.get(normalizedNodes, "result", {}),
                        },
                        treeVirtualNodes: treeNodes,
                    })
                );
            }
            console.log("hasSystemAccess -", hasSystemAccess);
            console.log("systems -", systems);
        } else {
            // New browsing API changes to get strcture level data
            if (node && node.level == 2 && assetStructureDetails.selectedSystem!.nameLookupModel.toLowerCase() !== OPCUA_MODEL) {
                systems = yield AbbSDK.getSystemStructureModelNamesAndIdsForTenantExt2(
                    node.objectId,
                    assetStructureDetails.selectedSystem!.groupBy
                );
                const mappedNodes = mapStructureModel(systems.details, node);
                yield addChildNodesToParentNode(node, mappedNodes);
            } else {
                let link = "";
                // OPCUA hard coded as of now..will change in future once API support
                if (node.level == 2 && assetStructureDetails.selectedSystem!.nameLookupModel.toLowerCase() === OPCUA_MODEL) {
                    link = `/${node.objectId}/objects`;
                } else if (node.level == 3 && assetStructureDetails.selectedSystem!.nameLookupModel.toLowerCase() === OPCUA_MODEL) {
                    link = node.link;
                } else {
                    // Need to check for child node permission
                    link =
                        node.level == 3
                            ? `${node.modelId}/${node.parentId}`
                            : `${node.link}`;
                }
                systems = yield AbbSDK.getChildObjectsST(
                    link,
                    GROUP_BY_SYSTEM,
                    assetStructureDetails.selectedSystem!.nameLookupModel,
                    assetStructureDetails.selectedSystem!.groupBy,
                    false,
                    false,
                    MIN_RECORDS,
                    MAX_RECORDS
                );
                const mappedNodes = mapStructureModel(
                    systems.details.data,
                    node
                );
                yield addChildNodesToParentNode(node, mappedNodes);
            }
        }

        let normalizeTreeNodeList = yield select(getNormalizedTreeNodeSelector);
        if (systems) {
            yield put(
                getSystemStructuresActionSuccess({
                    systemStructures: systems.details,
                    normalizeTreeNodeList,
                })
            );
        }

        // yield put(searchAsset(""));
    } catch (err) {
        console.log(
            "%c err inside getSystemsBasedOnConnectModels ",
            "background: salmon; color: black",
            err
        );
        const rootTreeNodes = mapSystemModel([], null);
        const normalizedNodes = normalize([], [assetTreeNodeSchema]);
        const treeNodes = mapTreeModel(rootTreeNodes);
        yield put(
            setTreeListNodesAction({
                node: rootTreeNodes,
                normalizeNode: {
                    byId: _.get(normalizedNodes, "entities.assetTreeNode", {}),
                    entities: _.get(normalizedNodes, "result", {}),
                },
                treeVirtualNodes: treeNodes,
            })
        );
        yield put(getSystemsActionFailure());
        yield showNotificationError(err);
    }
}

function* getNewSystemStructures(
    action: ReturnType<typeof getNewSystemStructuresActionRequest>
) {
    let node = _.get(action, "payload.data", null);
    try {
        let systemStructures = null;
        const store: StoreState = yield select();
        const assetStructureDetails = store.assets.assetStructureDetails;
        if (!node) {
            const hasSystemStructureAccess = yield AbbSDK.hasPermission(
                AbbSDK.getChildObjectsST
            );
            if (hasSystemStructureAccess) {
                systemStructures = yield AbbSDK.getChildObjectsST(
                    assetStructureDetails.selectedStructure
                        ? assetStructureDetails.selectedStructure.model
                        : DEFAULT_CONTROL_STRUCTURE,
                    GROUP_BY_STRUCTURE,
                    "",
                    "",
                    false,
                    false,
                    MIN_RECORDS,
                    MAX_RECORDS
                );
                const count = _.get(systemStructures, "details.count", 0);
                if (count !== 0) {
                    const rootTreeNodes = mapSystemModel(
                        systemStructures.details.data,
                        null
                    );
                    const normalizedNodes = normalize(
                        systemStructures.details.data,
                        [assetTreeNodeSchema]
                    );
                    const treeNodes = mapTreeModel(rootTreeNodes);
                    yield put(
                        setTreeListNodesAction({
                            node: rootTreeNodes,
                            normalizeNode: {
                                byId: _.get(
                                    normalizedNodes,
                                    "entities.assetTreeNode",
                                    {}
                                ),
                                entities: _.get(normalizedNodes, "result", {}),
                            },
                            treeVirtualNodes: treeNodes,
                        })
                    );
                }
                if (count === 0) {
                    yield put(
                        setTreeListNodesAction({
                            node: _.cloneDeepWith(DEFAULT_TREE_LIST_NODE),
                            normalizeNode: {
                                byId: {},
                                entities: [],
                            },
                            treeVirtualNodes: _.cloneDeepWith(
                                DEFAULT_TREE_VIRTUAL_LIST_NODE
                            ),
                        })
                    );
                }
                console.log(
                    "hasSystemStructureAccess -",
                    hasSystemStructureAccess
                );
                console.log("systemStructures -", systemStructures);
                console.log("systemStructures -", systemStructures);
            }
        } else {
            // Need to check for child node permission
            let link = `${assetStructureDetails.selectedStructure
                ? assetStructureDetails.selectedStructure.model
                : DEFAULT_CONTROL_STRUCTURE
                }/${node.objectId}/objects`;
            systemStructures = yield AbbSDK.getChildObjectsST(
                link,
                GROUP_BY_STRUCTURE,
                "",
                "",
                false,
                false,
                MIN_RECORDS,
                MAX_RECORDS
            );
            const mappedNodes = mapStructureModel(
                systemStructures.details.data,
                node
            );
            yield addChildNodesToParentNode(node, mappedNodes);
        }

        let normalizeTreeNodeList = yield select(getNormalizedTreeNodeSelector);

        if (systemStructures) {
            yield put(
                getSystemStructuresActionSuccess({
                    systemStructures: systemStructures.details.data,
                    normalizeTreeNodeList,
                })
            );
        }
        // yield put(searchAsset(""));
    } catch (err) {
        console.log(err);
        const rootTreeNodes = mapSystemModel([], null);
        const normalizedNodes = normalize([], [assetTreeNodeSchema]);
        const treeNodes = mapTreeModel(rootTreeNodes);
        yield put(
            setTreeListNodesAction({
                node: rootTreeNodes,
                normalizeNode: {
                    byId: _.get(normalizedNodes, "entities.assetTreeNode", {}),
                    entities: _.get(normalizedNodes, "result", {}),
                },
                treeVirtualNodes: treeNodes,
            })
        );
        yield put(getSystemStructuresActionFailure());
        yield showNotificationError(err);
    }
}

function* handleNewTreeListNodeToggle(
    action: ReturnType<typeof handleNewTreeListNodeToggleAction>
) {
    console.log("%c node ", "background: lime; color: black", action.payload);
    try {
        let node = _.get(action, "payload.data", null);
        if (node.toggled) {
            node.loading = true;
        }
        if (node && node.toggled) {
            const store: StoreState = yield select();
            const assetStructureDetails = store.assets.assetStructureDetails;
            yield put(updateNormalizedTreeListNode(node));
            if (
                assetStructureDetails &&
                assetStructureDetails.selectedGroupBy === "Connect"
            )
                yield put(getSystemsBasedOnConnectModelRequest(node));
            if (
                assetStructureDetails &&
                assetStructureDetails.selectedGroupBy === "Structure"
            )
                yield put(getNewSystemStructuresActionRequest(node));
        } else {
            // node.toggled = !node.toggled;
            yield put(updateNormalizedTreeListNode(node));
        }
    } catch (err) {
        yield showNotificationError(err);
    }
}

function* getNewAssetInstanceList(
    action: ReturnType<typeof getNewAssetInstanceListActionRequest>
) {
    // To Do - Fetch Asset Instance List
    const { node, type } = action.payload;
    if (type == "EXISTING") {
        // Fix for Tree Node Selection
        const previousSelectedTreeNode = yield select(
            selectedTreeNodeSelector
        );
        if (previousSelectedTreeNode) {
            previousSelectedTreeNode.active = false;
            yield put(
                updateNormalizedTreeListNode(previousSelectedTreeNode)
            );
        }
        if (node) {
            node.active = true;
        }
        yield put(updateNormalizedTreeListNode(node));
        yield put(setSelectedTreeNodeAction(node));
    }
    if (type == "NEW") {
        const previousSelectedTreeNode = yield select(
            selectedTreeNodeSelectorDrop
        );
        if (previousSelectedTreeNode) {
            previousSelectedTreeNode.active = false;
            yield put(
                updateNormalizedTreeListNode(previousSelectedTreeNode)
            );
        }
        // if (node) {
        //     node.active = true;
        // }
        yield put(updateNormalizedTreeListNode(node));
        yield put(setSelectedTreeNodeDropAction(node));
    }
}
// Filter Query
function* getFilterAssetModelType() {
    console.log(
        "%c getFilterAssetModelType ",
        "background: lime; color: black"
    );
    try {
        const { assets }: StoreState = yield select();
        const assetTypes = yield AbbSDK.getAssetTypes();
        const assetModelTypes = assets.selectedParentTreeNodeId
            ? yield AbbSDK.getAssetModelTypes(assets.selectedParentTreeNodeId)
            : { details: [] };
        const data = {
            assetModelTypes: assetModelTypes.details,
            assetTypes: assetTypes.details,
        };
        yield put(updateFilterAssetModelType(data));
    } catch (err) {
        console.log(
            "%c err inside getFilterAssetModelType ",
            "background: salmon; color: black",
            err
        );
        yield showNotificationError(err);
    }
}

function* showNotificationError(err: any) {
    const error = abbSDKGetErrorMessage(err);
    yield put(
        showNotificationModal({
            title: "Error",
            details: [error],
            resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
            type: "confirmation",
        })
    );
}

function* showNotificationFormattedError(err: any) {
    let error = abbSDKGetErrorMessage(err);
    let item = "";
    try {
        item = JSON.parse(error);
    } catch { }
    const message = _.get(item, "groupJobResults[0].description", error);
    yield put(
        showNotificationModal({
            title: "Error",
            details: [message],
            resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
            type: "confirmation",
        })
    );
}

// get All Permissions for Asset
function* getAllPermisssionForAsset(
    action: ReturnType<typeof getAllPermisssionForAssetAction>
) {
    try {
        const hasMonitorInstance = true;
        const hasDeleteInstance = yield AbbSDK.hasPermission(
            AbbSDK.deleteMultipleInstances
        );
        const hasEnableInstance = yield AbbSDK.hasPermission(
            AbbSDK.enableSingleOrMultipleMonitoringInstances
        );
        const hasDisableInstance = yield AbbSDK.hasPermission(
            AbbSDK.disableSingleOrMultipleMonitoringInstances
        );
        yield put(
            updateAssetPermission({
                hasMonitorInstance,
                hasDeleteInstance,
                hasEnableInstance,
                hasDisableInstance,
            })
        );
    } catch (error) {
        yield put(
            showNotificationModal({
                title: "API error",
                resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                type: "confirmation",
                details: [abbSDKGetErrorMessage(error)],
            })
        );
    }
}

// Custom Variable Delete
function* deleteCustomVariable(
    action: ReturnType<typeof deleteCustomVariableRequest>
) {
    try {
        const { objectId, modelId } = action.payload;
        const response = yield AbbSDK.deleteObject(objectId, modelId);
        yield put(
            showNotificationModal({
                title: "Item Deleted",
                resultStatus: NOTIFICATION_MODAL_STATUS.SUCCESS,
                type: "banner",
            })
        );
    } catch (error) {
        yield put(
            showNotificationModal({
                title: "API error",
                resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                type: "confirmation",
                details: [abbSDKGetErrorMessage(error)],
            })
        );
    }
}

// Object Hierachy Tool Changes
function* dropTreeNodeRequest(action: ReturnType<typeof dropTreeNode>) {
    console.log(
        "%c dropTreeNodeRequest ",
        "background: lime; color: black"
    );
    try {
        const droppedNode = action.payload.data;
        const store: StoreState = yield select();
        const existingStructure = store.assets.existingStructure;
        const selectedTreeStructureModelId = store.assets.assetStructureDetails.selectedStructure!.model;
        const treeData = store.assets.treeNewVirtualList;
        const treeNode = mapTreeModel(droppedNode);

        yield put(loadTreeHierarchyListRequest(true));
        const response = yield AbbSDK.addStrucutreNode("", droppedNode.objectId!, existingStructure!.model, droppedNode.name, selectedTreeStructureModelId ? selectedTreeStructureModelId : "");
        if (response) {
            yield put(
                showNotificationModal({
                    title: "Root Node Added Successfully",
                    resultStatus: NOTIFICATION_MODAL_STATUS.SUCCESS,
                    type: "banner",
                })
            );
            yield put(loadTreeHierarchyListRequest(false));
            if (treeData.children && Array.isArray(treeData.children))
                treeData.children.push(treeNode)

            yield put(updateNewHierarchyNode(treeData));
        }
    } catch (err) {
        yield put(loadTreeHierarchyListRequest(false));
        console.log(
            "%c err inside dropTreeNodeRequest ",
            "background: salmon; color: black",
            err
        );
        yield showNotificationFormattedError(err);
    }
}

function* dropTreeListNodeToggle(
    action: ReturnType<typeof dropTreeListNodeToggleAction>
) {
    console.log("%c node ", "background: lime; color: black", action.payload);
    try {
        let node = _.get(action, "payload.data", null);
        // if (node.toggled) {
        //     node.loading = true;
        // }
        // yield put(updateNormalizedTreeListNode(node));
        if (node && node.toggled) {
            yield put(getNewSystemStructuresDropNodeRequest(node));
        } else {
            // node.toggled = !node.toggled;
            const treeVirtualList = yield select(getVirtualTreeDropSelector);
            let totalNodeList = resetNodeItem(
                treeVirtualList,
                node.objectId
            );
            yield put(updateNormalizedTreeListNode(node));
        }
    } catch (err) {
        yield showNotificationError(err);
    }
}

function* getNewSystemStructuresDropNode(
    action: ReturnType<typeof getNewSystemStructuresDropNodeRequest>
) {
    let node = _.get(action, "payload.data", null);
    yield put(loadTreeHierarchyListRequest(true));
    try {
        let systemStructures = null;
        const store: StoreState = yield select();
        const existingStructure = store.assets.existingStructure;
        if (!node && existingStructure) {
            systemStructures = yield AbbSDK.getChildObjectsST(
                existingStructure!.model,
                GROUP_BY_STRUCTURE,
                "",
                "",
                false,
                false,
                MIN_RECORDS,
                MAX_RECORDS
            );
            const count = _.get(systemStructures, "details.count", 0);
            if (count !== 0) {
                const rootTreeNodes = mapSystemModel(
                    systemStructures.details.data,
                    null
                );
                const normalizedNodes = normalize(
                    systemStructures.details.data,
                    [assetTreeNodeSchema]
                );
                const treeNodes = mapTreeModel(rootTreeNodes);
                yield put(
                    setTreeListDropNodesAction({
                        // node: rootTreeNodes,
                        // normalizeNode: {
                        //     byId: _.get(
                        //         normalizedNodes,
                        //         "entities.assetTreeNode",
                        //         {}
                        //     ),
                        //     entities: _.get(normalizedNodes, "result", {}),
                        // },
                        treeVirtualNodes: treeNodes,
                    })
                );
            }
            if (count === 0) {
                yield put(loadTreeHierarchyListRequest(true));
                const response = yield AbbSDK.addStrucutreNode("", uuid(), existingStructure!.model, "Empty Folder", "");
                const result = _.get(response, "details.groupJobResults[0].result");
                if (result) {
                    yield put(
                        showNotificationModal({
                            title: "Root Node Added Successfully",
                            resultStatus: NOTIFICATION_MODAL_STATUS.SUCCESS,
                            type: "banner",
                        })
                    );
                }
                yield put(loadTreeHierarchyListRequest(false));
                yield put(getNewSystemStructuresDropNodeRequest(null));
            }
        } else if (existingStructure) {
            // Need to check for child node permission
            let link = `${existingStructure!.model
                }/${node.objectId}/objects`;
            systemStructures = yield AbbSDK.getChildObjectsST(
                link,
                GROUP_BY_STRUCTURE,
                "",
                "",
                false,
                false,
                MIN_RECORDS,
                MAX_RECORDS
            );
            const mappedNodes = mapStructureModel(
                systemStructures.details.data,
                node
            );
            yield addChildNodesToParentDropNode(node, mappedNodes);

            // let normalizeTreeNodeList = yield select(getNormalizedTreeNodeSelector);

            // if (systemStructures) {
            //     yield put(
            //         getSystemStructuresActionSuccess({
            //             systemStructures: systemStructures.details.data,
            //             normalizeTreeNodeList,
            //         })
            //     );
            // }
        }
        yield put(loadTreeHierarchyListRequest(false));
    } catch (err) {
        yield put(loadTreeHierarchyListRequest(false));
        console.log(err);
        yield put(getSystemStructuresActionFailure());
        yield showNotificationFormattedError(err);
    }
}

function* addChildNodesToParentDropNode(node: TreeNodeType, childNodes: any) {
    // let normalizeTreeNodeList = yield select(getNormalizedTreeNodeSelector);
    node.loading = false;
    node.expanded =
        Array.isArray(childNodes) && !childNodes[0].isLeaf
            ? !!node.expanded
            : false;

    // set isLeafNode property here rather in tree component
    node.isLeaf = Array.isArray(childNodes) ? childNodes[0].isLeaf : false;
    node.children = !node.isLeaf ? childNodes : [];

    let mappedNodes = mapTreeModel(node);
    const treeVirtualList = yield select(getVirtualTreeDropSelector);
    let totalNodeList = mapStructureToTreeNode(
        treeVirtualList,
        mappedNodes.id,
        mappedNodes
    );

    // const normalizedBasePlans = normalize(childNodes, [assetTreeNodeSchema]);
    // console.log("normalizedBasePlans", normalizedBasePlans);

    // normalizeTreeNodeList = mergeNormalizedTreeNodes(
    //     normalizeTreeNodeList,
    //     _.get(normalizedBasePlans, "entities.assetTreeNode", {}),
    //     _.get(normalizedBasePlans, "entities.result", [])
    // );

    yield put(updateNormalizedTreeListNode(node));
}

function* addChildNodesToParentDNR(action: ReturnType<typeof addChildNodesToParentDropNodeRequest>) {
    const { node, childNodes, name, actionType } = action.payload;
    const store: StoreState = yield select();
    const existingStructure = store.assets.existingStructure;
    try {
        // Root Node Add
        if (actionType == ACTION_TYPE.ROOT) {
            yield put(loadTreeHierarchyListRequest(true));
            const response = yield AbbSDK.addStrucutreNode("", uuid(), existingStructure!.model, name, "");
            const result = _.get(response, "details.groupJobResults[0].result");
            if (result) {
                yield put(
                    showNotificationModal({
                        title: "Root Node Added Successfully",
                        resultStatus: NOTIFICATION_MODAL_STATUS.SUCCESS,
                        type: "banner",
                    })
                );
            }
            yield put(loadTreeHierarchyListRequest(false));
            yield put(getNewSystemStructuresDropNodeRequest(null));
        } else if (actionType == ACTION_TYPE.ADD || actionType == "") {
            const addNodeDetails = Array.isArray(childNodes) && childNodes.length > 0 ? childNodes[childNodes.length - 1] : null;
            const selectedTreeStructureModelId = store.assets.assetStructureDetails.selectedStructure!.model;
            yield put(loadTreeHierarchyListRequest(true));
            if (addNodeDetails) {
                const response = yield AbbSDK.addStrucutreNode(node.objectId!, addNodeDetails.id, existingStructure!.model, addNodeDetails.name, actionType == "" ? selectedTreeStructureModelId : "");
                const result = _.get(response, "details.groupJobResults[0].result");
                if (result) {
                    yield put(
                        showNotificationModal({
                            title: "Node Added Successfully",
                            resultStatus: NOTIFICATION_MODAL_STATUS.SUCCESS,
                            type: "banner",
                        })
                    );

                    if (!node.toggled) {
                        yield put(loadTreeHierarchyListRequest(false));
                        node.loading = false;
                        node.expanded = true;
                        node.toggled = true;
                        yield put(getNewSystemStructuresDropNodeRequest(node));
                    } else {
                        // // set isLeafNode property here rather in tree component
                        // node.isLeaf = Array.isArray(childNodes) ? childNodes[0].isLeaf : false;
                        // node.children = !node.isLeaf ? childNodes : [];
                        node.loading = false;
                        let mappedNodes = mapTreeModel(node);
                        const cNodes = _.orderBy(childNodes, ['name'], ['asc']);
                        mappedNodes.children = cNodes;
                        const treeVirtualList = yield select(getVirtualTreeDropSelector);
                        let totalNodeList = mapStructureToTreeNode(
                            treeVirtualList,
                            mappedNodes.id,
                            mappedNodes
                        );
                        yield put(updateNormalizedTreeListNode(node));

                    }

                }
            }
            yield put(loadTreeHierarchyListRequest(false));
        } else if (actionType == ACTION_TYPE.EDIT) {
            yield put(loadTreeHierarchyListRequest(true));
            const response = yield AbbSDK.editStrucutreNodeName(node.name, node.objectId!, existingStructure!.model);
            const result = _.get(response, "details.groupJobResults[0].result");
            if (result) {
                yield put(
                    showNotificationModal({
                        title: "Node Edited Successfully",
                        resultStatus: NOTIFICATION_MODAL_STATUS.SUCCESS,
                        type: "banner",
                    })
                );
                let mappedNodes = mapTreeModel(node);
                mappedNodes.children = childNodes;
                const treeVirtualList = yield select(getVirtualTreeDropSelector);
                let totalNodeList = mapStructureToTreeNode(
                    treeVirtualList,
                    mappedNodes.id,
                    mappedNodes
                );
                yield put(updateNormalizedTreeListNode(node));

            }
            yield put(loadTreeHierarchyListRequest(false));
        }
    }
    catch (err) {
        yield put(loadTreeHierarchyListRequest(false));
        yield showNotificationFormattedError(err);
        console.log(err);
    }
}

function* removeNodeFromTree(action: ReturnType<typeof removeNodeFromTreeRequest>) {
    try {
        const { nodeId } = action.payload;
        yield put(loadTreeHierarchyListRequest(true));
        const store: StoreState = yield select();
        const existingStructure = store.assets.existingStructure;
        const response = yield AbbSDK.deleteStrucutreNode(existingStructure!.model, nodeId);
        const result = _.get(response, "details.groupJobResults[0].result");
        if (result) {
            yield put(
                showNotificationModal({
                    title: "Node Deleted Successfully",
                    resultStatus: NOTIFICATION_MODAL_STATUS.SUCCESS,
                    type: "banner",
                })
            );
            const treeVirtualList = yield select(getVirtualTreeDropSelector);
            let treeNodes = removeTreeNode(treeVirtualList, nodeId);
            yield put(
                setTreeListDropNodesAction({
                    // node: rootTreeNodes,
                    // normalizeNode: {
                    //     byId: _.get(
                    //         normalizedNodes,
                    //         "entities.assetTreeNode",
                    //         {}
                    //     ),
                    //     entities: _.get(normalizedNodes, "result", {}),
                    // },
                    treeVirtualNodes: treeNodes,
                })
            );
        }
        yield put(loadTreeHierarchyListRequest(false));
    }
    catch (err) {
        yield put(loadTreeHierarchyListRequest(false));
        console.log(
            "%c err inside getSystemStructureModels ",
            "background: salmon; color: black",
            err
        );
        yield showNotificationFormattedError(err);
    }
}

function* saveHierarchyDropNode(action: ReturnType<typeof saveHierarchyDropNodeRequest>) {
    const data = action.payload.data;
    yield put(
        showNotificationModal({
            title: "New Object Hierarchy Saved",
            resultStatus: NOTIFICATION_MODAL_STATUS.SUCCESS,
            type: "banner",
        })
    );
}


// New API Integration
function* getNewSystemStructureHierarchyForTenant(action: ReturnType<typeof getNewSystemStructureHierarchyForTenantRequest>) {
    console.log(
        "%c getNewSystemStructureHierarchyForTenant ",
        "background: lime; color: black"
    );
    try {
        yield put(loadStructureListRequest(true));
        // const response = yield AbbSDK.getSystemStructureModels();
        const res = yield AbbSDK.getSupportedModels(true);
        const response = yield AbbSDK.getSystemStructureModelNamesAndIdsForTenantExt();
        yield put(updateSystemStructureModelsForTenant(response.details));
        yield put(loadStructureListRequest(false));
        yield put(loadObjectHierarchy(false));
    } catch (err) {
        yield put(loadStructureListRequest(false));
        console.log(
            "%c err inside getSystemStructureModels ",
            "background: salmon; color: black",
            err
        );
        yield showNotificationFormattedError(err);
    }
}

function* createNewSystemStructureHierarchyForTenant(action: ReturnType<typeof createNewSystemStructureHierarchyForTenantRequest>) {
    try {
        yield put(loadStructureListRequest(true));
        const { name, modelId } = action.payload;
        const response = modelId ? yield AbbSDK.editStrucutreModel(name, modelId) : yield AbbSDK.createStrucutreModel(name, modelId);
        yield put(loadStructureListRequest(false));
        const result = _.get(response, "details.groupJobResults[0].result");
        if (result) {
            yield put(
                showNotificationModal({
                    title: modelId ? "Structure Edited Successfully" : "Structure Created Successfully",
                    resultStatus: NOTIFICATION_MODAL_STATUS.SUCCESS,
                    type: "banner",
                })
            );
            yield put(getNewSystemStructureHierarchyForTenantRequest())
        }
    } catch (err) {
        yield put(loadStructureListRequest(false));
        console.log(
            "%c err inside createNewSystemStructureHierarchyForTenant ",
            "background: salmon; color: black",
            err
        );
        yield showNotificationFormattedError(err);
    }
}


function* cloneNewSystemStructureHierarchyForTenant(action: ReturnType<typeof cloneNewSystemStructureHierarchyForTenantRequest>) {
    try {
        yield put(loadStructureListRequest(true));
        const { cloneStructureName, clonedStructureModelId, originSouceId } = action.payload;
        const response = yield AbbSDK.cloneStrucutreModel(cloneStructureName, clonedStructureModelId, originSouceId);
        yield put(loadStructureListRequest(false));
        const result = _.get(response, "details.groupJobResults[0].result");
        if (result) {
            yield put(
                showNotificationModal({
                    title: "Structure Cloned Successfully",
                    resultStatus: NOTIFICATION_MODAL_STATUS.SUCCESS,
                    type: "banner",
                })
            );
            yield put(getNewSystemStructureHierarchyForTenantRequest())
        }
    } catch (err) {
        yield put(loadStructureListRequest(false));
        console.log(
            "%c err inside cloneNewSystemStructureHierarchyForTenant ",
            "background: salmon; color: black",
            err
        );
        yield showNotificationFormattedError(err);
    }
}

function* deleteNewSystemStructureHierarchyForTenant(action: ReturnType<typeof deleteNewSystemStructureHierarchyForTenantRequest>) {
    console.log(
        "%c deleteNewSystemStructureHierarchyForTenant ",
        "background: lime; color: black"
    );
    try {
        yield put(loadStructureListRequest(true));
        const modelId = action.payload;
        const response = yield AbbSDK.deleteStrucutreModel(modelId);
        yield put(loadStructureListRequest(false));
        const result = _.get(response, "details.groupJobResults[0].result");
        if (result) {
            yield put(
                showNotificationModal({
                    title: "Structure Deleted Successfully",
                    resultStatus: NOTIFICATION_MODAL_STATUS.SUCCESS,
                    type: "banner",
                })
            );
            yield put(getNewSystemStructureHierarchyForTenantRequest())
        }
    } catch (err) {
        yield put(loadStructureListRequest(false));
        console.log(
            "%c err inside deleteNewSystemStructureHierarchyForTenant ",
            "background: salmon; color: black",
            err
        );
        yield showNotificationFormattedError(err);
    }
}

export default [
    takeLatest(ActionTypes.ASSET_CATEGORY_LIST_REQUEST, getAssetCategoryList),
    takeLatest(ActionTypes.ASSET_CATEGORY_SELECTION, handleAssetCategoryChange),
    takeLatest(ActionTypes.GET_SYSTEMS_REQUEST, getSystemsList),
    takeLatest(ActionTypes.GET_EDGES_REQUEST, getEdgesList),
    takeLatest(ActionTypes.GET_OBJECT_BY_EDGES_REQUEST, getObjectByEdges),
    takeLatest(ActionTypes.GET_STRUCTURES_REQUEST, getStructures),
    takeLatest(ActionTypes.GET_SYSTEM_STRUCTURES_REQUEST, getSystemStructures),
    takeLatest(
        ActionTypes.HANDLE_TREE_LIST_NODE_TOGGLE,
        handleTreeListNodeToggle
    ),
    takeLatest(
        ActionTypes.GET_SYSTEM_STRUCTURE_MODELS,
        getSystemStructureModels
    ),
    takeLatest(
        ActionTypes.GET_CONNECT_DETAILS_FROM_IDENTITY_MODELS,
        getConnectDetailsFromIdentityModels
    ),
    takeLatest(
        ActionTypes.GET_SYSTEMS_BASED_ON_CONNECT_MODEL,
        getSystemsBasedOnConnectModels
    ),
    takeLatest(
        ActionTypes.GET_NEW_SYSTEM_STRUCTURES_REQUEST,
        getNewSystemStructures
    ),
    takeLatest(
        ActionTypes.HANDLE_NEW_TREE_LIST_NODE_TOGGLE,
        handleNewTreeListNodeToggle
    ),
    takeLatest(ActionTypes.GET_ALL_PERMISSION_ASSET, getAllPermisssionForAsset),
    takeLatest(
        ActionTypes.GET_FILTER_ASSET_MODEL_TYPE_REQUEST,
        getFilterAssetModelType
    ),
    takeLatest(
        ActionTypes.GET_NEW_ASSET_INSTANCE_LIST_REQUEST,
        getNewAssetInstanceList
    ),
    takeLatest(ActionTypes.CUSTOM_VARIABLE_DELETE, deleteCustomVariable),
    takeLatest(ActionTypes.DROP_TREE_NODE, dropTreeNodeRequest),
    takeLatest(ActionTypes.DROP_TREELIST_NODE_TOGGLE_ACTION, dropTreeListNodeToggle),
    takeLatest(ActionTypes.GET_NEW_SYSTEM_STRUCTURE_DROP_NODE_REQUEST, getNewSystemStructuresDropNode),
    takeLatest(ActionTypes.SAVE_HIERARCHY_DROP_NODE_REQUEST, saveHierarchyDropNode),
    takeLatest(ActionTypes.ADD_CHILD_NODES_TO_PARENT_DROP_NODE_REQUEST, addChildNodesToParentDNR),
    takeLatest(ActionTypes.REMOVE_NODE_FROM_TREE, removeNodeFromTree),

    takeLatest(ActionTypes.GET_NEW_SYSTEM_STRUCTURE_HIERARCHY_FOR_TENANT, getNewSystemStructureHierarchyForTenant),
    takeLatest(ActionTypes.CREATE_NEW_SYSTEM_STRUCTURE_HIERARCHY_FOR_TENANT, createNewSystemStructureHierarchyForTenant),
    takeLatest(ActionTypes.CLONE_NEW_SYSTEM_STRUCTURE_HIERARCHY_FOR_TENANT, cloneNewSystemStructureHierarchyForTenant),
    takeLatest(ActionTypes.DELETE_NEW_SYSTEM_STRUCTURE_HIERARCHY_FOR_TENANT, deleteNewSystemStructureHierarchyForTenant),
];
