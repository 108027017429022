import React, { ReactNode, useState, useEffect } from "react";
// import Home from "./Home";
import Login from "./Login";
import Assets from "./Assets";
// import Function from "./Functions";
import history from "../store/history";
import Loader from "../components/Loader";
import Header from "../components/Header";
import Flex from "../components/shared/Flex";
import { Footer } from "../components/Footer";
import { ToggleButton } from "@abb/abb-common-ux-react";
import DashboardLayout from "../containers/DashboardLayout";
import { HelpPage } from "../components/HelpLayout/HelpPage";
import { ToggleSwitch } from "@abb/abb-common-ux-react";
import {
    Route,
    Switch,
    Router,
    Redirect,
    RouteComponentProps,
} from "react-router-dom";
import { AbilityService } from 'sce-engg-model-19.09';
import AppSettings from '../services/AppSettings';
// import { Condition } from './Functions/constants';
import { LOADER_SIZE, LOADER_TYPE, IS_LOGIN } from '../utils/constants/appConstants';
import { NotFound } from "./Login/NotFound";
import { StoreState } from "../store";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { initialiseLoginProvider } from "../store/login/action";
import Settings from './Settings';

const routesConfig = [
    {
        path: "/",
        component: Assets,
        name: "asset",
        exact: true,
        private: false,
        abbHeader: false,
    },
    {
        path: "/login",
        component: Assets,
        name: "asset",
        exact: true,
        private: false,
        abbHeader: false,
    },
    // {
    //     path: "/models",
    //     component: Home,
    //     name: "Home",
    //     exact: true,
    //     private: true,
    //     abbHeader: true,
    // },
    {
        path: "/assets",
        component: Assets,
        name: "asset",
        exact: true,
        private: true,
        abbHeader: true,
    },
    // {
    //     path: "/help/portal",
    //     component: HelpPage,
    //     name: "Help",
    //     exact: true,
    //     private: true,
    //     abbHeader: false,
    // },
    {
        path: '/settings',
        component: Settings,
        name: 'functions',
        exact: true,
        private: true,
        abbHeader: true,
    },
    {
        path: '*',
        name: "NotFound",
        private: true,
        abbHeader: false,
        component: NotFound
    }
];

const PrivateDashboardLayoutRoute = (props: { children: ReactNode }) => {
    return (
        <Flex column className="dashboard-layout-container">
            <Flex>
                <Header />
            </Flex>
            <Flex className="dashboard-layout-body-container">
                {props.children}
            </Flex>
            <Footer />
        </Flex>
    );
};

interface RouteProtectionProps {
    component: any;
    privateRoute: boolean;
    abbHeader: boolean;
}


const RoutesProtectionMapStateToProps = (state: StoreState) => {
    return {
        // isUserLoggedIn: state.login.isLoggedIn,
        loadingStatus: state.login.loadingStatus,
    };
};
const RoutesProtectionMapDispatchToProps = (dispatch: Dispatch) => {
    return {
        initialiseLoginProvider: () => {
            dispatch(initialiseLoginProvider());
        },
    };
};

const RoutesProtection = connect(
    RoutesProtectionMapStateToProps,
    RoutesProtectionMapDispatchToProps
)(
    (
        props: RouteComponentProps &
            RouteProtectionProps &
            ReturnType<typeof RoutesProtectionMapStateToProps> &
            ReturnType<typeof RoutesProtectionMapDispatchToProps>
    ) => {
        const {
            component: Component,
            privateRoute,
            abbHeader,
            // isUserLoggedIn,
            loadingStatus,
            initialiseLoginProvider,
            ...rest
        } = props;

        useEffect(() => {
            const isLoggedIn =
                AppSettings.cacheLocation === "localStorage"
                    ? localStorage.getItem("showLanding")
                    : sessionStorage.getItem("showLanding");

            // const isLoggedIn =
            //     AppSettings.cacheLocation === "localStorage"
            //         ? localStorage.getItem(IS_LOGIN)
            //         : sessionStorage.getItem(IS_LOGIN);
            // if (!isLoggedIn || isLoggedIn == "null") {
            //     // intializeProvider();
            //     props.initialiseLoginProvider();
            // }
            // else {
            //     props.updateIsLoggedIn(true);
            // }
        }, []);
        return (
            <Route
                {...rest}
                render={(renderProps) => {
                    const isLoggedIn =
                        AppSettings.cacheLocation === "localStorage"
                            ? localStorage.getItem("showLanding")
                            : sessionStorage.getItem("showLanding");
                    const ComponentToRender = Component;
                    if (loadingStatus) {
                        return (
                            <Flex center>
                                <Loader
                                    sizeClass={LOADER_SIZE.LARGE}
                                    type={LOADER_TYPE.RADIAL}
                                />
                            </Flex>
                        );
                    }
                    if (privateRoute) {
                        if (isLoggedIn === "true") {
                            return abbHeader ? (
                                <PrivateDashboardLayoutRoute>
                                    <ComponentToRender {...renderProps} />
                                </PrivateDashboardLayoutRoute>
                            ) : (
                                    <ComponentToRender {...renderProps} />
                                );
                        }
                        else {
                            // return <Redirect to={"/login"} />;
                            return (
                                <Flex center>
                                    <Loader
                                        sizeClass={LOADER_SIZE.LARGE}
                                        type={LOADER_TYPE.RADIAL}
                                    />
                                </Flex>
                            );
                        }
                    } else {
                        if (isLoggedIn == "true") {
                            return <Redirect to={"/assets"} />;
                        } else {
                            return <ComponentToRender {...renderProps} />;
                        }
                    }
                }}
            />
        );
    }
);

const AppRoutes = () => {
    return (
        <Router history={history}>
            <Switch>
                {routesConfig.map((config) => {
                    return (
                        <Route
                            exact={config.exact || true}
                            key={`${config.name}`}
                            path={config.path}
                            render={(props: RouteComponentProps) => {
                                return (
                                    <RoutesProtection
                                        {...props}
                                        component={config.component}
                                        privateRoute={config.private}
                                        abbHeader={config.abbHeader}
                                    />
                                );
                            }}
                        />
                    );
                })}

            </Switch>
        </Router>
    );
};

export default AppRoutes;
