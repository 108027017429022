import { takeLatest, put, select } from "redux-saga/effects";
import { ActionTypes } from "../../actionTypes";

import {

    updateSupportedModels,
    updateModelUsedbyInstances,

    resetPaginationLoader,
    getModelUsedbyInstances,
    getAllPermisssionForModelAction,
    updateModelPermission,

    getInfoModelInstancesRequest,
    updateInfoModelInstanceRequest,
    saveInfoModelInstanceRequest,
    getTypeDefinitionRequest,
    updateTypeDefinitionRequest,
    showProgresRequest,
    deleteInfoModelInstanceRequest,
    handleLoadingUpdateTypeRelatedIntance,

} from "../actions";
import { store } from "../../../containers/AppBoot";

import { toastr } from "react-redux-toastr";
import { StoreState } from "../..";
import {
    isNilOrEmpty,
    abbSDKGetErrorMessage,
    checkVersionUpdateStatus,
    isMinorVersionUpdate,
} from "../../../utils/helpers";
import {
    VIEW_MODE,
    NOTIFICATION_MODAL_STATUS,
    SAVE_OPTIONS,
    SUPPORTED_MODEL,
    ROUTE_PATHNAME,
    OVERALL_SEVERITY_FUNCTION,
    STRUCTURE_IDENTITY,
    MODEL_SAVE_TYPE,
    CONFIGURATION_TOOL_MODE,
} from "../../../utils/constants/appConstants";

import _ from "lodash";

import { sce } from "sce-engg-model-19.09";

import { showNotificationModal } from "../../notificationModal/action";

import { getSupportedConditionMonitorsRequest } from "../../supportedConditionMonitors/action";

// get All Permissions for Function
function* getAllPermisssionForModel(
    action: ReturnType<typeof getAllPermisssionForModelAction>
) {
    try {
        const hasSave = yield sce.hasPermission(sce.saveModel);
        const hasDelete = yield sce.hasPermission(sce.deleteModel);
        const hasImport = yield sce.hasPermission(sce.importModels);
        const hasExport = yield sce.hasPermission(sce.createExportableModels);
        yield put(
            updateModelPermission({
                hasSave,
                hasDelete,
                hasEdit: hasSave,
                hasImport,
                hasExport,
            })
        );
    } catch (error) {
        yield put(
            showNotificationModal({
                title: "API error",
                resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                type: "confirmation",
                details: [abbSDKGetErrorMessage(error)],
            })
        );
    }
}


function* getModelUsedbyInstancesList(action: ReturnType<typeof getModelUsedbyInstances>) {
    const { modelsPage }: StoreState = yield select();
    const { pageNumber, pageSize } = action.payload;
    yield put(resetPaginationLoader(true));

    try {
        // const response = yield sce.getModelStatistics(
        //     pageNumber,
        //     pageSize,
        //     modelsPage.activeModel.modelInstance.modelDetails.typeId,
        //     parseInt(modelsPage.activeModel.modelInstance.modelDetails.version.split('.')[0], 10)
        // );

        // if (response && response.status === 200) {
        //     const totalRecords = response.details.count;
        //     const noOfPages = Math.ceil(totalRecords / pageSize);
        //     yield put(resetPaginationLoader(false));
        //     yield put(
        //         changePaginationDetails({
        //             totalRecords: totalRecords,
        //             entriesPerPage: pageSize,
        //             noOfPages: noOfPages,
        //         })
        //     );
        //     yield put(updateModelUsedbyInstances(response.details.data));
        // } else {
        //     yield put(
        //         showNotificationModal({
        //             title: 'API error',
        //             resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
        //             type: 'confirmation',
        //         })
        //     );
        // }
    } catch (error) {
        yield put(resetPaginationLoader(false));
        yield put(
            showNotificationModal({
                title: 'API error',
                resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                type: 'confirmation',
                details: [abbSDKGetErrorMessage(error)],
            })
        );
    }
}
function* getInfoModelInstances(
    action: ReturnType<typeof getInfoModelInstancesRequest>
) {
    try {
        yield put(showProgresRequest(true));
        const response = yield sce.getInfoModelInstances(
            SUPPORTED_MODEL.MODEL_ID
        );
        const responseIdentity = yield sce.getInfoModelInstances(
            STRUCTURE_IDENTITY.MODEL
        );
        yield put(
            updateInfoModelInstanceRequest(
                response && response.details ? response.details[0] : null,
                responseIdentity && responseIdentity.details
                    ? responseIdentity.details
                    : []
            )
        );
        yield put(showProgresRequest(false));
    } catch (error) {
        yield put(showProgresRequest(false));
        yield put(
            showNotificationModal({
                title: "API error",
                resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                type: "confirmation",
                details: [abbSDKGetErrorMessage(error)],
            })
        );
    }
}

function* saveInfoModelInstance(
    action: ReturnType<typeof saveInfoModelInstanceRequest>
) {
    try {
        yield put(showProgresRequest(true));
        const { infoModel, type } = action.payload;
        if (infoModel) {
            const response =
                type == "UPDATE"
                    ? yield sce.updateInfoModelInstance(
                          infoModel.objectId,
                          infoModel.model,
                          infoModel
                      )
                    : yield sce.saveInfoModelInstance(infoModel);
        }
        yield put(showProgresRequest(false));
        yield put(getInfoModelInstancesRequest());
        yield put(
            showNotificationModal({
                title: "Successfully Updated",
                resultStatus: NOTIFICATION_MODAL_STATUS.SUCCESS,
                type: "banner",
            })
        );
    } catch (error) {
        yield put(showProgresRequest(false));
        yield put(
            showNotificationModal({
                title: "API error",
                resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                type: "confirmation",
                details: [abbSDKGetErrorMessage(error)],
            })
        );
    }
}

function* deleteInfoModelInstance(
    action: ReturnType<typeof deleteInfoModelInstanceRequest>
) {
    try {
        yield put(showProgresRequest(true));
        const { objectId, modelId } = action.payload;
        if (objectId && modelId) {
            const response = yield sce.deleteInfoModelInstance(
                objectId,
                modelId
            );
        }
        yield put(showProgresRequest(false));
        yield put(getInfoModelInstancesRequest());
        yield put(
            showNotificationModal({
                title: "Successfully Deleted",
                resultStatus: NOTIFICATION_MODAL_STATUS.SUCCESS,
                type: "banner",
            })
        );
    } catch (error) {
        yield put(showProgresRequest(false));
        yield put(
            showNotificationModal({
                title: "API error",
                resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                type: "confirmation",
                details: [abbSDKGetErrorMessage(error)],
            })
        );
    }
}

function* getTypeDefinition(
    action: ReturnType<typeof getTypeDefinitionRequest>
) {
    try {
        const response = yield sce.getTypeDefinition(
            STRUCTURE_IDENTITY.MODEL,
            STRUCTURE_IDENTITY.TYPE,
            STRUCTURE_IDENTITY.VERSION
        );
        yield put(
            updateTypeDefinitionRequest(
                response && response.details ? response.details : undefined
            )
        );
    } catch (error) {
        // yield put(showProgresRequest(false));
        yield put(
            showNotificationModal({
                title: "API error",
                resultStatus: NOTIFICATION_MODAL_STATUS.ERROR,
                type: "confirmation",
                details: [abbSDKGetErrorMessage(error)],
            })
        );
    }
}

export default [
    takeLatest(ActionTypes.GET_ALL_PERMISSION_MODEL, getAllPermisssionForModel),
    takeLatest(ActionTypes.GET_INFO_MODEL_INSTANCES, getInfoModelInstances),
    takeLatest(ActionTypes.SAVE_INFO_MODEL_INSTANCE, saveInfoModelInstance),
    takeLatest(ActionTypes.GET_TYPE_DEFINITION_REQUEST, getTypeDefinition),
    takeLatest(ActionTypes.DELETE_INFO_MODEL_INSTANCE, deleteInfoModelInstance),
    takeLatest(ActionTypes.GET_MODEL_USED_BY_INSTANCES, getModelUsedbyInstancesList),
];
