export const VALIDATION_MESSAGE = {
    EMPTY_FIELD_MESSAGE: "Field cannot be left empty",
    FUNCTION_NAME_ERROR: "Function name is already taken",
    SPECIAL_CHARACTERS: 'Special Characters are not allowed',
};

export const CONFIRMATION_BUTTON = {
    ACCEPT: "ACCEPT",
    REJECT: "REJECT",
    DISCARD_CHANGES: "Discard Changes",
    CANCEL: "Cancel",
    CONFIRM: "Confirm",
};

export const CONFIRMATION_TITLE = <const>{
    DELETE_MODEL: 'Delete',
    DISCARD_CHANGES: 'Discard Changes',
};
export const CREATE_NEW_MODEL = {
    MODEL_DETAILS: "TYPE DETAILS",
    UPDATE_MODEL: "UPDATE TYPE",
    UPDATE_BTN_TEXT: "Set",
    CREATE_MODEL: "CREATE TYPE",
    CREATE_BTN_TEXT: "Set",
    ASSET_CONFIG_MODEL: "NEW ASSET MONITOR INSTANCE CONFIGURATION",
    ASSET_CREATE_TEXT: "Set",
    ASSET_CONFIG_MODEL_UPDATE: "UPDATE ASSET MONITOR INSTANCE CONFIGURATION",
    ASSET_UPDATE_TEXT: "Set",
    ASSET_CRITICALITY_ENABLE: "ENABLE",
    ASSET_CRITICALITY_DISABLE: "DISABLE",
    ASSET_CRITICALITY_DELETE: "DELETE",
};

export const ASSET_MODAL_BUTTON = <const>{
    SET: "Set",
    CONFIGURE: "Configure",
    UPDATE: "Update",
};

export const CREATE_ALARM = {
    CREATE: "CREATE",
    NEW_ALARM: "NEW_ALARM",
};

export const DELETE_MODEL_MSG = {
    DELETE_MODEL_MSG: "Do you want to delete the current Type?",
    DELETE_ASSET_MSG: "Do you want to delete the current selected Asset?",
    DELETE_LINE_MSG: "Do you want to remove the Mapping Details?",
    DELETE_FUNCTION_MSG: "Do you want to remove Function from Type?",
    DELETE_OBJECT_MSG: "Do you want to remove the Object from the Type?",
};

export const INVALID_COMPUTE_MODEL_MESSAGES = {
    CONNECT_ALL_INHIBIT_PINS: "Please connect all inhibit pins",
    CONNECT_BOTH_OR_NONE_INHIBIT_PINS:
        "Please connect both or none inhibit pins",
};

export const UNSAVED_CHANGES_CONFIRMATION_MESSAGE =
    "Unsaved changes will be lost, do you want to continue?";

export const TOOLTIP_MESSAGES = {
    ADD: 'Add',
    EDIT: 'Rename',
    COPY: 'Clone',
    DELETE: 'Remove',
    USER_MANUAL: 'Help',
    SETTINGS: 'Configuration',
    PROFILE: 'Profile'
};

export const VIEW_BY_LIST = ["Systems", "Edge"];

export const CONFIRMATION_DELETE =
    'Are you sure do you want to continue?';